<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bulk-update-container">
      <div class="modal-body text-center">
        <div class="">
          <button type="button" class="close" @click="cancel">×</button>
          <h1 class="text-center">Edit Breed</h1>
        </div>
        <div class="container-fluid">
          <div class="alert alert-danger" v-show="errorMessage" role="alert">
            {{ errorMessage }}
          </div>
          <template v-if="breeds.length > 0">
            <div v-for="(breedObj, index) in breeds" :key="index">
              <div class="row margin-x-sm">
                <div class="col-xs-6 col-sm-6 font-lg">Breed {{ index + 1 }}</div>
                <div class="col-xs-10 col-sm-8">
                  <select
                    v-model="breedObj.breed"
                    :id="index + 'breed'"
                    class="selectpicker form-control"
                    data-live-search="true"
                    data-style="btn-selectpicker input-height font-md bg-transparent">
                    <option v-for="(breed, index) in this.animalStore.all_breeds" :key="index" :value="breed">{{ this.animalStore.breed_labels[breed] }}</option>
                  </select>
                </div>
                <div class="col-xs-6 col-sm-6">
                  <input type="number" v-model="breedObj.fraction" class="form-control" />
                </div>
                <div class="col-xs-2 col-sm-4">
                  <button type="button" class="close form-control col-xs-24" aria-label="Close" @click="removeBreed(breedObj._id)">
                    <span class="font-danger" aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-xs-24">
              <div class="cursor-pointer margin-x-md font-lg" @click="addBreed()">+ Add Breed</div>
            </div>
          </div>
        </div>
        <div class="padding-x-sm">
          <div class="row padding-x-xs">
            <button type="button" class="btn btn-success padding-y-lg" style="border-radius: 30px" @click="saveAnimal()"><small>Save</small></button>
          </div>
          <div class="row padding-x-xs">
            <button type="button" class="btn btn-transparent padding-y-sm" style="border: none" @click="cancel"><small>Cancel</small></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Animal from "@compositions/animal.vue";
import { ObjectId } from "@assets/js/helpers.js";
import { useAnimalStore } from "@stores/animal";

export default {
  data() {
    return {
      animalCopy: this.animal,
      breeds: [],
      originalBreed: [],
      errorMessage: "",
    };
  },
  props: {
    animal: { type: Object, required: true },
  },
  setup(props) {
    const { validateBreedFraction } = Animal();

    const animalStore = useAnimalStore();

    return {
      validateBreedFraction,
      animalStore,
    };
  },
  computed: {
    animalBreeds() {
      return this.animalCopy.breeds;
    },
  },
  created() {
    if (!this.animalCopy) {
      console.error("Animal obj is required for breeds modal");
    }

    if (!this.animalCopy.breeds || this.animalCopy.breeds.length === 0) {
      // Initialize breeds to show
      this.breeds = [
        {
          _id: ObjectId(),
          fraction: 100.0,
          breed: " ",
        },
      ];
    } else {
      this.breeds = this.animalCopy.breeds;
    }

    this.originalBreed = JSON.parse(JSON.stringify(this.breeds));
  },
  mounted() {
    window.window.$(".selectpicker").selectpicker();
  },
  updated() {
    this.$nextTick(function () {
      window.$(".selectpicker").selectpicker("refresh");
    });
  },
  methods: {
    removeBreed(_id) {
      let breedIdx = this.breeds.findIndex((x) => x._id === _id);
      this.breeds.splice(breedIdx, 1);
    },
    addBreed() {
      this.breeds.push({
        _id: ObjectId(),
        fraction: 100,
        breed: " ",
      });
    },
    cancel() {
      this.animalCopy.breeds = this.originalBreed;
      window.$("#changeBreedsModal").modal("hide");
      window.$("#changeCalfBreedsModal").modal("hide");
    },
    saveAnimal() {
      for (const index in this.breeds) {
        this.breeds[index].fraction = parseFloat(Number(this.breeds[index].fraction).toFixed(1));
      }

      if (!this.validateBreedFraction(this.breeds)) {
        this.errorMessage = "Breed composition must not exceed 100%";
        return;
      }

      this.errorMessage = "";
      this.animalCopy.breeds = this.breeds;

      window.$("#changeBreedsModal").modal("hide");
      window.$("#changeCalfBreedsModal").modal("hide");
    },
  },
  watch: {
    animalBreeds: function () {
      this.breeds = this.animalCopy.breeds;
      this.originalBreed = JSON.parse(JSON.stringify(this.breeds));
    },
  },
};
</script>
