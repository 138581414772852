import { defineStore } from "pinia";
import axios from "axios";

import { useUserStore } from "@stores/user";

export const useHerdStore = defineStore("herd", {
  state: () => ({
    userStore: useUserStore(),
    herds: [],
    archived_herds: [],
  }),
  getters: {},
  actions: {
    getHerds() {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/herds/`).then((response) => {
          this.herds = [];
          this.archived_herds = [];
          let payload = response.data;

          payload = payload.sort((a, b) => {
            return a.sort_order - b.sort_order;
          });

          let sort_order = 0;
          for (const herd of payload) {
            if (herd.status != "archived") {
              if (herd.sort_order == null) {
                herd.sort_order = sort_order++;
              }
              this.herds.push(herd);
            } else {
              this.archived_herds.push(herd);
            }
          }
          resolve();
        });
      });
    },
    getHerdById(id) {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/herds/${id}`).then((response) => {
          resolve(response.data);
        });
      });
    },
    addHerd(herd) {
      return new Promise((resolve, reject) => {
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/herds/${herd._id}`, herd).then((response) => {
          this.herds.push(herd);
          resolve();
        });
      });
    },
    archiveHerd(herd) {
      herd.status = "archived";
      this.herds = this.herds.filter((x) => x._id !== herd._id);
      this.archived_herds.push(herd);
    },
    activateHerd(herd) {
      herd.status = "active";
      this.archived_herds = this.archived_herds.filter((x) => x._id !== herd._id);
      this.herds.push(herd);
    },
    patchHerd(herd) {
      return new Promise((resolve, reject) => {
        let payload = Object.assign({}, herd, { animals: undefined });
        axios.patch(`/v2/feedyards/${this.userStore.auth.feedyard_id}/herds/${herd._id}`, payload).then((response) => {
          resolve();
        });
      });
    },
    reorderActiveHerds(herds) {
      this.herds = [];
      for (const herd of herds) {
        this.herds.push(herd);
      }
    },
    reorderArchivedHerds(herds) {
      this.archived_herds = [];
      for (const herd of herds) {
        this.archived_herds.push(herd);
      }
    },
  },
});
