import axios from "axios";
import VuePapaParse from "vue-papa-parse";
import { createPinia } from "pinia";
import vueCountryRegionSelect from "vue3-ts-country-region-select";
import { DateTime } from "luxon";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// PrimeVue Imports
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import "primeicons/primeicons.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";

import sharedPlugin from "./plugins/shared.js";

import { useUserStore } from "@stores/user";

import EventTable from "@components/event-table/event-table.vue";
import AnimalForm from "@components/animal-form.vue";
import GraftModal from "@components/graft-calf-modal.vue";
import PairModal from "@components/pair-calf-modal.vue";
import DamModal from "@components/dam-modal.vue";
import GraftDamModal from "@components/graft-dam-modal.vue";
import ImportCSVModal from "@components/import-csv-modal.vue";
import AddCalfModal from "@components/add-calf-modal.vue";
import SireModal from "@components/sire-modal.vue";
import InventorySireModal from "@components/inventory-sire-modal.vue";
import UpdateLedgersModal from "@components/update-ledgers-modal.vue";
import InventoryBatchUpdateModal from "@components/inventory-batch-update-modal.vue";
import BreedsModal from "@components/breeds-modal.vue";
import DepartureModal from "@components/individual-departure-modal.vue";
import MultipleDepartureModal from "@components/multiple-departure-modal.vue";
import ErrorModal from "@components/error-modal.vue";
import Filters from "@components/filters.vue";
import ManageTagsModal from "@components/manage-tags-modal.vue";

// Global JS prototypes
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Object.defineProperty(String.prototype, "replaceAll", {
  value: function (search, replace) {
    if (replace === undefined) {
      return this.toString();
    }
    return this.split(search).join(replace);
  },
  enumerable: false,
});

Object.filter = function (obj, predicate) {
  let result = {},
    key;

  for (key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !predicate(obj[key])) {
      result[key] = obj[key];
    }
  }
  return result;
};

// Router login check
router.beforeEach(function (to, from, next) {
  const userStore = useUserStore();

  userStore.fetchAuthStatus().then((x) => {
    if (to.path !== "/login" && to.path !== "login" && (!userStore.auth.feedyard_id || x.error)) {
      next({ path: "/login" });
    } else if ((to.path === "/login" || to.path === "login") && userStore.auth.feedyard_id && !x.error) {
      next({ path: "/overview" });
    } else {
      next();
    }
  });
});

let numberOfAjaxCallPending = 0;

function completeLoading() {
  numberOfAjaxCallPending--;
  if (numberOfAjaxCallPending == 0) {
    window.$(".loader").fadeOut();
  }
}

// Axios interceptors
axios.interceptors.request.use(
  function (config) {
    numberOfAjaxCallPending++;

    window.$(".loader").fadeIn();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    setTimeout(completeLoading, 550);
    return response;
  },
  function (error) {
    numberOfAjaxCallPending--;
    completeLoading();

    // 401 unauthorized error return user to login page.
    if (error.response.status === 401 && error.config.url !== "/api/settings/") {
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(VuePapaParse);
app.use(PrimeVue);
app.use(vueCountryRegionSelect);
app.use(Toast, {
  position: "top-center",
});
app.use(sharedPlugin);

app.config.globalProperties.DateTime = DateTime;

app.component("event-table", EventTable);
app.component("add-calf-modal", AddCalfModal);
app.component("animal-form", AnimalForm);
app.component("breeds-modal", BreedsModal);
app.component("dam-modal", DamModal);
app.component("individual-departure-modal", DepartureModal);
app.component("multiple-departure-modal", MultipleDepartureModal);
app.component("tags-modal", ManageTagsModal);
app.component("error-modal", ErrorModal);
app.component("filters", Filters);
app.component("graft-calf-modal", GraftModal);
app.component("graft-dam-modal", GraftDamModal);
app.component("import-csv-modal", ImportCSVModal);
app.component("manage-tags-modal", ManageTagsModal);
app.component("pair-calf-modal", PairModal);
app.component("sire-modal", SireModal);
app.component("inventory-sire-modal", InventorySireModal);
app.component("update-ledgers-modal", UpdateLedgersModal);
app.component("inventory-batch-update-modal", InventoryBatchUpdateModal);
app.component("data-table", DataTable);
app.component("column", Column);
app.component("vue-multiselect", VueMultiselect);

app.mount("#app");
