<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h2 class="modal-title text-center">Manage Tags</h2>
          <div id="error" class="alert-danger text-center" v-if="removeError">Animal must have at least one tag.</div>
          <div id="error" class="alert-danger text-center" v-if="selectOtherTagError">Please select another tag before removing current one.</div>
          <div class="row">
            <button type="button" class="btn btn-gray btn-sm col-xs-4 pull-right" @click="addTag()">
              +
              <span class="hidden-md">Add Tag</span>
            </button>
          </div>
        </div>
        <div class="container-fluid text-center">
          <table class="table table-center" style="width: 100%">
            <thead>
              <tr>
                <th>Primary Visual</th>
                <th>Visual Label</th>
                <th>Primary Digital</th>
                <th>Digital Label</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tag, index) in animalTags" :key="index">
                <td>
                  <label><input name="primaryVisualTag" type="radio" :key="tag._id" :value="tag._id" v-model="primaryVisualID" /></label>
                </td>
                <td>
                  <input type="text" class="form-control" v-model="tag.visual_label" />
                </td>
                <td>
                  <label><input name="primaryDigitalTag" type="radio" :key="tag._id" :value="tag._id" v-model="primaryDigitalID" /></label>
                </td>
                <td>
                  <input type="text" class="form-control" v-model="tag.digital_label" />
                </td>
                <td>
                  <button type="button" class="close form-control col-xs-24" aria-label="Close" @click="removeTag(tag._id)">
                    <span class="font-danger" aria-hidden="true">×</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-success btn-lg" style="padding: 0 2em" @click="updateTags">Save Tags</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ObjectId } from "@assets/js/helpers.js";

export default {
  data() {
    return {
      animalCopy: this.animal,
      removeError: false,
      selectOtherTagError: false,
      animalTags: {},
      primaryVisualID: "",
      primaryDigitalID: "",
    };
  },
  props: {
    animal: { type: Object, required: true },
  },
  created() {
    this.animalTags = JSON.parse(JSON.stringify(this.animalCopy.tags));
    this.primaryVisualID = JSON.parse(JSON.stringify(this.animalCopy.primary_visual_id));
    this.primaryDigitalID = JSON.parse(JSON.stringify(this.animalCopy.primary_tag_id));
  },
  methods: {
    addTag() {
      this.animalTags.push({
        _id: ObjectId(),
        visual_label: "",
        digital_label: "",
      });
      this.animalCopy.tags = this.animalTags;
    },
    removeTag(tag_id) {
      if (this.animalTags.length === 1) {
        this.removeError = true;
        return;
      }

      if (this.primaryDigitalID === tag_id) {
        this.selectOtherTagError = true;
        return;
      }

      if (this.primaryVisualID === tag_id) {
        this.selectOtherTagError = true;
        return;
      }
      //if the user tries to remove the tags without selecting it first
      this.animalCopy.primary_tag_id = this.primaryDigitalID;
      this.animalCopy.primary_visual_id = this.primaryVisualID;

      this.animalCopy.tags = this.animalCopy.tags.filter((x) => x._id !== tag_id);
      this.animalTags = this.animalTags.filter((x) => x._id !== tag_id);

      this.removeError = false;
      this.selectOtherTagError = false;
    },
    updateTags() {
      let animalTag = this.animalCopy.tags.find((x) => x._id === this.primaryVisualID);
      if (!animalTag || !this.primaryDigitalID || !this.primaryVisualID) {
        window.$("#manageTagsModal").modal("hide");
        return;
      }

      this.animalCopy.tags = this.animalTags;
      this.animalCopy.primary_tag_id = this.primaryDigitalID;
      this.animalCopy.primary_visual_id = this.primaryVisualID;

      window.$("#manageTagsModal").modal("hide");
      this.removeError = false;
      this.selectOtherTagError = false;
    },
  },
};
</script>
