<template>
  <!-- Two modals in this file -->
  <div class="modal modal-center fade in" id="importDataModal" role="dialog" aria-labelledby="importDataModal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h2 class="modal-title">Import Data</h2>
          <div id="Import" class="margin-y-sm">
            <div class="margin-x-sm">
              <div v-show="import_data.percent == 0">
                <div class="row">
                  <div class="bg-info bg-round text-left margin-md padding-md" style="height: min(25vw, 120px)">
                    <div class="col-xs-2" style="font-size: 2.5vw">
                      <span class="glyphicon glyphicon-info-sign"></span>
                    </div>
                    <div class="col-xs-22" style="font-size: min(3vw, 18px)">
                      <p>This allows you to import cow/calf data into your account from a CSV file.</p>
                      <p>Please ensure you follow the steps and the guidelines listed below.</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="text-left" style="padding: 0 6vw">
                    <p>1. Download The template CSV file.</p>
                    <p>2. Make sure your data is entered in the correct columns with only one animal per row.</p>
                    <p>3. Make sure to add any dams and sires your calves will be referencing.</p>
                    <p>4. Save and upload your CSV.</p>
                    <p>5. Import</p>
                  </div>
                </div>
              </div>
              <div class="row" v-show="import_data.percent > 0">
                <div v-show="import_data.errors.length > 0">
                  <div class="bg-danger bg-round text-left margin-md padding-md" style="height: min(25vw, 120px)">
                    <div class="col-xs-2 font-lg" style="padding-top: 25px">
                      <span class="glyphicon glyphicon-info-sign"></span>
                    </div>
                    <div class="col-xs-22">
                      <h4>ERROR: Invalid Data</h4>
                      <p>There was an error importing your animals. Before attempting to upload the CSV again, please address the issues outlined below:</p>
                    </div>
                  </div>

                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Row</th>
                        <th>Column(s)</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(error, index) in import_data.errors" :key="index">
                        <td>{{ error.row }}</td>
                        <td>{{ error.column }}</td>
                        <td>{{ error.reason }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>{{ import_data.status }}</h3>
                <div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: import_data.percent + '%' }"
                      :class="{ 'progress-bar-success': import_data.percent > 90 }"
                      :aria-valuenow="import_data.percent">
                      <span class="sr-only">{{ import_data.percent }}% Complete</span>
                    </div>
                  </div>
                </div>
                <div class="padding-x-sm">
                  <div class="row padding-x-xs">
                    <button type="button" class="btn btn-transparent padding-y-sm" @click="reset_form()" style="border: none">
                      <small>
                        {{ import_data.percent == 100 ? "Done" : "Cancel" }}
                      </small>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-18 font-lg text-left">Select File</div>
                <div class="col-xs-6 font-lg">
                  Animals to import:
                  <b>{{ import_data.csv_data.length }}</b>
                </div>
                <div class="col-xs-24">
                  <input type="file" ref="fileupload" class="form-control" @change="onFileChange($event)" />
                </div>
              </div>
              <div class="row margin-x-md">
                <div class="col-xs-6">
                  <button class="btn col-xs-24 btn-outline btn-round padding-y-md" data-toggle="modal" data-target="#exampleCSVModal">
                    <small>View Example</small>
                  </button>
                </div>
                <div class="col-xs-8">
                  <a href="/data_import_template.csv" download="data_import_template.csv">
                    <button class="btn col-xs-24 btn-black btn-round padding-y-md">
                      <small>Download Template</small>
                    </button>
                  </a>
                </div>
                <div class="col-xs-4"></div>
                <div class="col-xs-6">
                  <button class="btn col-xs-24 btn-success btn-round padding-y-md" @click="start_import()">
                    <small>Import Animals</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Example CSV Modal -->
  <div class="modal modal-center fade in" id="exampleCSVModal" role="dialog" aria-labelledby="exampleCSVModal">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <button type="button" class="close" data-dismiss="modal">×</button>

          <h1 class="modal-title">Example CSV</h1>

          <div class="font-danger text-left">*Required</div>

          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>*Visual ID</th>
                  <th>Electronic ID</th>
                  <th>*Birthdate</th>
                  <th>Brand/Tattoo</th>
                  <th>Herd</th>
                  <th>Pasture</th>
                  <th>Role</th>
                  <th>Gender</th>
                  <th>Breed</th>
                  <th>Color</th>
                  <th>Horned Status</th>
                  <th>Purchase Date</th>
                  <th>Birth Weight</th>
                  <th>Birth Pasture</th>
                  <th>Sire ID</th>
                  <th>Dam ID</th>
                  <th>Management Code</th>
                  <th>Calving Ease</th>
                  <th>Vigor</th>
                  <th>Weaned Date</th>
                  <th>Departure Date</th>
                  <th>Departure Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Red 24</td>
                  <td>9740000000</td>
                  <td>05/09/2019</td>
                  <td>Circle K</td>
                  <td>Herd Sires</td>
                  <td>Bull Pen</td>
                  <td>Natural Service</td>
                  <td>Bull</td>
                  <td>Angus</td>
                  <td>BLK</td>
                  <td>Polled</td>
                  <td>07/11/2020</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Red 25</td>
                  <td></td>
                  <td>03/14/2017</td>
                  <td>Red Range</td>
                  <td>Main Herd</td>
                  <td>Creek Hill</td>
                  <td>Dam</td>
                  <td>Cow</td>
                  <td>Holstein</td>
                  <td></td>
                  <td>Polled</td>
                  <td></td>
                  <td>134</td>
                  <td>North Mill</td>
                  <td>Blue 42</td>
                  <td>Yellow 34</td>
                  <td>Dam only</td>
                  <td>Abnormal presentation</td>
                  <td>Nursed immediately, healthy</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Red 26</td>
                  <td></td>
                  <td>01/31/2022</td>
                  <td></td>
                  <td>Commercial</td>
                  <td>Creek Hill</td>
                  <td>Calf</td>
                  <td>Heifer</td>
                  <td>Limousin</td>
                  <td>RED</td>
                  <td>Horned</td>
                  <td></td>
                  <td>150</td>
                  <td>North Mill</td>
                  <td>Blue 31</td>
                  <td>Yellow 28</td>
                  <td>Dam with Creep</td>
                  <td>Caesarean</td>
                  <td>Required assistance</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Red 27</td>
                  <td>9742300193</td>
                  <td>12/31/2020</td>
                  <td></td>
                  <td>Commercial</td>
                  <td>West Lawn</td>
                  <td>Replace</td>
                  <td>Heifer</td>
                  <td>Other</td>
                  <td>Other</td>
                  <td></td>
                  <td></td>
                  <td>140</td>
                  <td>West Lawn</td>
                  <td>Blue 31</td>
                  <td></td>
                  <td>Twin or Foster Dam</td>
                  <td>No Assistance</td>
                  <td>Dead on arrival</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="font-lg">Data Formatting Chart</div>
          <table class="table table-bordered text-left">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Accepted Values</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Birthdate</b></td>
                <td>Date of birth of the animal (Required)</td>
                <td>Any valid date in the format MM/DD/YYYY</td>
                <td>12/31/2021</td>
              </tr>
              <tr>
                <td><b>Visual ID</b></td>
                <td>Primary visual ID (Required)</td>
                <td>Any value</td>
                <td>Red 47</td>
              </tr>
              <tr>
                <td><b>Eletronic ID</b></td>
                <td>Unique 15 digit electronic ID, not limited to 840's</td>
                <td>A 15 digit number</td>
                <td>
                  974000000001954
                  <br />
                  or
                  <br />
                  974-000-000-001-954
                </td>
              </tr>
              <tr>
                <td><b>Brand/Tattoo</b></td>
                <td>Additional operation visual ID methods unique to the animal</td>
                <td>Any value</td>
                <td>Triple 7</td>
              </tr>
              <tr>
                <td><b>Role</b></td>
                <td>
                  Animal category used to highlight an animal's role within the herd. Provides methods to highlight and alert management actions for calf, dam, sire,
                  replacement heifers and recipient cows. Narrows candidate list of known sires and dams when adding calves at birth and beyond. Useful to highlight
                  replacement heifers if they are not managed as a separate herd.
                </td>
                <td>
                  <div>Calf</div>
                  <div>Weaned</div>
                  <div>Replacement</div>
                  <div>Dam</div>
                  <div>Natural Service</div>
                  <div>AI</div>
                  <div>AI + Natural</div>
                </td>
                <td>Calf</td>
              </tr>
              <tr>
                <td><b>Gender</b></td>
                <td>Animal age based sex used to differentiate mature cows and bulls from calves</td>
                <td>
                  <div>Bull</div>
                  <div>Steer</div>
                  <div>Heifer</div>
                  <div>Cow</div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td><b>Herd</b></td>
                <td>
                  A grouping filter used to make data management easier. Simplifies application of practices common to animal characteristics such as age, calving
                  season or owner
                </td>
                <td>Any value</td>
                <td>Bull Herd</td>
              </tr>
              <tr>
                <td><b>Pasture</b></td>
                <td>
                  Similar to herd, a grouping feature for data management related to the location of the animals. Different herds may reside in common pasture making
                  management applications such as sire, mineral or supplement easy to track by location
                </td>
                <td>Any value</td>
                <td>Grassy Knoll</td>
              </tr>
              <tr>
                <td><b>Sire ID</b></td>
                <td>Animal sire</td>
                <td>Any Visual ID of a bull/sire</td>
                <td>Aquamarine 5</td>
              </tr>
              <tr>
                <td><b>Dam ID</b></td>
                <td>Animal dam</td>
                <td>Any Visual ID of a cow/dam</td>
                <td>Red 45</td>
              </tr>
              <tr>
                <td><b>Breed</b></td>
                <td>
                  Breed composition, for crossbred animals with known composition enter primary breed and edit further composition percent within Performance Ranch.
                  If breed composition is mixed and unimportant, then select crossbred.
                </td>
                <td>
                  <div v-for="(breed, index) in this.animalStore.all_breeds" :key="index">
                    {{ this.animalStore.breed_labels[breed] }}
                  </div>
                </td>
                <td>Galloway</td>
              </tr>
              <tr>
                <td><b>Color</b></td>
                <td>Animal's physical coat color</td>
                <td>
                  <div v-for="(coat_color, index) in animalStore.options.coat_colors" :key="index">
                    {{ animalStore.options.coat_color_labels[coat_color].label }}
                    {{ animalStore.options.coat_color_labels[coat_color].extended !== null ? " " + animalStore.options.coat_color_labels[coat_color].extended : "" }}
                    <!-- {{
                      coat_color === "black_white_face"
                        ? options.coat_color_labels[coat_color] + " (black with white face)"
                        : coat_color === "red_white_face"
                        ? options.coat_color_labels[coat_color] + " (red with white face)"
                        : options.coat_color_labels[coat_color]
                    }} -->
                  </div>
                </td>
                <td>RWF</td>
              </tr>
              <tr>
                <td><b>Horned Status</b></td>
                <td>Animal's horn status</td>
                <td>
                  <div v-for="(horn_status, index) in animalStore.options.horn_statuses" :key="index">
                    {{ animalStore.options.horn_status_labels[horn_status] }}
                  </div>
                </td>
                <td>Horned</td>
              </tr>
              <tr>
                <td><b>Purchase Date</b></td>
                <td>Date purchased cattle were added to the operation, automates origin to purchased rather than raised</td>
                <td>Any valid date in the format MM/DD/YYYY</td>
                <td>01/01/2021</td>
              </tr>
              <tr>
                <td><b>Birth Weight</b></td>
                <td>Weight at birth</td>
                <td>Any number, in US pounds (lbs)</td>
                <td>150</td>
              </tr>
              <tr>
                <td><b>Birth Pasture</b></td>
                <td>Pasture location at birth, useful for contemporary grouping</td>
                <td>Any pasture name</td>
                <td>Grassy Knoll</td>
              </tr>
              <tr>
                <td><b>Management Code</b></td>
                <td>Code used to categorize how calves are managed prior to weaning, used to evaluate dam productivity</td>
                <td>
                  <div>1 = Dam only</div>
                  <div>2 = Dam with Creep</div>
                  <div>3 = Without Dam, Bucket Fed</div>
                  <div>4 = Twin or Foster Dam</div>
                </td>
                <td>2</td>
              </tr>
              <tr>
                <td><b>Calving Ease</b></td>
                <td>Numerical score to quantify calving ease. Data range from 1 for unassisted calving to 5 for abnormal presentation.</td>
                <td>
                  <div>1 = No Assistance</div>
                  <div>2 = Assited, Easy</div>
                  <div>3 = Assisted, Difficult / mechanical</div>
                  <div>4 = Caesarean</div>
                  <div>5 = Abnormal presentation</div>
                </td>
                <td>4</td>
              </tr>
              <tr>
                <td><b>Vigor</b></td>
                <td>
                  Measure of calf activity immediately following birth, used to evaluate pre-weaning calf mortality. Data range from 1 healthy and nused to 5 dead on
                  arrival
                </td>
                <td>
                  <div>1 = Nursed immediately, healthy</div>
                  <div>2 = Nursed on own, but slowly</div>
                  <div>3 = Required assistance</div>
                  <div>4 = Died shortly after birth</div>
                  <div>5 = Dead on arrival</div>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td><b>Weaned Date</b></td>
                <td>Date calf is weaned from Dam</td>
                <td>Any valid date in the format MM/DD/YYYY</td>
                <td>10/14/2021</td>
              </tr>
              <tr>
                <td><b>Weaned Weight</b></td>
                <td>Weight at weaning</td>
                <td>Any number, in US pounds (lbs)</td>
                <td>500</td>
              </tr>
              <tr>
                <td><b>Departure Date</b></td>
                <td>Date animal was sold or died</td>
                <td>Any valid date in the format MM/DD/YYYY</td>
                <td>04/01/2021</td>
              </tr>
              <tr>
                <td><b>Departure Reasons</b></td>
                <td>Reason for departure</td>
                <td>
                  <div>Pre-weaning (Calf)</div>
                  <div v-for="(departure_reason, index) in this.animalStore.map_options.departure_reasons" :key="index">
                    <div v-if="departure_reason.category == 'pre'" class="padding-y-sm">{{ index }} = {{ departure_reason.subreason }}</div>
                  </div>

                  <div>Post-weaning (Cow/Bull)</div>
                  <div v-for="(departure_reason, index) in this.animalStore.map_options.departure_reasons" :key="index">
                    <div v-if="departure_reason.category == 'post'" class="padding-y-sm">{{ index }} = {{ departure_reason.subreason }}</div>
                  </div>
                </td>
                <td>4</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { defineComponent } from "vue";
import Animal from "@compositions/animal.vue";

import { mapIncorrectManagementCodes } from "@stores/helpers/animal.js";
import { ObjectId } from "@assets/js/helpers.js";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";

import { crmSync } from "@assets/js/hubspot.js";

export default defineComponent({
  data() {
    return {
      import_data: {
        files: null,
        csv_data: [],
        animal_data: [],
        status: "",
        percent: 0,
        errors: [],
      },
      commentRows: 0,
      parent_animals: new Map(),
    };
  },
  setup() {
    const animalStore = useAnimalStore();

    const herdStore = useHerdStore();

    const pastureStore = usePastureStore();

    const { removeWhiteTrailingSpacesFromVisualTags, visualTagHasInvalidCharacters } = Animal();

    return {
      animalStore,
      herdStore,
      pastureStore,

      removeWhiteTrailingSpacesFromVisualTags,
      visualTagHasInvalidCharacters,
    };
  },
  created() {
    this.herdStore.getHerds();
    this.pastureStore.getPastures();
    this.animalStore.getAnimals().then(() => {
      this.animalStore.getDigitalTagsFromPBAnimals();
    });
  },
  mounted() {
    let self = this;
    window.$(document).ready(() => {
      window.$("#importDataModal").on("hidden.bs.modal", function () {
        self.reset_form();
      });
    });
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.import_data.files = files;

      let file = this.import_data.files[0];
      let classOject = this;

      let fr = new FileReader();
      let cleanFile = "";
      let obj = this;

      fr.onload = function () {
        // Clean up CSV files for
        obj.commentRows = 0;

        let lines = fr.result.split(/[\r\n]+/g); // split by new line
        let required_columns = "Birthdate,Visual ID".replace(/\s/g, "").toLowerCase();
        let required_index = -1;

        for (let line of lines) {
          // check if required columns are found in previous loop
          if (required_index == -1) {
            required_index = line.replace(/\s/g, "").toLowerCase().indexOf(required_columns);
          }

          // check if current line has required index
          if (required_index < 0) {
            obj.commentRows++;
            continue;
          }

          cleanFile += line + "\n";
        }

        obj.$papa.parse(cleanFile, {
          header: true,
          comments: "#",
          skipEmptyLines: true,
          complete: function (results) {
            classOject.validate_data(results.data);
            classOject.import_data.csv_data = results.data;
          },
          error: function (err, file, inputElem, reason) {
            // executed if an error occurs while loading the file,
            // or if before callback aborted for some reason
            console.log(err);
            obj.import_data.errors = [{ row: 0, column: "CSV file", reason: "Invalid CSV file." }];
          },
        });
      };

      fr.readAsText(file);
    },
    async start_import() {
      if (this.import_data.percent > 0) {
        this.reset_form();
        return;
      }
      this.import_data.status = "Processing File";
      this.import_data.percent = 7;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.process_data();
      if (this.import_data.errors.length == 0) {
        crmSync({
          last_bulk_import_date: new Date().toJSON().slice(0, 10),
        });
      }
    },
    validate_data(csv_data) {
      let errors = [];
      let row_count = this.commentRows + 1;

      // animal labels from CSV
      let set_digital_label = new Set();
      let map_visual_label = new Map();

      // avoid updating store with validation
      let buffered_sires = this.animalStore.sires;
      let buffered_cows = this.animalStore.replacement_and_dams;
      let i = 0;

      try {
        for (let row_animal of csv_data) {
          // Trim unwanted spaces
          for (let key in row_animal) {
            row_animal[key] = row_animal[key]?.trim();
          }

          row_count++;
          row_animal["Birthdate"] = row_animal["Birthdate (MM/DD/YYYY)"] ? row_animal["Birthdate (MM/DD/YYYY)"] : row_animal["Birthdate"];
          row_animal["Vigor"] = row_animal["Birth Vigor"] ? row_animal["Birth Vigor"] : row_animal["Vigor"];

          const birth_date = this.fromCSVDate(row_animal["Birthdate"]);
          // Birthdate requirement
          if (!row_animal["Birthdate"]) {
            errors.push({
              row: row_count,
              column: "Birthdate",
              reason: "Missing Birthdate",
            });
          } else if (!birth_date.isValid) {
            errors.push({
              row: row_count,
              column: "Birthdate",
              reason: `${row_animal["Birthdate"]}: Invalid Date (must be MM/DD/YYYY)`,
            });
          } else if (birth_date > DateTime.now()) {
            errors.push({
              row: row_count,
              column: "Birthdate",
              reason: `${row_animal["Birthdate"]}: Date cannot be in the future`,
            });
          }

          // Visual ID requirement
          if (!row_animal["Visual ID"]) {
            errors.push({
              row: row_count,
              column: "Visual ID",
              reason: "Missing Visual ID",
            });
          }

          // Gender validation
          if (row_animal["Gender"]) {
            if (!this.animalStore.options.genders.includes(row_animal["Gender"]?.toLowerCase())) {
              errors.push({
                row: row_count,
                column: "Gender",
                reason: `${row_animal["Gender"]}: Invalid Gender`,
              });
            }
          }

          //Gender but no Role validation
          if (row_animal["Gender"] && !row_animal["Role"]) {
            errors.push({
              row: row_count,
              column: "Role",
              reason: "Role must be provided if animal has a gender.",
            });
          }

          // Role validation
          if (row_animal["Role"]) {
            row_animal["Role"] = row_animal["Role"].toLowerCase();
            let gender = row_animal["Gender"]?.toLowerCase();
            if (row_animal["Role"] === "natural service") {
              row_animal["Role"] = "natural_sire";
            }
            if (row_animal["Role"] === "ai + natural") {
              row_animal["Role"] = "natural_ai";
            }
            if (row_animal["Role"] === "ai") {
              row_animal["Role"] = "ai_sire";
            }
            if (row_animal["Role"] === "replacement") {
              row_animal["Role"] = "replace";
            }

            if (!this.animalStore.options.role_labels[row_animal["Role"]]) {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Invalid Role",
              });
            }

            //calf or weaned and gender is cow
            if ((row_animal["Role"] === "weaned" || row_animal["Role"] === "calf") && gender === "cow") {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Invalid gender for the calf/weaned role.",
              });
            }
            //replace and gender is not heifer
            if (row_animal["Role"] === "replace" && gender !== "heifer") {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Replace role must have heifer as a gender.",
              });
            }
            //dam and gender is not cow
            if (row_animal["Role"] === "dam" && gender !== "cow") {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Dam role must have cow as a gender.",
              });
            }
            //weaned role with null gender
            if (row_animal["Role"] === "weaned" && !gender) {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Weaned role must have bull, steer or heifer as a gender.",
              });
            }
            //sire role and gender is not bull
            if (this.animalStore.options.male_roles.includes(row_animal["Role"]) && gender !== "bull") {
              errors.push({
                row: row_count,
                column: "Role",
                reason: "Sire role must have bull as a gender.",
              });
            }
            //if role is calf and there's a weaned date/pounds, set role to weaned.
            if (row_animal["Role"] === "calf" && (row_animal["Weaned Date"] || row_animal["Weaned Weight"])) {
              row_animal["Role"] = "weaned";
            }
          }

          // Breed validation
          if (row_animal["Breed"]) {
            if (row_animal["Breed"].includes(":")) {
              let breeds = row_animal["Breed"].split(";");
              for (const b of breeds) {
                const composition = b.split(":");
                const name = composition[0];

                if (name !== "Unknown" && !Object.keys(this.animalStore.breed_labels).find((x) => this.animalStore.breed_labels[x] == name)) {
                  errors.push({
                    row: row_count,
                    column: "Breed",
                    reason: `${name}: Invalid Breed`,
                  });
                }
              }
            } else if (!Object.keys(this.animalStore.breed_labels).find((x) => this.animalStore.breed_labels[x] == row_animal["Breed"])) {
              errors.push({
                row: row_count,
                column: "Breed",
                reason: `${row_animal["Breed"]}: Invalid Breed`,
              });
            }
          }

          // Purchase Date validation
          const purchase_date = this.fromCSVDate(row_animal["Purchase Date"]);
          if (row_animal["Purchase Date"] && !purchase_date.isValid) {
            errors.push({
              row: row_count,
              column: "Purchase Date",
              reason: `${row_animal["Purchase Date"]}: Invalid Date (must be MM-DD-YYYY)`,
            });
          }
          if (purchase_date < birth_date) {
            errors.push({
              row: row_count,
              column: "Purchase Date",
              reason: `${row_animal["Purchase Date"]}: Date must be after birthdate`,
            });
          }

          // Days Pregnant validation
          const preg_check_days = row_animal["Days Pregnant"];
          if (row_animal["Days Pregnant"] && (isNaN(preg_check_days) || preg_check_days < 0)) {
            errors.push({
              row: row_count,
              column: "Days Pregnant",
              reason: `${row_animal["Days Pregnant"]}: Invalid Days (must be positive number)`,
            });
          }

          // Birth Weight validation
          if (row_animal["Birth Weight"] && row_animal["Birth Weight"] < 0) {
            errors.push({
              row: row_count,
              column: "Birth Weight",
              reason: `${row_animal["Birth Weight"]}: Birth Weight must be positive number`,
            });
          }

          // Management Code
          if (row_animal["Management Code"] && !this.animalStore.map_options.management_codes[row_animal["Management Code"]]) {
            errors.push({
              row: row_count,
              column: "Management Code",
              reason: `${row_animal["Management Code"]}: Invalid Management Code`,
            });
          }

          // Calving Ease
          if (row_animal["Calving Ease"] && !this.animalStore.map_options.calving_eases[row_animal["Calving Ease"]]) {
            errors.push({
              row: row_count,
              column: "Calving Ease",
              reason: `${row_animal["Calving Ease"]}: Invalid Calving Ease`,
            });
          }

          // Vigor
          if (row_animal["Vigor"] && !this.animalStore.map_options.vigors[row_animal["Vigor"]]) {
            errors.push({
              row: row_count,
              column: "Vigor",
              reason: `${row_animal["Vigor"]}: Invalid Vigor`,
            });
          }

          if (row_animal["Departure Reason"] && !this.animalStore.map_options.departure_reasons[row_animal["Departure Reason"]]) {
            errors.push({
              row: row_count,
              column: "Departure Reason",
              reason: `${row_animal["Departure Reason"]}: Invalid Departure Reasons`,
            });
          }

          // Weaned Date validation
          const weaned_date = this.fromCSVDate(row_animal["Weaned Date"]);
          if (row_animal["Weaned Date"] && !weaned_date.isValid) {
            errors.push({
              row: row_count,
              column: "Weaned Date",
              reason: `${row_animal["Weaned Date"]}: Invalid Date (must be MM-DD-YYYY)`,
            });
          }

          if (weaned_date < birth_date) {
            errors.push({
              row: row_count,
              column: "Weaned Date",
              reason: `${row_animal["Weaned Date"]}: Date must be after birthdate`,
            });
          }

          if (!row_animal["Weaned Date"] && row_animal["Weaned Weight"]) {
            errors.push({
              row: row_count,
              column: "Weaned Date",
              reason: "Weaned Date is required when Weaned Weight is provided.",
            });
          }

          // Weaned Weight validation
          if (row_animal["Weaned Weight"] && row_animal["Weaned Weight"] < 0) {
            errors.push({
              row: row_count,
              column: "Weaned Weight",
              reason: `${row_animal["Weaned Weight"]}: Weaned Weight must be positive number`,
            });
          }

          // Departure Date validation
          const departure_date = this.fromCSVDate(row_animal["Departure Date"]);
          if (row_animal["Departure Date"] && !departure_date.isValid) {
            errors.push({
              row: row_count,
              column: "Departure Date",
              reason: `${row_animal["Departure Date"]}: Invalid Date (must be MM-DD-YYYY)`,
            });
          }
          if (departure_date < birth_date) {
            errors.push({
              row: row_count,
              column: "Departure Date",
              reason: `${row_animal["Departure Date"]}: Date must be after birthdate`,
            });
          }

          let objectId = ObjectId();

          let regExp = new RegExp("^[1-9]{1}\\d{2}-\\d{3}-\\d{3}-\\d{3}-\\d{3}$|^[1-9]\\d{14}$", "m");
          if (row_animal["Electronic ID"]) {
            // Check for duplicate ids compared within csv
            if (set_digital_label.has(row_animal["Electronic ID"])) {
              errors.push({
                row: row_count,
                column: "Electronic ID",
                reason: `${row_animal["Electronic ID"]}: Duplicate Electronic ID`,
              });
            }
            set_digital_label.add(row_animal["Electronic ID"]);

            // Check for duplicate digital tags (ranch) compared from db
            if (row_animal["Electronic ID"] && this.animalStore.ranch_eid_values.has(row_animal["Electronic ID"])) {
              errors.push({
                row: row_count,
                column: "Electronic ID",
                reason: `${row_animal["Electronic ID"]}: Electronic ID exists in database.`,
              });
            }

            // Check for duplicate ids compared from pb animals
            if (row_animal["Electronic ID"] && this.animalStore.pb_eid_values.has(row_animal["Electronic ID"])) {
              errors.push({
                row: row_count,
                column: "Electronic ID",
                reason: `${row_animal["Electronic ID"]}: Electronic ID exists in Performance Beef.`,
              });
            }

            let isEIDValid = regExp.test(row_animal["Electronic ID"]);

            if (!isEIDValid) {
              errors.push({
                row: row_count,
                column: "Electronic ID",
                reason: `${row_animal["Electronic ID"]}: Electronic ID must be 15 numeric digits and cannot start with 0. - are allowed every 3 digits.`,
              });
            }

            // Reserve ObjectId if it's parent
            if (this.isParent(row_animal) && this.parent_animals.has(row_animal["Electronic ID"])) {
              let obj = this.parent_animals.get(row_animal["Electronic ID"]);
              obj._id = objectId;
              this.parent_animals.set(row_animal["Electronic ID"], obj);
            }
          }

          // Visual ID has invalid characters
          if (this.visualTagHasInvalidCharacters(row_animal["Visual ID"])) {
            errors.push({
              row: row_count,
              column: "Visual ID, Birthdate",
              reason: `${row_animal["Visual ID"]}: Visual tag has invalid character(s).`,
            });
          }

          // Visual ID is required
          // Check for duplicate ids compared within csv and apply regex to remove extra spaces
          row_animal["Visual ID"] = this.removeWhiteTrailingSpacesFromVisualTags(row_animal["Visual ID"]);
          if (
            map_visual_label.has(row_animal["Visual ID"].toLowerCase()) &&
            map_visual_label.get(row_animal["Visual ID"].toLowerCase()) == birth_date.toFormat("yyyy-LL-dd")
          ) {
            errors.push({
              row: row_count,
              column: "Visual ID, Birthdate",
              reason: `${row_animal["Visual ID"]}, ${row_animal["Birthdate"]}: Duplicate Visual ID + Birthdate`,
            });
          }
          map_visual_label.set(row_animal["Visual ID"].toLowerCase(), birth_date.toFormat("yyyy-LL-dd"));

          // Check for duplicate ids compared from db
          if (
            !row_animal["Electronic ID"] &&
            // visual_and_birthdate_values only has animals without digital tags.
            this.animalStore.visual_and_birthdate_values.has(row_animal["Visual ID"].toLowerCase()) &&
            this.animalStore.visual_and_birthdate_values.get(row_animal["Visual ID"].toLowerCase()).has(birth_date.toFormat("yyyy-LL-dd"))
          ) {
            errors.push({
              row: row_count,
              column: "Visual ID, Birthdate",
              reason: `${row_animal["Visual ID"]}, ${row_animal["Birthdate"]}: Visual ID + Birthdate exists in database.`,
            });
          }

          // Reserve ObjectId if it's parent
          if (this.isParent(row_animal) && this.parent_animals.has(row_animal["Visual ID"])) {
            let obj = this.parent_animals.get(row_animal["Visual ID"]);
            obj._id = objectId;
            this.parent_animals.set(row_animal["Visual ID"], obj);
          }

          // check sire id exist
          if (row_animal["Sire ID"]?.length > 0) {
            let sires = row_animal["Sire ID"].split(";");

            let sires_map = new Map();
            for (const sire of sires) {
              sires_map.set(sire.replace(/[^0-9A-Z\s]+/gi, ""), null);
            }

            for (const sire of buffered_sires) {
              // search for the rest of the tags
              for (const tag of sire.tags) {
                if (sires_map.has(tag.visual_label)) {
                  sires_map.set(tag.visual_label, sire._id);
                } else if (sires_map.has(tag.digital_label)) {
                  sires_map.set(tag.digital_label, sire._id);
                }
              }

              // search for the primary tags and overwrite existing tags
              for (const tag of sire.tags) {
                if (sire.primary_tag_id && tag._id == sire.primary_tag_id) {
                  if (sires_map.has(tag.digital_label)) {
                    sires_map.set(tag.digital_label, sire._id);
                  }
                } else if (sire.primary_visual_id && tag._id == sire.primary_visual_id) {
                  if (sires_map.has(tag.visual_label)) {
                    sires_map.set(tag.visual_label, sire._id);
                  }
                }
              }
            }

            for (const [key, value] of sires_map) {
              if (value == null) {
                // setup parent so we can check if parent exist in the csv or db.
                let parent = this.parent_animals.get(key);

                if (!parent) {
                  parent = {
                    column: "Sire ID",
                    _id: null,
                    affected_rows: new Set(),
                  };
                }

                parent.affected_rows.add(i);

                this.parent_animals.set(key.trim(), parent);
              }
            }

            row_animal["Sire ID"] = sires_map;
          }

          // check dam id exist
          if (row_animal["Dam ID"] && row_animal["Dam ID"].length > 0) {
            let dam_id = null;
            cowLoop: for (const cow of buffered_cows) {
              // search for the rest of the tags
              for (const tag of cow.tags) {
                if (tag.visual_label == row_animal["Dam ID"] || tag.digital_label == row_animal["Dam ID"]) {
                  dam_id = cow._id;
                  row_animal["Dam ID"] = dam_id;
                  break cowLoop;
                }
              }

              // search for the primary tags and overwrite existing tags
              for (const tag of cow.tags) {
                if (cow.primary_tag_id && tag._id == cow.primary_tag_id) {
                  if (tag.digital_label == row_animal["Dam ID"]) {
                    dam_id = cow._id;
                    row_animal["Dam ID"] = dam_id;
                    break cowLoop;
                  }
                } else if (cow.primary_visual_id && tag._id == cow.primary_visual_id) {
                  if (tag.visual_label == row_animal["Dam ID"]) {
                    dam_id = cow._id;
                    row_animal["Dam ID"] = dam_id;
                    break cowLoop;
                  }
                }
              }
            }

            if (dam_id == null) {
              let parent = this.parent_animals.get(row_animal["Dam ID"]);

              if (!parent) {
                parent = {
                  column: "Dam ID",
                  _id: null,
                  affected_rows: new Set(),
                };
              }

              parent.affected_rows.add(i);

              this.parent_animals.set(row_animal["Dam ID"], parent);
              row_animal["Dam ID"] = null;
            }
          }

          // check herd id exist
          const herd = this.herdStore.herds.find((x) => {
            return x.name == row_animal["Herd"];
          });
          if (herd == null && row_animal["Herd"] && row_animal["Herd"].length > 0) {
            errors.push({
              row: row_count,
              column: "Herd",
              reason: `Herd: ${row_animal["Herd"]} does not exist in database. Please create herd before importing animal.`,
            });
          }

          // check pasture id exist
          const pasture = this.pastureStore.pastures.find((x) => x.name == row_animal["Pasture"]);
          if (pasture == null && row_animal["Pasture"] && row_animal["Pasture"].length > 0) {
            errors.push({
              row: row_count,
              column: "Pasture",
              reason: `Pasture: ${row_animal["Pasture"]} does not exist in database. Please create pasture before importing animal.`,
            });
          }

          // check birth pasture id exist
          const birth_pasture = this.pastureStore.pastures.find((x) => x.name == row_animal["Birth Pasture"]);
          if (birth_pasture == null && row_animal["Birth Pasture"] && row_animal["Birth Pasture"].length > 0) {
            errors.push({
              row: row_count,
              column: "Birth Pasture",
              reason: `Birth Pasture: ${row_animal["Birth Pasture"]} does not exist in database. Please create pasture before importing animal.`,
            });
          }

          const gender = row_animal["Gender"]?.toLowerCase();
          let new_animal = this.new_animal(row_animal);

          if (this.animalStore.options.female_genders.includes(gender) && this.animalStore.options.female_roles.includes(new_animal.role)) {
            buffered_cows.push(new_animal);
          } else if (gender == "bull" && this.animalStore.options.male_roles.includes(new_animal.role)) {
            buffered_sires.push(new_animal);
          }
          i++;
        }

        for (let [key, item] of this.parent_animals) {
          for (let i of item.affected_rows) {
            if (item._id == null) {
              errors.push({
                row: i + this.commentRows + 2,
                column: item.column,
                reason: `${item.column}: ${key} does not exist in database or spreadsheet. Please create animal before importing calf.`,
              });
            } else if (item.column == "Dam ID") {
              csv_data[i][item.column] = item._id;
            } else if (item.column == "Sire ID") {
              csv_data[i][item.column].set(key, item._id);
            }
          }
        }
      } catch (err) {
        console.log(err);
        this.import_data.errors = [{ row: 0, column: "CSV file", reason: "Invalid CSV file." }];
        return errors;
      }

      this.import_data.errors = errors;

      return errors;
    },
    process_data() {
      this.import_data.status = "Validating Data";
      this.import_data.animal_data = [];

      if (this.import_data.errors.length > 0) {
        return;
      }

      let processed_count = 0;

      for (const row_animal of this.import_data.csv_data) {
        let new_animal = this.new_animal(row_animal);
        processed_count++;
        this.import_data.percent = 7 + (processed_count / this.import_data.csv_data.length) * 27;
        this.import_data.animal_data.push(new_animal);
      }

      processed_count = 0;
      for (const animal of this.import_data.animal_data) {
        this.animalStore.addAnimal(animal).then(() => {
          this.import_data.percent = 34 + (++processed_count / this.import_data.animal_data.length) * 66;
          this.import_data.status = `Importing Animals: ${processed_count} of ${this.import_data.animal_data.length}`;
        });
      }

      this.import_data.status = `Complete! ${processed_count} Animals Added`;
    },
    reset_form() {
      this.import_data = {
        files: null,
        csv_data: [],
        animal_data: [],
        status: "",
        percent: 0,
        errors: [],
      };
      this.$refs.fileupload.value = null;
    },
    new_animal(row_animal) {
      let departure = this.animalStore.map_options.departure_reasons[row_animal["Departure Reason"]];
      row_animal["Birthdate"] = row_animal["Birthdate (MM/DD/YYYY)"] ? row_animal["Birthdate (MM/DD/YYYY)"] : row_animal["Birthdate"];

      const electronic_id = row_animal["Electronic ID"];
      const visual_id = row_animal["Visual ID"];

      let objectId = ObjectId();
      if (row_animal._id) {
        // _id reserved by validation script
        objectId = row_animal._id;
      } else if (this.isParent(row_animal) && (this.parent_animals.has(electronic_id) || this.parent_animals.has(visual_id))) {
        // _id reserved by parent's eid
        let obj = this.parent_animals.has(electronic_id) ? this.parent_animals.get(electronic_id) : this.parent_animals.get(visual_id);

        if (
          (obj.column === "Sire ID" && this.animalStore.options.male_roles.includes(row_animal["Role"])) ||
          (obj.column === "Dam ID" && this.animalStore.options.female_roles.includes(row_animal["Role"]))
        ) {
          // check if obj is same role
          objectId = obj._id;
        }
      }

      let new_animal = {
        _id: objectId,
        birth_date: this.fromCSVDate(row_animal["Birthdate"]).toFormat("yyyy-LL-dd"),
        brand: row_animal["Brand/Tattoo"],
        herd_id: this.herdStore.herds.find((x) => x.name == row_animal["Herd"])?._id,
        pasture_id: this.pastureStore.pastures.find((x) => x.name == row_animal["Pasture"])?._id,
        gender: row_animal["Gender"]?.toLowerCase(),
        breeds: null,
        coat_color: null,
        horned_status: null,
        birth_weight: row_animal["Birth Weight"],
        birth_pasture_id: this.pastureStore.pastures.find((x) => x.name == row_animal["Birth Pasture"])?._id,
        sire_ids: row_animal["Sire ID"]?.size ? Array.from(row_animal["Sire ID"].values()) : [],
        dam_id: row_animal["Dam ID"],
        role: row_animal["Role"]?.toLowerCase(),
        management_code: mapIncorrectManagementCodes(this.animalStore.map_options.management_codes[row_animal["Management Code"]]),
        birth_assistance: this.animalStore.map_options.calving_eases[row_animal["Calving Ease"]],
        purchase_date: row_animal["Purchase Date"] ? this.fromCSVDate(row_animal["Purchase Date"]).toFormat("yyyy-LL-dd") : null,
        preg_check_days: row_animal["Days Pregnant"] ? parseInt(row_animal["Days Pregnant"]) : null,
        birth_vigor: this.animalStore.map_options.vigors[row_animal["Vigor"]],
        wean_date: row_animal["Weaned Date"] ? this.fromCSVDate(row_animal["Weaned Date"]).toFormat("yyyy-LL-dd") : null,
        wean_pounds: row_animal["Weaned Weight"],
        departure_dtz: row_animal["Departure Date"] ? this.fromCSVDate(row_animal["Departure Date"]).toFormat("yyyy-LL-dd") : null,
        departure_subreason: departure?.subreason,
        departure_reason: departure?.reason,
        tags: [],
      };

      // reserve id for reference
      row_animal._id = new_animal._id;

      if (row_animal["Breed"].includes(":")) {
        // multiple breed with percentages
        let breeds = row_animal["Breed"].split(";");
        new_animal.breeds = [];

        for (const b of breeds) {
          const composition = b.split(":");
          const name = composition[0];
          const percent = composition[1];

          if (name !== "Unknown") {
            let breed = Object.keys(this.animalStore.breed_labels).find((x) => this.animalStore.breed_labels[x] == name);
            new_animal.breeds.push({
              _id: ObjectId(),
              fraction: parseFloat(percent),
              breed: breed,
            });
          }
        }
      } else {
        // single breed without percentage
        let breed = Object.keys(this.animalStore.breed_labels).find((x) => this.animalStore.breed_labels[x] == row_animal["Breed"]);

        if (row_animal["Breed"]) {
          new_animal.breeds = [
            {
              _id: ObjectId(),
              fraction: 100.0,
              breed: breed,
            },
          ];
        }
      }

      if (row_animal["Color"]) {
        new_animal.coat_color = Object.keys(this.animalStore.options.coat_color_labels).find(
          (x) => this.animalStore.options.coat_color_labels[x].label.toLowerCase() == row_animal["Color"].toLowerCase()
        );
      }

      if (row_animal["Horned Status"]) {
        new_animal.horned_status = Object.keys(this.animalStore.options.horn_status_labels).find(
          (x) => this.animalStore.options.horn_status_labels[x].toLowerCase() == row_animal["Horned Status"].toLowerCase()
        );
      }

      const tag_id = ObjectId();
      new_animal.tags[0] = {
        _id: tag_id,
        visual_label: visual_id,
        digital_label: electronic_id.replace(/[^0-9A-Z\s]+/gi, ""),
      };

      new_animal.primary_tag_id = tag_id;
      new_animal.primary_visual_id = tag_id;

      return new_animal;
    },
    isParent(row_animal) {
      return this.animalStore.options.male_roles.includes(row_animal["Role"]) || this.animalStore.options.female_roles.includes(row_animal["Role"]);
    },
    fromCSVDate(date) {
      let validFormats = ["yyyy-LL-dd", "LL/dd/yyyy", "LL-dd-yyyy", "L/d/yyyy", "L-d-yyyy", "L/d/yy", "L-d-yy"];
      let d;
      d = DateTime.fromISO(date);
      if (d.isValid) {
        return d;
      }

      for (const format of validFormats) {
        d = DateTime.fromFormat(date, format);
        if (d.isValid) {
          return d;
        }
      }
    },
  },
});
</script>
