<template>
  <div>
    <template v-if="event.type === 'ai'">
      <Dropdown
        v-model="event.inventory_id"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="lodash.values(inventoryStore.list.artificial_inseminations)"
        optionLabel="label"
        optionValue="inventory_id"
        placeholder="Select AI"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'body_condition'">
      <select v-model="event.body_condition" class="form-control" :disabled="disableEditParent" required>
        <option v-for="(value, key) in animalStore.options.body_conditions" :key="key" :value="value">
          {{ animalStore.options.body_condition_labels[value] }}
        </option>
      </select>
    </template>
    <template v-else-if="event.type === 'bull_breeding_exam'">
      <Dropdown
        v-model="event.breeding_soundness_score"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="lodash.values(inventoryStore.event_options.bull_breeding_exam)"
        optionLabel="label"
        optionValue="key"
        placeholder="Select score"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'castration'">
      <Dropdown
        v-model="event.castration_method"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="lodash.values(inventoryStore.event_options.castration)"
        optionLabel="label"
        optionValue="key"
        placeholder="Select method"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'cull'">
      <Dropdown
        v-model="event.cull_reason"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="lodash.values(inventoryStore.event_options.cull_reasons)"
        optionLabel="label"
        optionValue="key"
        placeholder="Select reason"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'dehorn'"><span>1</span></template>
    <template v-else-if="event.type === 'in_estrus'">
      <Dropdown
        v-model="event.in_estrus"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="[
          { key: 'true', label: 'Yes' },
          { key: 'false', label: 'No' },
        ]"
        optionLabel="label"
        optionValue="key"
        placeholder="Select yes/no"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'morphology'">
      <input
        type="number"
        v-model="event.morphology_percent"
        placeholder="Enter Percentage"
        class="form-control"
        :disabled="disableEditParent"
        min="0"
        max="100"
        step="1"
        required />
    </template>
    <template v-else-if="event.type === 'motility'">
      <input
        type="number"
        v-model="event.motility_percent"
        placeholder="Enter Percentage"
        class="form-control"
        :disabled="disableEditParent"
        min="0"
        max="100"
        step="1"
        required />
    </template>
    <template v-else-if="event.type === 'pelvic_area'">
      <div class="form-group form-inline">
        <div class="col-md-7">
          <input
            type="number"
            placeholder="Height (cm)"
            min="0.01"
            step="0.01"
            style="width: 100%"
            v-model="event.height_cm"
            class="form-control"
            :disabled="disableEditParent"
            required />
        </div>
        <div class="col-md-7">
          <input
            type="number"
            placeholder="Width (cm)"
            min="0.01"
            step="0.01"
            style="width: 100%"
            v-model="event.width_cm"
            class="form-control"
            :disabled="disableEditParent"
            required />
        </div>
        <div class="col-md-10">
          <input
            type="number"
            :placeholder="'Area: ' + (isNaN(event.height_cm * event.width_cm) ? '' : event.height_cm * event.width_cm)"
            style="width: 100%"
            class="form-control"
            disabled />
        </div>
      </div>
    </template>
    <template v-else-if="event.type === 'physical'">
      <input type="text" placeholder="Enter Notes" v-model="event.physical" class="form-control" :disabled="disableEditParent" required />
    </template>
    <template v-else-if="event.type === 'preg_check'">
      <div class="col-md-10">
        <Dropdown
          v-model="event.preg_method"
          class="col-xs-24"
          :disabled="disableEditParent"
          required
          :options="lodash.values(inventoryStore.event_options.preg_check)"
          optionLabel="label"
          optionValue="key"
          placeholder="Select method"
          @change="updateEvents(event)" />
      </div>
      <div class="col-md-14">
        <div :class="{ 'col-xs-12': event.preg_days_dropdown === 'custom' }">
          <Dropdown
            v-model="event.preg_days_dropdown"
            class="col-xs-24"
            :disabled="disableEditParent"
            required
            :options="lodash.values(inventoryStore.event_options.preg_check_days)"
            optionLabel="label"
            optionValue="key"
            placeholder="Select days"
            @change="updateEvents(event)" />
        </div>
        <div class="col-xs-12" v-if="event.preg_days_dropdown === 'custom'">
          <input type="number" placeholder="Preg Days" v-model="event.preg_days" class="form-control" :disabled="disableEditParent" min="0" step="1" required />
        </div>
      </div>
    </template>
    <template v-else-if="event.type === 'reproductive_tract_score'">
      <Dropdown
        v-model="event.reproductive_tract_score"
        class="col-xs-24"
        :disabled="disableEditParent"
        required
        :options="inventoryStore.event_options.reproductive_tract_score.scores"
        placeholder="Select score"
        @change="updateEvents(event)" />
    </template>
    <template v-else-if="event.type === 'scrotal_circumference'">
      <input
        type="number"
        placeholder="Enter Measurement"
        v-model="event.scrotal_circumference_cm"
        class="form-control"
        :disabled="disableEditParent"
        min="0"
        step="1"
        required />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import _ from "lodash";
import Dropdown from "primevue/dropdown";

import { useUserStore } from "@stores/user";
import { useAnimalStore } from "@/stores/animal";
import { useInventoryStore } from "@/stores/inventory";

export default defineComponent({
  props: {
    prop_event: { type: Object, required: true },
    disableEditParent: { type: Boolean },
  },
  components: {
    Dropdown,
  },
  emits: ["updateEventsHandler"],
  data() {
    return {
      event: this.prop_event,
    };
  },
  setup(props) {
    const userStore = useUserStore();
    const animalStore = useAnimalStore();
    const inventoryStore = useInventoryStore();
    const lodash = _;

    return {
      userStore,
      animalStore,
      inventoryStore,
      lodash,
    };
  },
  methods: {
    updateEvents(event) {
      this.$emit("updateEventsHandler", event);
    },
  },
});
</script>
