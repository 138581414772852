<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bulk-update-container">
      <div class="modal-body sire-modal">
        <div class="modal-header">
          <button type="button" class="close" @click="cancel">×</button>
          <h1 class="modal-title text-center">Change Sire(s)</h1>
          <h3 v-if="hasNoSire || !this.animalCopy" class="text-center" style="padding: 15px">No Sire Assigned</h3>
          <div v-else class="container-fluid text-center">
            <h3 class="text-center" style="padding: 15px">Assigned Sire(s)</h3>
            <table class="animal-table table table-center" style="width: 100%">
              <thead class="animal-table-head">
                <tr class="animal-head-row">
                  <th class="text-center">Visual ID, EID</th>
                  <th class="text-center">Role</th>
                  <th class="text-center">Herd</th>
                  <th class="text-center">Pasture</th>
                  <th class="text-center">Brand/Tattoo</th>
                  <th class="text-center">Unassign</th>
                </tr>
              </thead>
              <tbody class="modal-table-body">
                <template v-if="assignedNaturalSire">
                  <tr class="animal-row text-center" v-for="(sires, index) in assignedNaturalSire" :key="index">
                    <td>
                      {{ getAnimalPrimaryVisualLabel(sires) }}
                      <br />
                      <small>{{ getAnimalPrimaryDigitalLabel(sires) }}</small>
                    </td>
                    <td>{{ animalStore.options.role_labels[sires.role] }}</td>
                    <td>{{ sires.herd ? sires.herd : "---" }}</td>
                    <td>{{ sires.pasture ? sires.pasture : "---" }}</td>
                    <td>{{ sires.brand ? sires.brand : "---" }}</td>
                    <td>
                      <button type="button" id="unassignSire" class="btn btn-outline btn-sm btn-round padding-y-md margin-sm" @click="unassignSire(sires)">
                        <small style="font-weight: bold">Unassign</small>
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <h2 class="text-center" style="padding: 15px">Select Sire(s)</h2>
          <div class="search-form">
            <img class="search-glass" src="/home/images/search_glass.png" />
            <input
              v-model="search"
              type="search"
              class="modal-search form-control"
              data-el="searchBox"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon" />
            <div class="dropdown">
              <button type="button" class="btn btn-secondary btn-lg dropdown-toggle modal-filter-button" data-toggle="dropdown">
                <img src="/home/images/filter.svg" style="position: absolute; left: 30px; top: 15px; width: 18px" />
                <span style="padding: 55px; font-size: 20px; color: black">Filters ({{ filterCounter }})</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-filters" role="menu">
                <h5 style="font-weight: bold">Role</h5>
                <select class="form-control form-control-filter" v-model="roleFilter">
                  <option>All Roles</option>
                  <option>Natural Service</option>
                  <option>AI</option>
                  <option>AI + Natural</option>
                </select>
                <h5 style="font-weight: bold">Herd</h5>
                <select class="form-control form-control-filter" v-model="herdFilter">
                  <option>All Herds</option>
                  <option v-for="(herd, index) in herdStore.herds" :key="index" :value="herd._id">
                    {{ herd.name }}
                  </option>
                  <option>No Herd Assigned</option>
                </select>
                <h5 style="font-weight: bold">Pastures</h5>
                <select class="form-control form-control-filter" v-model="pastureFilter">
                  <option>All Pastures</option>
                  <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">
                    {{ pasture.name }}
                  </option>
                  <option>No Pasture Assigned</option>
                </select>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid text-center">
          <table class="animal-table table table-center" style="width: 100%">
            <thead class="animal-table-head">
              <tr class="animal-head-row">
                <th class="text-center">Visual ID, EID</th>
                <th class="text-center">Role</th>
                <th class="text-center">Herd</th>
                <th class="text-center">Pasture</th>
                <th class="text-center">Brand/Tattoo</th>
              </tr>
            </thead>
            <tbody class="modal-table-body">
              <tr class="sire-row-pair text-center" v-for="(sire, index) in filteredSireList" :key="sire._id" @click="selectSireToPair(index, sire)">
                <td>
                  {{ getAnimalPrimaryVisualLabel(sire) }}
                  <br />
                  <small>{{ getAnimalPrimaryDigitalLabel(sire) }}</small>
                </td>
                <td>{{ animalStore.options.role_labels[sire.role] }}</td>
                <td>{{ sire.herd ? sire.herd : "---" }}</td>
                <td>{{ sire.pasture ? sire.pasture : "---" }}</td>
                <td>{{ sire.brand ? sire.brand : "---" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <button type="button" id="modal-select-button-sire" class="btn btn-success btn-lg btn-round padding-y-md margin-sm" @click="pairSire">
            <h4>
              Set {{ getAnimalPrimaryVisualLabel(this.sireToBePaired) }} as Sire to
              {{ getAnimalPrimaryVisualLabel(this.animalCopy) }}
            </h4>
          </button>
        </div>
        <div class="text-center">
          <button type="button" style="float: none" class="close margin-sm" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Animal from "@compositions/animal.vue";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";

export default {
  data() {
    return {
      animalCopy: this.animal,
      assignedNaturalSire: null,
      sireToBePaired: null,
      herdFilter: "All Herds",
      search: "",
      pastureFilter: "All Pastures",
      roleFilter: "All Roles",
      animalHasSires: false,
      sireList: [],
    };
  },
  props: {
    animal: { type: Object, required: true },
  },
  setup(props) {
    const { getAnimalPrimaryDigitalLabel, getAnimalPrimaryVisualLabel, formatAnimal } = Animal();

    const animalStore = useAnimalStore();
    const herdStore = useHerdStore();
    const pastureStore = usePastureStore();

    return {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      formatAnimal,

      animalStore,
      herdStore,
      pastureStore,
    };
  },
  mounted() {
    // Modifies bootstrap dropwdown to not close when clicking inside
    window.$(function () {
      window.$(".dropdown").on({
        click: function (event) {
          if (window.$(event.target).closest(".dropdown-toggle").length) {
            window.$(this).data("closable", true);
          } else {
            window.$(this).data("closable", false);
          }
        },
        "hide.bs.dropdown": function (event) {
          let hide = window.$(this).data("closable");
          window.$(this).data("closable", true);
          return hide;
        },
      });
    });
    let sires = this.animalStore.sires.filter((sire) => {
      return sire._id !== this.animalCopy._id && !sire.departure_dtz && sire.dam_id !== this.animalCopy._id;
    });
    this.sireList = this.formatAnimal(sires);
  },
  computed: {
    filterCounter() {
      let counter = 0;

      if (this.herdFilter.toLowerCase() !== "all herds") {
        counter++;
      }
      if (this.roleFilter.toLowerCase() !== "all roles") {
        counter++;
      }
      if (this.pastureFilter.toLowerCase() !== "all pastures") {
        counter++;
      }
      return counter;
    },
    filteredSireList() {
      let filteredSires = this.sireList;
      // Herd Filtering
      if (this.herdFilter.toLowerCase() === "no herd assigned") {
        filteredSires = filteredSires.filter((sire) => {
          return !sire.herd;
        });
      } else if (this.herdStore.herds.find((x) => x._id === this.herdFilter)) {
        filteredSires = filteredSires.filter((sire) => {
          return sire.herd_id === this.herdFilter;
        });
      }
      // Role Filtering
      if (this.roleFilter.toLowerCase() === "natural service") {
        filteredSires = filteredSires.filter((sire) => {
          return sire.gender === "bull" && sire.role === "natural_sire";
        });
      } else if (this.roleFilter.toLowerCase() === "ai") {
        filteredSires = filteredSires.filter((sire) => {
          return sire.gender === "bull" && sire.role === "ai_sire";
        });
      } else if (this.roleFilter.toLowerCase() === "ai + natural") {
        filteredSires = filteredSires.filter((sire) => {
          return sire.gender === "bull" && sire.role === "natural_ai";
        });
      }
      // Pasture Filtering
      if (this.pastureFilter.toLowerCase() === "no pasture assigned") {
        filteredSires = filteredSires.filter((sire) => {
          return !sire.pasture;
        });
      } else if (this.pastureStore.pastures.find((x) => x._id === this.pastureFilter)) {
        filteredSires = filteredSires.filter((sire) => {
          return sire.pasture_id === this.pastureFilter;
        });
      }

      // Visual, EID search filtering
      return filteredSires
        .filter((sire) => {
          return (
            this.getAnimalPrimaryVisualLabel(sire).toLowerCase().includes(this.search.toLowerCase()) ||
            this.getAnimalPrimaryDigitalLabel(sire).toLowerCase().includes(this.search.toLowerCase())
          );
        })
        .sort((a, b) => a.role.localeCompare(b.role) || this.getAnimalPrimaryVisualLabel(a).localeCompare(this.getAnimalPrimaryVisualLabel(b)));
    },
    hasNoSire() {
      if (!this.animalCopy || !this.animalCopy.sire_ids || !this.animalCopy.sire_ids.length) {
        return true;
      } else if (this.animalCopy.sire_ids !== null && this.animalCopy.sire_ids.length >= 1 && this.animalCopy.sire_ids[0] !== "") {
        this.assignSire();
      }
      return false;
    },
  },
  methods: {
    assignSire() {
      this.assignedNaturalSire = [];
      this.assignedNaturalSire = this.animalStore.sires.filter((sire) => this.animalCopy.sire_ids.includes(sire._id));

      if (!this.assignedNaturalSire.length) {
        this.assignedNaturalSire = null;
      }

      if (!this.assignedNaturalSire) {
        this.animalHasSires = false;
      } else {
        this.animalHasSires = true;
      }
    },
    selectSireToPair(index, sire) {
      if (sire === this.sireToBePaired) {
        sire = null;
      }

      let rowSelectedPair = this.$el.querySelectorAll(".sire-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        if (i === index) {
          rowSelectedPair.item(i).classList.toggle("highlight");
          this.sireToBePaired = sire;
        } else {
          rowSelectedPair.item(i).classList.remove("highlight");
        }
      }
    },
    cancel() {
      let rowSelectedPair = this.$el.querySelectorAll(".sire-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }

      this.sireToBePaired = null;
      this.search = "";
      this.herdFilter = "All Herds";
      this.pastureFilter = "All Pastures";
      window.$("#changeSireModal").modal("hide");
      window.$("#changeCalfSireModal").modal("hide");
    },
    pairSire() {
      if (this.sireToBePaired === null) {
        return;
      }

      if (
        !this.sireToBePaired ||
        this.animalCopy.sire_ids.includes(this.sireToBePaired._id) ||
        (this.sireToBePaired.sire_ids && this.sireToBePaired.sire_ids.includes(this.animalCopy._id))
      ) {
        return;
      }

      window.$(".loader").fadeIn();
      if (this.animalCopy.sire_ids[0] === "") {
        this.animalCopy.sire_ids[0] = this.sireToBePaired._id;
      } else {
        this.animalCopy.sire_ids.push(this.sireToBePaired._id);
      }

      this.search = "";

      let rowSelectedPair = document.getElementsByClassName("sire-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }
      this.sireToBePaired = null;
      window.$(".loader").fadeOut();
      window.$("#changeSireModal").modal("hide");
      window.$("#changeCalfSireModal").modal("hide");
    },
    unassignSire(sire) {
      window.$(".loader").fadeIn();
      this.animalCopy.sire_ids = this.animalCopy.sire_ids.filter((x) => x !== sire._id);
      this.assignSire();
      window.$(".loader").fadeOut();
    },
    removeNonExistingSires() {
      let existingSires = [];
      if (this.assignedNaturalSire) {
        for (let sire of this.assignedNaturalSire) {
          existingSires.push(sire._id);
        }
      }

      this.animalCopy.sire_ids = existingSires;
      this.assignSire();
    },
  },
};
</script>
