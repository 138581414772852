// plugins/shared.js
export default {
  install: (app) => {
    // Plugin code goes here
    app.config.globalProperties.$isLimitedAgent = () => {
      let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);
      var ipadWebView = userAgent.endsWith("(khtml, like gecko)");

      // ipadWebView tests for iOS 13+ on newer devices. The user agent contains
      // no reference to a browser/chrome/safari on them, so that seems like a sufficient test.
      // The second test detects iPhones/iPods/older iPads/etc.
      // Both tests should cover a majority of used devices, but it's not guaranteed to catch all of them.
      return ipadWebView || (ios && !(standalone || safari));
    };
  },
};
