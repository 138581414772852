<script>
import { DateTime } from "luxon";

const Util = {
  dateISOToYmd: (dtz) => {
    return DateTime.fromISO(dtz).toFormat("yyyy-LL-dd");
  },
  dateYmdToUTC: (date) => {
    return DateTime.fromISO(date).set({ hour: 12 }).toUTC().toISO();
  },
  monthDiff: (fromDate, toDate) => {
    return DateTime.fromISO(toDate).diff(DateTime.fromISO(fromDate), "months");
  },
  isXDayDiff: (d1, d2, x) => {
    d1 = DateTime.fromISO(d1);
    d2 = DateTime.fromISO(d2);

    const diff = d2.diff(d1, "days");

    return Math.round(Math.abs(diff?.days)) <= x;
  },
};

export default Util;
</script>
