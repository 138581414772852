import { defineStore } from "pinia";
import axios from "axios";

import { useUserStore } from "@stores/user";

export const usePastureStore = defineStore("pasture", {
  state: () => ({
    userStore: useUserStore(),
    pastures: [],
    archived_pastures: [],
  }),
  actions: {
    getPastures() {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/pens/?uses="pasture"`).then((response) => {
          this.pastures = [];
          this.archived_pastures = [];
          let payload = response.data;

          payload = payload.sort((a, b) => {
            return a.sort_order - b.sort_order;
          });

          let sort_order = 0;
          for (const pasture of payload) {
            if (pasture.status != "archived") {
              if (pasture.sort_order == null) {
                pasture.sort_order = sort_order++;
              }
              this.pastures.push(pasture);
            } else {
              this.archived_pastures.push(pasture);
            }
          }

          resolve(payload);
        });
      });
    },
    getPastureById(id) {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/pens/${id}`).then((response) => {
          resolve(response.data);
        });
      });
    },
    addPasture(pasture) {
      pasture.uses = ["pasture"];
      pasture.status = "active";
      return new Promise((resolve, reject) => {
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/pens/${pasture._id}`, pasture).then((response) => {
          resolve(response);
        });
      });
    },
    archivePasture(pasture) {
      pasture.status = "archived";
      this.pastures = this.pastures.filter((x) => x._id !== pasture._id);
      this.archived_pastures.push(pasture);
    },
    activatePasture(pasture) {
      pasture.status = "active";
      this.archived_pastures = this.archived_pastures.filter((x) => x._id !== pasture._id);
      this.pastures.push(pasture);
    },
    patchPasture(pasture) {
      return new Promise((resolve, reject) => {
        let payload = Object.assign({}, pasture, { animals: undefined });
        axios.patch(`/v2/feedyards/${this.userStore.auth.feedyard_id}/pens/${pasture._id}`, payload).then((response) => {
          resolve(response);
        });
      });
    },
    reorderActivePastures(pastures) {
      this.pastures = [];
      for (const pasture of pastures) {
        this.pastures.push(pasture);
      }
    },
    reorderArchivePastures(pastures) {
      this.archived_pastures = [];
      for (const pasture of pastures) {
        this.pastures.push(pasture);
      }
    },
  },
});
