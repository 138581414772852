<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bulk-update-container">
      <div class="modal-body graft-dam-modal">
        <div class="modal-header">
          <button type="button" class="close" @click="cancel">×</button>
          <h1 class="modal-title text-center">Change Dam</h1>
          <h3 v-if="(!active_graft_dam && !this.animalCopy.grafted_to_id) || !this.animalCopy" class="text-center" style="padding: 15px">No Dam Assigned</h3>
          <template v-if="(!active_graft_dam && this.animalCopy.grafted_to_id) || !this.animalCopy">
            <div class="text-center" style="padding: 15px">
              <h4 style="color: red">The Animal grafted dam is no longer valid. Please select a new grafted dam or click unassign.</h4>
              <div style="display: flex; justify-content: center">
                <button type="button" id="unassignDam" class="btn btn-outline btn-sm btn-round padding-y-md margin-sm" @click="unassignDam">
                  <small style="font-weight: bold">Unassign</small>
                </button>
              </div>
            </div>
          </template>
          <template v-if="active_graft_dam">
            <h4 class="text-center">Assigned Dam</h4>
            <table class="animal-table table table-center" style="width: 100%">
              <thead class="animal-table-head">
                <tr class="animal-head-row">
                  <th class="text-center">Visual ID, EID</th>
                  <th class="text-center">Herd</th>
                  <th class="text-center">Pasture</th>
                  <th class="text-center">Brand/Tattoo</th>
                  <th class="text-center">Unassign</th>
                </tr>
              </thead>
              <tbody class="modal-table-body">
                <tr class="animal-row text-center">
                  <td>
                    {{ getAnimalPrimaryVisualLabel(active_graft_dam) }}
                    <br />
                    <small>{{ getAnimalPrimaryDigitalLabel(active_graft_dam) }}</small>
                  </td>
                  <td>{{ active_graft_dam.herd ? active_graft_dam.herd : "---" }}</td>
                  <td>{{ active_graft_dam.pasture ? active_graft_dam.pasture : "---" }}</td>
                  <td>{{ active_graft_dam.brand ? active_graft_dam.brand : "---" }}</td>
                  <td>
                    <button type="button" id="unassignDam" class="btn btn-outline btn-sm btn-round padding-y-md margin-sm" @click="unassignDam">
                      <small style="font-weight: bold">Unassign</small>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <h2 class="text-center" style="padding: 15px">Select Dam</h2>
          <div class="search-form">
            <img class="search-glass" src="/home/images/search_glass.png" />
            <input v-model="searchQuery" type="search" class="modal-search form-control" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
            <div class="dropdown">
              <button type="button" class="btn btn-secondary btn-lg dropdown-toggle modal-filter-button" data-toggle="dropdown">
                <img src="/home/images/filter.svg" style="position: absolute; left: 30px; top: 15px; width: 18px" />
                <span style="padding: 55px; font-size: 20px; color: black">Filters ({{ filterCounter }})</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-filters" role="menu">
                <h5 style="font-weight: bold">Herd</h5>
                <select class="form-control form-control-filter" v-model="herdFilter">
                  <option>All Herds</option>
                  <option v-for="(herd, index) in herdStore.herds" :key="index" :value="herd._id">
                    {{ herd.name }}
                  </option>
                  <option>No Herd Assigned</option>
                </select>
                <h5 style="font-weight: bold">Pastures</h5>
                <select class="form-control form-control-filter" v-model="pastureFilter">
                  <option>All Pastures</option>
                  <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">
                    {{ pasture.name }}
                  </option>
                  <option>No Pasture Assigned</option>
                </select>
                <h5 style="font-weight: bold">Birthdate</h5>
                <input name="birthdate" type="date" class="form-control form-control-filter" v-model="birthdateFilter" />
                <template v-if="birthdateFilter > this.currentDate">
                  <p style="color: red; text-align: center">Birthdate should not be later than current date.</p>
                </template>
                <p style="margin: 10px; text-align: center; font-weight: bold">To</p>
                <input name="birthdate" type="date" class="form-control form-control-filter" v-model="birthdateFilterEnd" />
                <template v-if="birthdateFilterEnd < birthdateFilter">
                  <p style="color: red; text-align: center">Date must be later or equal to birthdate.</p>
                </template>
                <h5 style="font-weight: bold">Other</h5>
                <label class="custom-checkbox">
                  <h5 style="padding: 5px">With Calf Assigned</h5>
                  <input class="form-check-input" type="checkbox" v-model="withCalfFilter" />
                  <span class="custom-checkmark filter" style="left: 10px; top: 1px"></span>
                </label>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid text-center">
          <table class="animal-table table table-center" style="width: 100%">
            <thead class="animal-table-head">
              <tr class="animal-head-row">
                <th class="text-center">Visual ID, EID</th>
                <th class="text-center">Role</th>
                <th class="text-center">Gender</th>
                <th class="text-center">Herd</th>
                <th class="text-center">Pasture</th>
                <th class="text-center">Calf ID</th>
                <th class="text-center">Birthdate</th>
              </tr>
            </thead>
            <tbody class="modal-table-body">
              <tr
                class="graft-dam-row-pair text-center"
                v-for="(cowOrHeifer, index) in filteredGraftCowList"
                :key="cowOrHeifer._id"
                @click="selectDamToPair(index, cowOrHeifer)">
                <td>
                  {{ getAnimalPrimaryVisualLabel(cowOrHeifer) }}
                  <br />
                  <small>{{ getAnimalPrimaryDigitalLabel(cowOrHeifer) }}</small>
                </td>
                <td>{{ animalStore.options.role_labels[cowOrHeifer.role] }}</td>
                <td>{{ animalStore.options.gender_labels[cowOrHeifer.gender] }}</td>
                <td>{{ cowOrHeifer.herd ? cowOrHeifer.herd : "No Herd Assigned" }}</td>
                <td>{{ cowOrHeifer.pasture ? cowOrHeifer.pasture : "No Pasture Assigned" }}</td>
                <td>
                  {{ cowOrHeifer.active_calf ? cowOrHeifer.active_calf : "No Active Calf" }}
                </td>
                <td>{{ cowOrHeifer.birth_date ? cowOrHeifer.birth_date : "No Birthdate Recorded" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <button type="button" id="modal-select-button" class="btn btn-success btn-lg btn-round padding-y-md margin-sm" data-dismiss="modal" @click="pairDam">
            <h4>
              Set {{ getAnimalPrimaryVisualLabel(this.graftDamToBePaired) }} as Grafted Dam to
              {{ getAnimalPrimaryVisualLabel(this.animalCopy) }}
            </h4>
          </button>
        </div>
        <div class="text-center">
          <button type="button" data-dismiss="modal" style="float: none" class="close margin-sm" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

import Animal from "@compositions/animal.vue";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";

export default {
  emits: ["setActiveGraftDamToNull", "updateActiveGraftDam"],
  data() {
    return {
      animalCopy: this.animal,
      birthdateFilter: null,
      birthdateFilterEnd: null,
      graftDamToBePaired: null,
      filteredGraftCowList: [],
      herdFilter: "All Herds",
      withCalfFilter: false,
      searchQuery: "",
      pastureFilter: "All Pastures",
      currentDate: DateTime.now().toFormat("yyyy-LL-dd"),
      graftCowList: [],
    };
  },
  props: {
    animal: { type: Object, required: true },
    active_graft_dam: { type: Object, required: false },
  },
  setup(props) {
    const { getAnimalPrimaryDigitalLabel, getAnimalPrimaryVisualLabel, formatAnimal } = Animal();

    const animalStore = useAnimalStore();

    const herdStore = useHerdStore();

    const pastureStore = usePastureStore();

    return {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      formatAnimal,

      animalStore,

      herdStore,

      pastureStore,
    };
  },
  mounted() {
    window.$(function () {
      window.$(".dropdown").on({
        click: function (event) {
          if (window.$(event.target).closest(".dropdown-toggle").length) {
            window.$(this).data("closable", true);
          } else {
            window.$(this).data("closable", false);
          }
        },
        "hide.bs.dropdown": function (event) {
          let hide = window.$(this).data("closable");
          window.$(this).data("closable", true);
          return hide;
        },
      });
    });
    //initial cow list that the user can manipulate with filters
    let cows = this.animalStore.replacement_and_dams.filter((cow) => {
      return cow._id !== this.animalCopy._id && cow.dam_id !== this.animalCopy._id && cow._id !== this.animalCopy.dam_id && cow.role === "dam" && !cow.departure_dtz;
    });
    this.graftCowList = [];
    cows.forEach((cow) => {
      const formattedCow = this.formatAnimal(cow);
      this.graftCowList.push(formattedCow);
    });
    this.attachActiveCalfProperty();
  },
  computed: {
    filterCounter() {
      let counter = 0;

      if (this.withCalfFilter) {
        counter++;
      }
      if (this.birthdateFilter && this.birthdateFilterEnd) {
        counter++;
      }
      if (this.herdFilter.toLowerCase() !== "all herds") {
        counter++;
      }
      if (this.pastureFilter.toLowerCase() !== "all pastures") {
        counter++;
      }
      return counter;
    },
  },
  methods: {
    attachActiveCalfProperty() {
      const calves = [...this.animalStore.unweanedCalves];
      this.graftCowList.forEach((cow) => {
        const matched_index = [];

        const active_calf_label = [];

        calves.forEach((calf, index) => {
          if (calf.grafted_to_id === cow._id || (!calf.grafted_to_id && calf.dam_id === cow._id)) {
            active_calf_label.push(this.getAnimalPrimaryVisualLabel(calf));

            matched_index.push(index);
          }
        });
        //removes matched calves from the main array to avoid looping through them again
        matched_index.sort((a, b) => b - a);
        matched_index.forEach((index) => {
          calves.splice(index, 1);
        });
        cow.active_calf = active_calf_label.length > 0 ? active_calf_label.join(", ") : null;
      });
    },
    selectDamToPair(index, cowOrHeifer) {
      if (cowOrHeifer === this.graftDamToBePaired) {
        cowOrHeifer = null;
      }
      let rowSelectedPair = document.getElementsByClassName("graft-dam-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        if (i === index) {
          rowSelectedPair.item(i).classList.toggle("highlight");
          this.graftDamToBePaired = cowOrHeifer;
        } else {
          rowSelectedPair.item(i).classList.remove("highlight");
        }
      }
    },
    cancel() {
      let rowSelectedPair = document.getElementsByClassName("graft-dam-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }
      this.graftDamToBePaired = null;
      this.searchQuery = "";
      this.herdFilter = "All Herds";
      this.withCalfFilter = false;
      this.pastureFilter = "All Pastures";
      this.birthdateFilter = null;
      this.birthdateFilterEnd = null;
      window.$("#changeGraftedDamModal").modal("hide");
    },
    pairDam() {
      if (
        !this.graftDamToBePaired ||
        this.graftDamToBePaired._id === this.animalCopy.grafted_to_id ||
        this.graftDamToBePaired._id === this.animalCopy._id ||
        this.graftDamToBePaired.dam_id === this.animalCopy._id
      ) {
        return;
      }
      window.$(".loader").fadeIn();
      this.animalCopy.grafted_to_id = this.graftDamToBePaired._id;
      this.searchQuery = "";

      this.$emit("updateActiveGraftDam", this.graftDamToBePaired);

      let rowSelectedPair = document.getElementsByClassName("graft-dam-row-pair");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }

      this.graftDamToBePaired = null;

      window.$(".loader").fadeOut();
    },
    updateFilteredGraftedCowList() {
      let filteredCows = this.graftCowList;

      if (this.withCalfFilter) {
        filteredCows = filteredCows.filter((cow) => {
          return cow.active_calf;
        });
      }
      //Birthdate Filtering, date checking to prevent future or past dates
      if (this.birthdateFilter !== null && this.birthdateFilterEnd !== null) {
        if (this.birthdateFilterEnd >= this.birthdateFilter) {
          filteredCows = filteredCows.filter((cow) => {
            return cow.birth_date >= this.birthdateFilter && cow.birth_date <= this.birthdateFilterEnd;
          });
        }
      }
      // Herd Filtering
      if (this.herdFilter.toLowerCase() === "no herd assigned") {
        filteredCows = filteredCows.filter((cow) => {
          return !cow.herd;
        });
      } else if (this.herdStore.herds.find((x) => x._id === this.herdFilter)) {
        filteredCows = filteredCows.filter((cow) => {
          return cow.herd_id === this.herdFilter;
        });
      }
      // Pasture Filtering
      if (this.pastureFilter.toLowerCase() === "no pasture assigned") {
        filteredCows = filteredCows.filter((cow) => {
          return !cow.pasture;
        });
      } else if (this.pastureStore.pastures.find((x) => x._id === this.pastureFilter)) {
        filteredCows = filteredCows.filter((cow) => {
          return cow.pasture_id === this.pastureFilter;
        });
      }
      // Visual, EID search filtering
      // Visual, EID search filtering, role sorting
      if (this.searchQuery?.length) {
        filteredCows = filteredCows.filter((cow) => {
          return (
            this.getAnimalPrimaryVisualLabel(cow).toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            this.getAnimalPrimaryDigitalLabel(cow).toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      }

      this.filteredGraftCowList = filteredCows;
    },
    unassignDam() {
      if (!this.animalCopy.grafted_to_id) {
        return;
      }
      this.$emit("setActiveGraftDamToNull");
      window.$(".loader").fadeIn();
      this.animalCopy.grafted_to_id = null;
      window.$(".loader").fadeOut();
    },
  },
  watch: {
    birthdateFilter(newVal) {
      this.birthdateFilter = DateTime.fromISO(newVal).isValid ? DateTime.fromISO(newVal).toFormat("yyyy-LL-dd") : null;
      this.updateFilteredCowList();
    },
    birthdateFilterEnd(newVal) {
      this.birthdateFilterEnd = DateTime.fromISO(newVal).isValid ? DateTime.fromISO(newVal).toFormat("yyyy-LL-dd") : null;
      this.updateFilteredCowList();
    },
    graftCowList: "updateFilteredGraftedCowList",
    withCalfFilter: "updateFilteredGraftedCowList",
    pastureFilter: "updateFilteredGraftedCowList",
    herdFilter: "updateFilteredGraftedCowList",
    searchQuery: "updateFilteredGraftedCowList",
  },
};
</script>
