<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bulk-update-container">
      <div class="modal-body">
        <div class="modal-header">
          <button type="button" class="close" @click="cancel">×</button>
          <h1 class="modal-title text-center">Graft an Existing Calf</h1>
          <h2 class="text-center" style="padding: 15px">Select Calf</h2>
          <div class="search-form">
            <img class="search-glass" src="/home/images/search_glass.png" />
            <input v-model="search" type="search" class="modal-search form-control" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
            <div class="dropdown">
              <button type="button" class="btn btn-secondary btn-lg dropdown-toggle modal-filter-button" data-toggle="dropdown">
                <img src="/home/images/filter.svg" style="position: absolute; left: 30px; top: 15px; width: 18px" />
                <span style="padding: 55px; font-size: 20px; color: black">Filters ({{ filterCounter }})</span>
              </button>
              <ul class="dropdown-menu dropdown-menu-filters" role="menu">
                <h5 style="font-weight: bold">Gender</h5>
                <select class="form-control form-control-filter" v-model="genderFilter">
                  <option>All Genders</option>
                  <option>Bull Calf</option>
                  <option>Steer Calf</option>
                  <option>Heifer Calf</option>
                  <option>No Gender Assigned</option>
                </select>
                <h5 style="font-weight: bold">Herd</h5>
                <select class="form-control form-control-filter" v-model="herdFilter">
                  <option>All Herds</option>
                  <option v-for="(herd, index) in herdStore.herds" :key="index" :value="herd._id">
                    {{ herd.name }}
                  </option>
                  <option>No Herd Assigned</option>
                </select>
                <h5 style="font-weight: bold">Pastures</h5>
                <select class="form-control form-control-filter" v-model="pastureFilter">
                  <option>All Pastures</option>
                  <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">
                    {{ pasture.name }}
                  </option>
                  <option>No Pasture Assigned</option>
                </select>
                <h5 style="font-weight: bold">Birthdate</h5>
                <input name="birthdate" type="date" :max="currentDate" class="form-control form-control-filter" v-model="birthdateFilter" />
                <template v-if="birthdateFilter > this.currentDate">
                  <p style="color: red; text-align: center">Birthdate should not be later than current date.</p>
                </template>
                <p style="margin: 10px; text-align: center; font-weight: bold">To</p>
                <input name="birthdate" type="date" :max="currentDate" class="form-control form-control-filter" v-model="birthdateFilterEnd" />
                <template v-if="birthdateFilterEnd < birthdateFilter">
                  <p style="color: red; text-align: center">Date must be later or equal to birthdate.</p>
                </template>
                <h5 style="font-weight: bold">Other</h5>
                <label class="custom-checkbox">
                  <h5 style="padding: 5px">No Dam Assigned</h5>
                  <input class="form-check-input" type="checkbox" v-model="noDamFilter" />
                  <span class="custom-checkmark filter" style="left: 10px; top: 1px"></span>
                </label>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid text-center">
          <table class="animal-table table table-center" style="width: 100%">
            <thead class="animal-table-head">
              <tr class="animal-head-row">
                <th class="text-center">Visual ID, EID</th>
                <th class="text-center">Dam ID</th>
                <th class="text-center">Role</th>
                <th class="text-center">Gender</th>
                <th class="text-center">Pasture</th>
                <th class="text-center">Herd</th>
                <th class="text-center">Birthdate</th>
              </tr>
            </thead>
            <tbody class="modal-table-body">
              <tr class="calf-row-graft text-center" v-for="(calf, index) in filteredAllCalvesList" :key="index" @click="selectCalfToGraft(index, calf)">
                <td>
                  {{ getAnimalPrimaryVisualLabel(calf) }}
                  <br />
                  <small>{{ getAnimalPrimaryDigitalLabel(calf) }}</small>
                </td>
                <td>
                  {{ getDamIDForPairingAnExistingCalf(calf) ? getDamIDForPairingAnExistingCalf(calf) : "No Dam Assigned" }}
                </td>
                <td>{{ animalStore.options.role_labels[calf.role] }}</td>
                <td>{{ calf.gender ? animalStore.options.gender_labels[calf.gender] : "No Gender Assigned" }}</td>
                <td>{{ formatAnimal(calf).pasture ? calf.pasture : "No Pasture Assigned" }}</td>
                <td>{{ formatAnimal(calf).herd ? calf.herd : "No Herd Assigned" }}</td>
                <td>{{ calf.birth_date ? calf.birth_date : "No Birthdate Recorded" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <button type="button" id="modal-select-button" class="btn btn-success btn-lg btn-round padding-y-md margin-sm" data-dismiss="modal" @click="graftCalf">
            <h4>Graft Calf to {{ getAnimalPrimaryVisualLabel(this.animalCopy) }}</h4>
          </button>
        </div>
        <div class="text-center">
          <button type="button" data-dismiss="modal" style="float: none" class="close margin-sm" @click="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

import Animal from "@compositions/animal.vue";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";

export default {
  emits: ["updateCalfList"],
  data() {
    return {
      animalCopy: this.animal,
      birthdateFilter: null,
      birthdateFilterEnd: null,
      calfToBeGrafted: null,
      genderFilter: "All Genders",
      herdFilter: "All Herds",
      noDamFilter: false,
      search: "",
      pastureFilter: "All Pastures",
      currentDate: DateTime.now().toFormat("yyyy-LL-dd"),
      calfList: [],
    };
  },
  props: {
    animal: { type: Object, required: true },
  },
  setup(props) {
    const { getAnimalPrimaryDigitalLabel, getAnimalPrimaryVisualLabel, updateHeiferToCow, formatAnimal } = Animal();

    const animalStore = useAnimalStore();

    const herdStore = useHerdStore();

    const pastureStore = usePastureStore();

    return {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      updateHeiferToCow,
      formatAnimal,
      animalStore,
      herdStore,
      pastureStore,
    };
  },
  mounted() {
    this.currentDate = DateTime.now().toFormat("yyyy-LL-dd");
    let calves = this.animalStore.unweanedCalves.filter((calf) => {
      return calf._id !== this.animalCopy._id && !calf.departure_dtz;
    });
    this.calfList = this.formatAnimal(calves);
  },
  computed: {
    isHeifer() {
      return this.animalCopy.gender === "heifer";
    },
    filterCounter() {
      let counter = 0;

      if (this.noDamFilter) {
        counter++;
      }
      if (this.birthdateFilter && this.birthdateFilterEnd) {
        counter++;
      }
      if (this.genderFilter.toLowerCase() !== "all genders") {
        counter++;
      }
      if (this.herdFilter.toLowerCase() !== "all herds") {
        counter++;
      }
      if (this.pastureFilter.toLowerCase() !== "all pastures") {
        counter++;
      }
      return counter;
    },
    filteredAllCalvesList() {
      let filteredCalves = this.calfList;

      if (this.noDamFilter) {
        filteredCalves = filteredCalves.filter((calf) => {
          return !calf.dam_id;
        });
      }
      //birthdate filter
      if (this.birthdateFilter !== null && this.birthdateFilterEnd !== null) {
        if (this.birthdateFilterEnd >= this.birthdateFilter) {
          filteredCalves = filteredCalves.filter((calf) => {
            return calf.birth_date >= this.birthdateFilter && calf.birth_date <= this.birthdateFilterEnd;
          });
        }
      }
      // Gender Filtering
      if (this.genderFilter.toLowerCase() === "no gender assigned") {
        filteredCalves = filteredCalves.filter((calf) => {
          return calf.gender === null;
        });
      } else if (this.genderFilter.toLowerCase() !== "all genders") {
        filteredCalves = filteredCalves.filter((calf) => {
          return calf.gender !== null ? calf.gender.includes(this.genderFilter.substring(0, 4).toLowerCase()) : false;
        });
      }
      // Herd Filtering
      if (this.herdFilter.toLowerCase() === "no herd assigned") {
        filteredCalves = filteredCalves.filter((calf) => {
          return !calf.herd;
        });
      } else if (this.herdStore.herds.find((x) => x._id === this.herdFilter)) {
        filteredCalves = filteredCalves.filter((calf) => {
          return calf.herd_id === this.herdFilter;
        });
      }
      // Pasture Filtering
      if (this.pastureFilter.toLowerCase() === "no pasture assigned") {
        filteredCalves = filteredCalves.filter((calf) => {
          return !calf.pasture;
        });
      } else if (this.pastureStore.pastures.find((x) => x._id === this.pastureFilter)) {
        filteredCalves = filteredCalves.filter((calf) => {
          return calf.pasture_id === this.pastureFilter;
        });
      }
      // Visual, EID and Dam ID manual search filtering
      return filteredCalves
        .filter((calf) => {
          return (
            this.getAnimalPrimaryVisualLabel(calf).toLowerCase().includes(this.search.toLowerCase()) ||
            this.getAnimalPrimaryDigitalLabel(calf).toLowerCase().includes(this.search.toLowerCase()) ||
            this.getDamIDForPairingAnExistingCalf(calf).toLowerCase().includes(this.search.toLowerCase())
          );
        })
        .sort((a, b) => this.getAnimalPrimaryVisualLabel(a).localeCompare(this.getAnimalPrimaryVisualLabel(b)));
    },
  },
  methods: {
    cancel() {
      let rowSelectedPair = document.getElementsByClassName("calf-row-graft");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }
      this.search = "";
      this.calfToBeGrafted = null;
      this.genderFilter = "All Genders";
      this.herdFilter = "All Herds";
      this.noDamFilter = false;
      this.pastureFilter = "All Pastures";
      this.birthdateFilter = null;
      this.birthdateFilterEnd = null;
      window.$("#graftExistingCalfModal").modal("hide");
    },
    getDamIDForPairingAnExistingCalf(calves) {
      const dam = this.animalStore.replacement_and_dams.find((x) => x._id === calves.dam_id);
      return this.getAnimalPrimaryVisualLabel(dam);
    },
    graftCalf() {
      if (!this.calfToBeGrafted || this.calfToBeGrafted.grafted_to_id === this.animalCopy._id || this.calfToBeGrafted.dam_id === this.animalCopy._id) {
        return;
      }

      this.calfToBeGrafted.grafted_to_id = this.animalCopy._id;
      if (this.isHeifer) {
        this.updateHeiferToCow(this.animalCopy);
      }
      this.animalStore.editAnimal(this.calfToBeGrafted).then(() => {
        this.$emit("updateCalfList");
        window.$(".loader").fadeOut();
      });

      let rowSelectedPair = document.getElementsByClassName("calf-row-graft");
      for (let i = 0; i < rowSelectedPair.length; i++) {
        rowSelectedPair.item(i).classList.remove("highlight");
      }
      this.search = "";
      this.calfToBeGrafted = null;

      return;
    },
    selectCalfToGraft(index, calf) {
      if (calf === this.calfToBeGrafted) {
        calf = null;
      }
      let rowSelectedGraft = document.getElementsByClassName("calf-row-graft");
      for (let i = 0; i < rowSelectedGraft.length; i++) {
        if (i === index) {
          rowSelectedGraft.item(i).classList.toggle("highlight");
          this.calfToBeGrafted = calf;
        } else {
          rowSelectedGraft.item(i).classList.remove("highlight");
        }
      }
    },
  },
  watch: {
    birthdateFilter(newVal) {
      this.birthdateFilter = DateTime.fromISO(newVal).isValid ? DateTime.fromISO(newVal).toFormat("yyyy-LL-dd") : null;
    },
    birthdateFilterEnd(newVal) {
      this.birthdateFilterEnd = DateTime.fromISO(newVal).isValid ? DateTime.fromISO(newVal).toFormat("yyyy-LL-dd") : null;
    },
  },
};
</script>
