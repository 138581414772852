<template>
  <div id="filterContainer" class="filter-container noprint" style="opacity: 100">
    <div class="filter-close" @click="closeFilters()">
      <img class="filter-close-icon" src="/home/images/cancel.svg" />
    </div>
    <div class="filter-block">
      <div class="filter-heading-block">
        <h1 class="filter-heading margin-y-sm">Filters &amp; Settings</h1>
        <div id="defaultButton" class="filter-default-button" style="display: none" onclick="restoreDefaults(true);">Restore to Default</div>
      </div>
      <div class="filters">
        <div class="legend">
          <div class="legend-item legend-input-item">Show</div>
          <div class="legend-item legend-input-item">Min.</div>
          <div class="legend-item legend-input-item">Max.</div>
          <div class="legend-item legend-input-item">Exclusion 1</div>
          <div class="legend-item legend-input-item">Exclusion 2</div>
          <div class="legend-item legend-input-item">Exclusion 3</div>
        </div>
        <div id="filtersList" class="filters-list ui-sortable">
          <div v-for="(filter, filter_idx) in filterCopy" :key="filter_idx" class="row" data-field-type="string">
            <div v-if="!filterCopy[filter_idx].hidden">
              <div class="row-item sort-col ui-sortable-handle"></div>
              <div class="row-item checkbox-col toggle-col">
                <label class="checkbox-container">
                  <input type="checkbox" value="filter_idx" v-model="filterCopy[filter_idx].isChecked" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <label class="row-item field-col">{{ filter.name }}</label>
              <div class="row-item input-col min-col">
                <input type="text" class="input field-min" v-show="filter.isRange" v-model="filterCopy[filter_idx].min" />
              </div>
              <div class="row-item input-col max-col">
                <input type="text" class="input field-max" v-show="filter.isRange" v-model="filterCopy[filter_idx].max" />
              </div>
              <div class="row-item input-col threshold-col">
                <input type="text" class="input field-exclusion" v-model="filterCopy[filter_idx].exclusions[0]" />
              </div>
              <div class="row-item input-col threshold-col">
                <input type="text" class="input field-exclusion" v-model="filterCopy[filter_idx].exclusions[1]" />
              </div>
              <div class="row-item input-col threshold-col">
                <input type="text" class="input field-exclusion" v-model="filterCopy[filter_idx].exclusions[2]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["updatefilters", "close-filters"],
  props: ["filters"],
  data() {
    return {
      filterCopy: this.filters,
    };
  },
  methods: {
    closeFilters() {
      this.$emit("close-filters");
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit("updateFilters", newValue);
      },
    },
  },
};
</script>
