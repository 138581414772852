import { defineStore } from "pinia";
import axios from "axios";
import qs from "qs";
import { DateTime } from "luxon";

import { ObjectId } from "@assets/js/helpers.js";
import { useUserStore } from "@stores/user";

export const useInventoryStore = defineStore("inventory", {
  state: () => {
    let userStore = useUserStore();
    return {
      userStore: userStore,
      medications: [],
      artificial_inseminations: [],
      events: [],
      inventory: [],
      inventory_selections: [],
      allInventory: new Map(),
      map_events: new Map(),
      managed_inventory: [], // merge with inventory
      display_options: {},
      event_options: {
        bull_breeding_exam: {
          pass: { key: "pass", label: "Pass", inventory_id: null },
          fail: { key: "fail", label: "Fail", inventory_id: null },
          defer: { key: "defer", label: "Defer", inventory_id: null },
        },
        castration: {
          band: { key: "band", label: "Band", inventory_id: null },
          knife: { key: "knife", label: "Knife", inventory_id: null },
          other: { key: "other", label: "Other", inventory_id: null },
        },
        preg_check: {
          palpation: { key: "palpation", label: "Palpation", inventory_id: null },
          ultrasound: { key: "ultrasound", label: "Ultrasound", inventory_id: null },
          other: { key: "other", label: "Other", inventory_id: null },
        },
        // TODO: Fix preg check dropdown order
        preg_check_days: {
          "-1": { key: -1, label: "Not Pregnant" },
          0: { key: 0, label: "Pregnant" },
          30: { key: 30, label: 30 },
          60: { key: 60, label: 60 },
          90: { key: 90, label: 90 },
          120: { key: 120, label: 120 },
          150: { key: 150, label: 150 },
          180: { key: 180, label: 180 },
          210: { key: 210, label: 210 },
          custom: { key: "custom", label: "Custom" },
        },
        cull_reasons: {
          aborted: { key: "aborted", label: "Aborted / calf died" },
          age: { key: "age", label: "Age" },
          disease: { key: "disease", label: "Disease" },
          disposition: { key: "disposition", label: "Disposition" },
          open: { key: "open", label: "Open" },
          injury: { key: "injury", label: "Injury" },
          production: { key: "production", label: "Production/performance" },
          soundness: { key: "soundness", label: "Soundness (feet, mouth, or udder)" },
        },
        reproductive_tract_score: {
          inventory_id: null,
          scores: [1, 2, 3, 4, 5],
        },
        // Non dropdown
        other: {
          dehorn: { inventory_id: null },
          pelvic_area: { inventory_id: null },
        },
      },
      event_categories: { bull_breeding_exam: 1, castration: 1, dehorn: 1, pelvic_area: 1, preg_check: 1, reproductive_tract_score: 1 },
      default_events: [
        {
          category: "bull_breeding_exam",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Breeding Soundness Exam",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "castration",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Castration (Band)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "castration",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Castration (Knife)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "castration",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Castration (Other)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "dehorn",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Dehorning",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "pelvic_area",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Pelvic Area",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "preg_check",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Preg Check (Other)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "preg_check",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Preg Check (Palpation)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "preg_check",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Preg Check (Ultrasound)",
          unit: "unit",
          unlimited: true,
        },
        {
          category: "reproductive_tract_score",
          feedyard_id: userStore.auth.feedyard_id,
          name: "Reproductive Tract Score",
          unit: "unit",
          unlimited: true,
        },
      ],
      inventory_ledgers: [],
      list: {
        artificial_inseminations: {},
        inventory_events_id: {},
      },
    };
  },
  getters: {
    inventory_display_options: (state) => state.display_options,
  },
  actions: {
    getAI() {
      return new Promise((resolve, reject) => {
        axios.get(`/php/inventory/artificial_insemination/`).then((response) => {
          this.artificial_inseminations = [];
          this.list.artificial_inseminations = [];

          for (const artificial_insemination of response.data) {
            this.artificial_inseminations.push(artificial_insemination);

            for (const value of artificial_insemination.values) {
              this.list.artificial_inseminations = Object.assign({}, this.list.artificial_inseminations, {
                [value.id]: { inventory_id: value.id, label: value.title.label, source_animal_id: value.source_animal_id },
              });
            }
          }

          this.inventory = this.artificial_inseminations;
          resolve(response.data);
        });
      });
    },
    getEvents(updateInventory = true) {
      return new Promise((resolve, reject) => {
        axios.get(`/php/inventory/events/`).then((response) => {
          this.events = [];

          for (const event of response.data) {
            this.event_categories = event.categories;
            this.events.push(event);
            for (const value of event.values) {
              if (this.event_options[value.key] != null) {
                if (["castration", "preg_check"].includes(value.key)) {
                  for (const opts in this.event_options[value.key]) {
                    if (value.title.label.includes(this.event_options[value.key][opts].label)) {
                      this.event_options[value.key][opts].inventory_id = value.id;
                    }
                  }
                } else if (value.key === "bull_breeding_exam") {
                  for (const opts in this.event_options[value.key]) {
                    this.event_options[value.key][opts].inventory_id = value.id;
                  }
                } else if (value.key === "reproductive_tract_score") {
                  this.event_options[value.key].inventory_id = value.id;
                }
              } else if (value.key in this.event_options.other) {
                // Non dropdown event
                this.event_options.other[value.key].inventory_id = value.id;
              }
            }
          }

          if (updateInventory) {
            this.inventory = this.events;
          }
          resolve(response.data);
        });
      });
    },
    getAllInventory() {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory/`).then((res_inventory) => {
          this.allInventory = new Map();
          let map_inventory_category = new Map();
          this.map_events = new Map();

          // set all inventory by Map(_id, inventory)
          for (const inventory of res_inventory.data) {
            if (inventory.category == "feed" || inventory.sort_order === 0) {
              continue;
            }
            this.allInventory.set(inventory._id, inventory);

            const category = this.getCategory(inventory.category);
            if (!map_inventory_category.has(category)) {
              map_inventory_category.set(category, [inventory]);
            } else {
              let value = map_inventory_category.get(category);
              value.push(inventory);
              map_inventory_category.set(category, value);
            }
          }

          // setup inventory selection for VueMultiselect
          this.inventory_selections = [];
          for (const [category, inventory] of map_inventory_category) {
            if (category === "ai" || this.isEvents(category)) {
              continue;
            }
            this.inventory_selections.push({
              category: category.capitalize(),
              inventory: Array.from(inventory.values()),
            });
          }

          this.getAllInventoryProperties().then((properties) => {
            this.getAllInventoryEvents().then((events) => {
              // setup Map(event._id, inventory)
              for (const property of properties) {
                let inventory = this.allInventory.get(property.inventory_id);
                if (inventory) {
                  if (!inventory.property) {
                    inventory.property = property;
                    inventory.property.start_date = property.date;
                  } else if (DateTime.fromISO(inventory.property.date) < DateTime.fromISO(property.date)) {
                    // Use the latest property
                    inventory.property = property;
                  } else if (DateTime.fromISO(inventory.property.start_date) > DateTime.fromISO(property.date)) {
                    // Get the earliest start_date
                    inventory.property.start_date = property.date;
                  }
                }
              }
              for (const event of events) {
                this.map_events.set(event._id, event);
              }

              resolve(this.allInventory);
            });
          });
        });
      });
    },
    getAllInventoryProperties() {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_properties/`).then((response) => {
          resolve(response.data);
        });
      });
    },
    getAllInventoryEvents() {
      return new Promise((resolve) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_events/`).then((response) => {
          resolve(response.data);
        });
      });
    },
    getInventoryById(id) {
      return new Promise((resolve) => {
        axios.get(`/php/inventory/edit/`, { params: { id: id } }).then((response) => {
          resolve(response.data);
        });
      });
    },
    getInventoryByCategory(category) {
      return new Promise((resolve, reject) => {
        axios.get(`/php/inventory/`, { params: { category: category } }).then((response) => {
          this[category] = [];
          let payload = response.data;

          for (const inventoryItem of payload) {
            if (!payload.title) {
              this[category].push(inventoryItem);
            }
          }

          this.inventory = this[category];

          resolve(response.data);
        });
      });
    },
    getInventoryOptions() {
      return new Promise((resolve, reject) => {
        axios.get(`/php/inventory/options/`).then((response) => {
          this.display_options = response.data;
          resolve();
        });
      });
    },
    getInventoryCategoryProperties(category) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/php/inventory/inventory_category_properties/`, {
            params: { category: category },
          })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    getManagedInventory(category) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/php/inventory/manage/`, {
            params: { category: category },
          })
          .then((response) => {
            this.managed_inventory = response.data;
          });
      });
    },
    searchInventory(params) {
      return new Promise((resolve, reject) => {
        axios.post(`/php/inventory/inventory_search/`, params).then((response) => {
          resolve(response.data);
        });
      });
    },
    addInventory(params) {
      return new Promise((resolve, reject) => {
        axios.post(`/php/inventory/add_db/`, params).then((response) => {
          resolve(response.data);
        });
      });
    },
    checkEvents() {
      let promises = [];
      let data = {
        ledger_data: {
          starting_amount: null,
          cents_per_unit: 0,
        },
        isNew: true,
      };

      this.userStore.fetchRanchSettings().then(() => {
        let user = this.userStore.user;

        this.getEvents(false).then(() => {
          for (const default_event of this.default_events) {
            if (!this.events || this.events.length === 0 || !this.events[0].values.find((x) => x.title.label === default_event.name)) {
              data.feedyard_id = default_event.feedyard_id;
              data.inventory_data = default_event;
              data.ledger_data = { date: DateTime.fromSQL(user.time_created).toFormat("yyyy-LL-dd") };
              promises.push(this.addInventory(data));
            }
          }
        });
      });

      return promises;
    },
    manageInventory(params) {
      return new Promise((resolve, reject) => {
        axios.post(`/php/inventory/manage_db/`, params).then((response) => {
          resolve(response.data);
        });
      });
    },
    getCategory(category) {
      return this.isEvents(category) ? "events" : category;
    },
    isEvents(category) {
      return this.event_categories[category] || category == "events";
    },
    getInventoryLedgers(id) {
      // note this ledger is ledger UI in individual inventory tab
      return new Promise((resolve, reject) => {
        axios.get(`/php/inventory/get_history/?id=${id}`).then((res) => {
          this.inventory_ledgers = res.data;
          resolve();
        });
      });
    },
    removeLedgerEvent(event_id, ledger_id) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/php/inventory/remove_action/`,
            qs.stringify({
              event_id: event_id,
              ledger_id: ledger_id,
            })
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    removeInventoryEvent(event_id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_events/${event_id}`, {
            event_id: event_id,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    unitMapper(unit) {
      let mapper = {
        default: "unit",
        unit: "unit",
        aggregate: this.display_options.agg_label,
        pound: this.display_options.weight_label,
        mL: "mL",
        cc: "cc",
        straw: "straws",
      };

      return mapper[unit] ? mapper[unit] : mapper.default;
    },
    addInventoryEvent(applied_cost) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_events/${applied_cost.inventory_event_id}`, {
            _id: applied_cost.inventory_event_id,
            feedyard_id: this.userStore.auth.feedyard_id,
            inventory_id: applied_cost.inventory._id,
            date: applied_cost.application_dtz,
            diff: applied_cost.dose ? -1 * applied_cost.dose : -1,
          })
          .then(() => {
            resolve();
          });
      });
    },
    updateInventoryEvent(applied_cost) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_events/${applied_cost.inventory_event_id}`, {
            _id: applied_cost.inventory_event_id,
            feedyard_id: this.userStore.auth.feedyard_id,
            inventory_id: applied_cost.inventory._id,
            date: applied_cost.application_dtz,
            diff: applied_cost.dose ? -1 * applied_cost.dose : -1,
          })
          .then(() => {
            resolve();
          });
      });
    },
    addProperty(inventory) {
      return new Promise((resolve, reject) => {
        let objid = ObjectId();

        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory_properties/${objid}`, {
          _id: objid,
          feedyard_id: this.userStore.auth.feedyard_id,
          inventory_id: inventory.inventory._id,
          date: inventory.date,
          starting_amount: inventory.starting_amount,
        });

        resolve();
      });
    },
  },
});
