<template>
  <div class="modal fade" id="weightUpdate">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="modalLabel">Update Amount</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="alert alert-danger" role="alert" v-show="error">{{ error }}</div>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-label">Update Type</span>
              <select class="form-control" data-id="action" v-model="form.action" @change="updateActionDescription()">
                <option value="default" disabled selected>Select Action</option>
                <option value="add">Add</option>
                <option value="adjust">Adjust</option>
              </select>
            </div>
            <div class="alert alert-info" role="alert" v-show="info">
              {{ info }}
            </div>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-label">Date</span>
              <input type="date" v-model="form.date" class="form-control" :max="this.userStore.user.work_date" />
            </div>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-label">
                {{ amount_label }}
              </span>
              <input type="number" v-model="form.weight" class="form-control" />
              <span class="input-group-addon adjust-unit-label">{{ inventoryStore.unitMapper(inventory.unit) }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-confirm" @click="updateInventoryWeight()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { useToast } from "vue-toastification";

import { useUserStore } from "@stores/user";
import { useInventoryStore } from "@/stores/inventory";

export default {
  data() {
    return {
      error: "",
      info: "",
      amount_label: "Amount",
      form: {
        action: "default",
        date: null,
        weight: null,
      },
    };
  },
  props: {
    inventory: { type: Object, required: true },
    inventory_ledgers: { type: Object, required: true },
  },
  setup() {
    const toast = useToast();
    const userStore = useUserStore();
    const inventoryStore = useInventoryStore();

    return {
      toast,
      userStore,
      inventoryStore,
    };
  },
  methods: {
    updateActionDescription() {
      if (this.form.action === "add") {
        this.info = "Adding increases the current inventory on the entered date. Any future adjustment event will override this change.";
        this.amount_label = "Added Amount";
      } else if (this.form.action === "adjust") {
        this.info = "Adjustment resets the estimated remaining quantity to the entered value, and aids in shrink % calculation.";
        this.amount_label = "New Amount";
      }
    },
    updateInventoryWeight() {
      window.$(".loader").fadeIn();

      axios
        .post(
          `/php/inventory/update_weight/`,
          qs.stringify({
            action: this.form.action,
            id: this.inventory.id,
            date: this.form.date,
            weight: this.form.weight,
            first_ledger_date: this.inventory.first_ledger_date,
          })
        )
        .then((res) => {
          window.$(".loader").fadeOut();

          let data = res.data;
          if (typeof data.error !== "undefined") {
            this.error = data.error;
            return;
          }

          this.resetForm();
          this.toast.success("Successfully updated weight.");
          window.$("#weightUpdate").modal("hide");
          this.inventoryStore.getInventoryLedgers(this.inventory.id);
        })
        .catch(() => {
          this.resetForm();
          this.toast.error("Fail to update weight.");
        });
    },
    resetForm() {
      this.error = "";
      this.form = {
        action: "default",
        date: null,
        weight: null,
      };
    },
  },
};
</script>
