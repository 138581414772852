import { ObjectId } from "@assets/js/helpers.js";
import Util from "@compositions/util.vue";

import { DateTime } from "luxon";

export function AnimalToApi(animal, state) {
  // Clone object to prevent issue with pass by reference
  animal = JSON.parse(JSON.stringify(animal));

  // Sanitize animal for Animal schema
  let sanitizedAnimal = {
    _id: ObjectId(),
    created_dtz: DateTime.fromISO(state.work_date).set({ hour: 12 }).toUTC().toISO(),
    birth_date: null,
    wean_date: null,
    feedyard_id: state.feedyard_id,
    applied_costs: [],
    herd_id: null,
    brand: null,
    birth_assistance: null,
    birth_vigor: null,
    breeds: null,
    breed_registrations: [],
    coat_color: null,
    management_code: null,
    dam_id: null,
    departure_dtz: null,
    departure_id: null,
    departure_reason: null,
    departure_subreason: null,
    departure_note: null,
    gender: null,
    grafted_to_id: null,
    horned_status: null,
    artificial_sire_ids: null,
    sire_ids: null,
    role: null,
    note: null,
    tags: [
      {
        _id: ObjectId(),
        visual_label: "0000",
        digital_label: "000000000000",
      },
    ],
    primary_tag_id: null,
    primary_visual_id: null,
    purchase_date: null,
    events: [],
    pen_movements: [],
  };

  // Sanitize animal for Animal schema
  for (const key in sanitizedAnimal) {
    if (animal[key]) {
      if (animal[key] === "---") {
        animal[key] = null;
      }
      sanitizedAnimal[key] = animal[key];
    }
  }

  // Sanitize gender to lowercase
  if (animal.gender) {
    sanitizedAnimal.gender = animal.gender.toLowerCase();
  }

  // parse Events
  let birth_event = null;
  let wean_event = null;
  let birth_pen_movement = null;
  let last_pen_movement = null;
  let last_dam_body_condition_event = null;
  let last_preg_check_event = null;

  sanitizedAnimal.events.sort((a, b) => DateTime.fromISO(a.created_dtz) - DateTime.fromISO(b.created_dtz));

  for (let event of sanitizedAnimal.events) {
    let minute = 0;

    // check for event_id
    // TODO remove event.type on pen movement schema changes
    if (animal.birth_event_id && event._id === animal.birth_event_id) {
      birth_event = event;
    } else if (animal.wean_event_id && event._id === animal.wean_event_id) {
      wean_event = event;
    } else if (event.type === "birth") {
      birth_event = event;
    } else if (event.type === "wean") {
      wean_event = event;
      if (animal.birth_date === animal.wean_date) {
        minute = 1;
      }
    } else {
      // make all other events 5 minutes later
      minute = 5;
    }

    event.minute = minute;
  }

  // pen movements
  for (let movement of sanitizedAnimal.pen_movements) {
    if (movement._id === animal.birth_pen_movement?._id) {
      birth_pen_movement = movement;
    }
    if (movement.pen_id && (!last_pen_movement || movement.dtz >= last_pen_movement.dtz)) {
      last_pen_movement = movement;
    }
  }

  //Events under animal.events
  for (const eventIdx in sanitizedAnimal.events) {
    let event = sanitizedAnimal.events[eventIdx];

    if (event.type === "note") {
      event.type = null;
    }

    event.created_dtz = DateTime.fromISO(event.created_dtz).set({ hour: 12, minute: event.minute }).toUTC().toISO();
    delete event.minute;
    delete event.date;

    if (event.preg_check_days && (!last_preg_check_event || event.created_dtz > last_preg_check_event.created_dtz)) {
      last_preg_check_event = event;
    }

    if (!isNaN(parseFloat(event.rectal_temp))) {
      event.rectal_kelvin = ((parseFloat(event.rectal_temp) + 459.67) * 5) / 9;
    } else {
      event.rectal_kelvin = null;
    }
    delete event.rectal_temp;

    if (parseFloat(event.pounds) >= 0) {
      event.pounds = parseFloat(event.pounds);
    } else {
      event.pounds = null;
    }

    if (event.diagnosis) {
      event.diagnosis_ids = [];
      event.diagnosis_ids.push(event.diagnosis._id);
    }

    delete event.diagnosis;
    delete event.last_weight_event;
  }

  for (let applied_cost of sanitizedAnimal.applied_costs) {
    // Events under applied_costs
    if (
      applied_cost.type === "body_condition" &&
      applied_cost.body_condition &&
      DateTime.fromISO(applied_cost.application_dtz).hasSame(DateTime.fromISO(animal.calf_birth_date), "day") &&
      applied_cost.calf_animal_id === animal.calf_animal_id
    ) {
      last_dam_body_condition_event = applied_cost;
    }

    applied_cost.application_dtz = DateTime.fromISO(applied_cost.application_dtz).set({ hour: 12 }).toUTC().toISO();
    delete applied_cost.date;

    // Product Applied, Animal History, Event History properties
    delete applied_cost.inventory;
    delete applied_cost.preg_days_dropdown;

    // remove legacy properties
    delete applied_cost.inventory_id;
    delete applied_cost.dose;
    delete applied_cost.premise_device_id;
  }

  // Formate dates & pounds
  if (animal.birth_date) {
    let pushCondition = { null_event: false, hasPounds: false };
    if (!birth_event) {
      birth_event = {
        _id: ObjectId(),
      };
      pushCondition.null_event = true;
    }

    birth_event.created_dtz = DateTime.fromISO(animal.birth_date).set({ hour: 12 }).toUTC().toISO();
    // format float
    if (parseFloat(animal.birth_weight) > 0) {
      birth_event.pounds = parseFloat(animal.birth_weight);
      pushCondition.hasPounds = true;
    } else {
      birth_event.pounds = null;
    }

    if (animal.birth_pasture_id) {
      if (!birth_pen_movement) {
        birth_pen_movement = {
          _id: ObjectId(),
        };

        sanitizedAnimal.pen_movements.push(birth_pen_movement);
      }

      birth_pen_movement.dtz = birth_event.created_dtz;
      birth_pen_movement.pen_id = animal.birth_pasture_id;
    } else {
      sanitizedAnimal.pen_movements = sanitizedAnimal.pen_movements.filter((x) => x._id !== birth_pen_movement?._id);
    }

    if (pushCondition.null_event && pushCondition.hasPounds) {
      sanitizedAnimal.events.push(birth_event);
    } else if (!pushCondition.hasPounds) {
      // Remove event if empty
      sanitizedAnimal.events = sanitizedAnimal.events.filter((x) => x._id !== birth_event?._id);
    }

    birth_event.type = null;
  }

  // Wean event
  if (parseFloat(animal.wean_pounds) > 0 || animal.wean_date) {
    let pushCondition = { null_event: false, hasPounds: false };
    if (!wean_event) {
      wean_event = {
        _id: ObjectId(),
      };

      pushCondition.null_event = true;
    }

    let minute = animal.wean_date === animal.birth_date ? 1 : 0;

    wean_event.created_dtz = animal.wean_date
      ? DateTime.fromISO(animal.wean_date).set({ hour: 12, minute: minute }).toUTC().toISO()
      : DateTime.fromISO(state.work_date).set({ hour: 12, minute: minute }).toUTC().toISO();

    if (parseFloat(animal.wean_pounds) > 0) {
      wean_event.pounds = parseFloat(animal.wean_pounds);
      pushCondition.hasPounds = true;
    } else {
      wean_event.pounds = null;
    }

    if (pushCondition.null_event && pushCondition.hasPounds) {
      sanitizedAnimal.events.push(wean_event);
    } else if (!pushCondition.hasPounds) {
      // Remove event if empty
      sanitizedAnimal.events = sanitizedAnimal.events.filter((x) => x._id !== wean_event?._id);
    }

    wean_event.type = null;
  }

  if (animal.purchase_date) {
    sanitizedAnimal.purchase_date = DateTime.fromISO(animal.purchase_date).toFormat("yyyy-LL-dd");
  }

  if (animal.departure_dtz) {
    sanitizedAnimal.departure_dtz = DateTime.fromISO(animal.departure_dtz).set({ hour: 12 }).toUTC().toISO();
  }

  // break out artificial Insemination from sire_ids
  if (sanitizedAnimal.artificial_sire_ids && !sanitizedAnimal.artificial_sire_ids.length) {
    sanitizedAnimal.artificial_sire_ids = [];
  }

  for (const sire_idx in sanitizedAnimal.sire_ids) {
    const sire_id = sanitizedAnimal.sire_ids[sire_idx];

    if (state.artificial_inseminations && state.artificial_inseminations.has(sire_id) && !sanitizedAnimal.artificial_sire_ids.includes(sire_id)) {
      sanitizedAnimal.artificial_sire_ids.push(sire_id);
    }
    if (state.artificial_inseminations && state.artificial_inseminations.has(sire_id) && sanitizedAnimal.artificial_sire_ids.includes(sire_id)) {
      sanitizedAnimal.sire_ids.splice(sire_idx, 1);
    }
  }

  // clean empty sire_ids
  if (sanitizedAnimal.sire_ids !== null && sanitizedAnimal.sire_ids.length === 1 && sanitizedAnimal.sire_ids[0] === "") {
    sanitizedAnimal.sire_ids = null;
  }

  // turn singe sire_id into array
  if (sanitizedAnimal.sire_ids !== null && !Array.isArray(sanitizedAnimal.sire_ids)) {
    sanitizedAnimal.sire_ids = [sanitizedAnimal.sire_ids];
  }

  //breed_registration number into array
  if (sanitizedAnimal.breed_registrations !== null && !Array.isArray(sanitizedAnimal.breed_registrations)) {
    sanitizedAnimal.breed_registrations = [sanitizedAnimal.breed_registrations];
  }

  // set primary tag
  for (let tag of sanitizedAnimal.tags) {
    if (!tag._id) {
      tag._id = ObjectId();
    }
    if (!sanitizedAnimal.primary_tag_id) {
      sanitizedAnimal.primary_tag_id = tag._id;
    }
    if (!sanitizedAnimal.primary_visual_id) {
      sanitizedAnimal.primary_visual_id = tag._id;
    }
  }

  //Adds a new pasture event
  if (animal.pasture_id && animal.pasture_id != last_pen_movement?.pen_id) {
    sanitizedAnimal.pen_movements.push({
      _id: ObjectId(),
      dtz: DateTime.now().toISO(),
      pen_id: animal.pasture_id,
    });
  }

  // dam body condition is used only on dams/heifers to create/verify the dam bcs events in applied_costs
  if (animal.body_condition && animal.calf_animal_id) {
    if (!last_dam_body_condition_event) {
      sanitizedAnimal.applied_costs.push({
        _id: ObjectId(),
        application_dtz: DateTime.fromISO(animal.calf_birth_date).set({ hour: 12 }).toUTC().toISO(),
        body_condition: animal.body_condition,
        calf_animal_id: animal.calf_animal_id,
        type: "body_condition",
      });
    } else {
      last_dam_body_condition_event.body_condition = animal.body_condition;
    }
  }

  //Breed check
  if (sanitizedAnimal.breeds) {
    for (const breedObj in sanitizedAnimal.breeds) {
      if (
        (sanitizedAnimal.breeds[breedObj].breed === " " || !sanitizedAnimal.breeds[breedObj].breed) &&
        sanitizedAnimal.breeds[breedObj].fraction &&
        sanitizedAnimal.breeds.length === 1
      ) {
        // if there's only one breed and it's empty or null, set the whole breed obj to null
        sanitizedAnimal.breeds = null;
      } else if (sanitizedAnimal.breeds && sanitizedAnimal.breeds[breedObj].breed !== " " && sanitizedAnimal.breeds[breedObj].fraction) {
        sanitizedAnimal.breeds[breedObj].fraction /= 100.0;
      } else if (
        ((sanitizedAnimal.breeds && sanitizedAnimal.breeds[breedObj].breed === " ") || !sanitizedAnimal.breeds[breedObj].breed) &&
        sanitizedAnimal.breeds[breedObj].fraction
      ) {
        sanitizedAnimal.breeds.splice(breedObj, 1);
      }
    }
  }
  return sanitizedAnimal;
}

export function ApiToAnimal(animal, animalState) {
  // birth_date is required
  if (!animal.birth_date) {
    return false;
  }

  animal.events.sort((a, b) => DateTime.fromISO(a.created_dtz) - DateTime.fromISO(b.created_dtz));

  let birth_event = null;
  birth_event = animal.events.find((x) => {
    let birth_dtz = DateTime.fromISO(animal.birth_date).set({ hour: 12 }).toUTC().toISO();

    for (let day = 0; day <= 1 && birth_event === null; day++) {
      if (
        Util.isXDayDiff(x.created_dtz, birth_dtz, day) &&
        (x.diagnosis_ids == null || x.diagnosis_ids.length === 0) &&
        x.note == null &&
        x.rectal_kelvin == null &&
        (x.symptoms == null || x.symptoms.length === 0)
      ) {
        x.type = "birth";
        return x;
      }
    }
  });

  if (birth_event == null) {
    birth_event = {
      _id: ObjectId(),
      created_dtz: DateTime.fromISO(animal.birth_date).set({ hour: 12 }).toUTC().toISO(),
    };
  }

  animal.birth_weight = birth_event?.pounds;

  let wean_event = null;
  wean_event = animal.events.find((x) => {
    let wean_dtz = DateTime.fromISO(animal.wean_date).set({ hour: 12 }).toUTC().toISO();
    for (let day = 0; day <= 1 && animal.wean_date && wean_event === null; day++) {
      if (
        x._id !== birth_event._id &&
        Util.isXDayDiff(x.created_dtz, wean_dtz, day) &&
        (x.diagnosis_ids == null || x.diagnosis_ids.length === 0) &&
        x.note == null &&
        x.rectal_kelvin == null &&
        (x.symptoms == null || x.symptoms.length === 0) &&
        x.pen_id == null
      ) {
        x.type = "wean";
        return x;
      }
    }
  });

  if (wean_event === null) {
    wean_event = {
      _id: ObjectId(),
      created_dtz: DateTime.fromISO(animal.wean_date).set({ hour: 12 }).toUTC().toISO(),
    };
  }

  let birth_pen_movement = null;
  let movement = animal.pen_movements.find((x) => {
    let birth_dtz = DateTime.fromISO(animal.birth_date).set({ hour: 12 }).toUTC().toISO();
    for (let day = 0; day <= 1 && birth_pen_movement === null; day++) {
      if (Util.isXDayDiff(x.dtz, birth_dtz, day)) {
        return x;
      }
    }
  });

  if (movement !== null) {
    birth_pen_movement = movement;
  }

  animal.birth_pen_movement = birth_pen_movement;
  animal.birth_pasture_id = birth_pen_movement?.pen_id;
  animal.birth_event_id = birth_event._id;
  animal.wean_event_id = wean_event?._id;

  animal.wean_pounds = wean_event?.pounds;

  if (animal.purchase_date) {
    animal.purchase_date = DateTime.fromISO(animal.purchase_date).toFormat("yyyy-LL-dd");
  }

  if (animal.departure_dtz) {
    animal.departure_dtz = DateTime.fromISO(animal.departure_dtz).toFormat("yyyy-LL-dd");
  }

  let last_pen_movement = null;
  let last_preg_check_event = null;

  animal.events = animal.events.sort((a, b) => DateTime.fromISO(a.created_dtz) - DateTime.fromISO(b.created_dtz));

  for (const movement of animal.pen_movements) {
    if (movement._id !== birth_pen_movement?._id && movement.pen_id && (last_pen_movement === null || movement.dtz > last_pen_movement.dtz)) {
      last_pen_movement = movement;
    }
  }

  for (const eventIdx in animal.events) {
    let event = animal.events[eventIdx];

    if (event.preg_check_days && (!last_preg_check_event || event.created_dtz > last_preg_check_event.created_dtz)) {
      last_preg_check_event = event;
    }

    event.created_dtz = DateTime.fromISO(event.created_dtz).toFormat("yyyy-LL-dd");
    if (!isNaN(parseFloat(event.rectal_kelvin))) {
      event.rectal_temp = Math.round(((parseFloat(event.rectal_kelvin) * 9) / 5 - 459.67) * 10) / 10;
    }

    // set note type for UI
    if (!event.type && event.note && !(event.diagnosis_ids?.length > 0 || event.rectal_temp || event.pounds)) {
      event.type = "note";
    }
  }

  //fill missing breeds with empty string to be able to show unknown to the user
  let fraction = 0;
  if (animal.breeds) {
    for (const breedObj in animal.breeds) {
      if (animal.breeds[breedObj].fraction > 1) {
        animal.breeds[breedObj].fraction /= 100.0;
      }
      animal.breeds[breedObj].fraction *= 100;
      animal.breeds[breedObj].fraction = animal.breeds[breedObj].fraction.toFixed(1);
      fraction += parseFloat(animal.breeds[breedObj].fraction);
      if (fraction == 100.1) {
        animal.breeds[breedObj].fraction -= 0.1;
        animal.breeds[breedObj].fraction.toFixed(1);
      }
    }
    if (fraction < 100) {
      animal.breeds.push({
        _id: ObjectId(),
        fraction: (100 - fraction).toFixed(1),
        breed: " ",
      });
    }
  }

  //make sure the values where there's no API validation are set to lower case
  if (animal.management_code) {
    // animal.management_code = animal.management_code.toLowerCase();

    // This is temporary. Until the PR Android code is refactored to send the same `management_code` values to pb-api and until the existing values in Mongo are cleaned up,
    // let's attempt to correct them, as we fetch them from the API, in case the user attempts to edit the animal. Saving the edited animal will correct the `management_code` property in Mongo.
    animal.management_code = mapIncorrectManagementCodes(animal.management_code.toLowerCase());
  }

  //If an animal comes to ranch without a primary visual/primary tag, a default must be set.
  if (!animal.primary_visual_id || !animal.tags.find((x) => x._id === animal.primary_visual_id)) {
    animal.primary_visual_id = animal.tags[0]._id;
  }

  if (!animal.primary_tag_id || !animal.tags.find((x) => x._id === animal.primary_tag_id)) {
    animal.primary_tag_id = animal.tags[0]._id;
  }

  // finds the dam bcs value inside applied costs and assigns it to dam_body_condition on the top lvl document
  if (animal.dam_id) {
    const dam = animalState.animals.find((x) => x._id === animal.dam_id);
    animal.dam_body_condition = dam?.applied_costs.find((x) => x.type === "body_condition" && x.calf_animal_id === animal._id && x.body_condition)?.body_condition;
  }

  if (!animal.dam_body_condition) {
    animal.dam_body_condition = null;
  }

  animal.pasture_id = last_pen_movement?.pen_id;
  animal.last_pen_movement = last_pen_movement;

  animal.applied_costs = animal.applied_costs.sort((a, b) => DateTime.fromISO(a.application_dtz) - DateTime.fromISO(b.application_dtz));
  for (let applied_cost of animal.applied_costs) {
    applied_cost.application_dtz = DateTime.fromISO(applied_cost.application_dtz).toFormat("yyyy-LL-dd");
  }

  if (!animal.pasture_id) {
    animal.pasture_id = null;
  }
  return animal;
}

export function mapIncorrectManagementCodes(management_code) {
  // acceptable management_codes: ["dam_only", "dam_with_creep", "without_dam_bucket_fed", "twin_or_foster_dam"],
  if (management_code !== null && management_code !== undefined) {
    management_code = management_code.replaceAll("_", " ").replaceAll(",", "").trim().toLowerCase();
  }

  switch (management_code) {
    case "dam only":
      return "dam_only";
    case "dam with creep":
      return "dam_with_creep";
    case "without dam bucket fed":
      return "without_dam_bucket_fed";
    case "twin or foster dam":
      return "twin_or_foster_dam";
    case null:
    case "":
    default:
      return management_code;
  }
}
