<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h2 class="modal-title">Record a Departure</h2>
        <div>
          <ul class="nav nav-tabs" role="tablist" style="display: inline-block; padding-top: 10px">
            <li role="presentation" v-for="(tab, index) in tabs" :key="index" :class="{ active: tab.isActive }">
              <template v-if="tab.show">
                <a href="#" role="tab" data-toggle="tab" style="font-size: 18px; color: #cf1f25; padding-left: 50px; padding-right: 50px" @click="setActiveTab(tab)">
                  {{ tab.name }}
                </a>
              </template>
            </li>
          </ul>
          <div class="tab-content">
            <div id="sale" class="tab-pane" style="padding-right: 30px" :class="{ active: tabs[0].isActive }"></div>
            <div id="death" class="tab-pane" :class="{ active: tabs[1].isActive }"></div>
            <div id="transfer" class="tab-pane" :class="{ active: tabs[2].isActive }"></div>
          </div>
        </div>
        <div>
          <div class="tab-content">
            <template v-if="isDeathTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.bulk_animal.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.bulk_animal.$error }">*Departure Date</span>
                <input type="date" class="form-control" v-model="bulk_animal.departure_dtz" id="departureDate" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.bulk_animal.$errors">
                <span class="error-message" v-for="error in v$.bulk_animal.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
            </template>
            <template v-else-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.bulk_animal.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.bulk_animal.$error }">*Departure Date</span>
                <input type="date" class="form-control" v-model="bulk_animal.departure_dtz" id="departureDate" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.bulk_animal.$errors">
                <span class="error-message" v-for="error in v$.bulk_animal.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
            </template>
            <template v-else>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.transfer_date.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.transfer_date.$error }">*Departure Date</span>
                <input
                  type="date"
                  class="form-control"
                  :class="{ 'form-control-validation-error': v$.transfer_date.$error }"
                  :max="this.userStore.user.work_date"
                  v-model="this.transfer_date"
                  id="departureDateTransfer" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.transfer_date.$errors">
                <span class="error-message" v-for="error in v$.transfer_date.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                </span>
              </div>
            </template>
            <template v-if="isDeathTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.death_departure.reason.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.death_departure.reason.$error }">*Reason</span>
                <select v-model="death_departure" class="form-control" :class="{ 'form-control-validation-error': v$.death_departure.reason.$error }">
                  <option selected v-for="(reasons, index) in animalStore.options.all_death_reasons" :key="index" :value="reasons">
                    {{ reasons.subreason }}
                  </option>
                </select>
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.death_departure.reason.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.death_departure.reason.required.$message }}
                </span>
              </div>
            </template>
            <!--Sale Tab-->
            <template v-else-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.sale_departure.reason.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.sale_departure.reason.$error }">*Reason</span>
                <select v-model="sale_departure" class="form-control" :class="{ 'form-control-validation-error': v$.sale_departure.reason.$error }">
                  <option selected v-for="(reasons, index) in animalStore.options.sale_reasons" :key="index" :value="reasons">
                    {{ reasons.subreason }}
                  </option>
                </select>
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.sale_departure.reason.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.sale_departure.reason.required.$message }}
                </span>
              </div>
            </template>
            <!--Head Count for sales and deaths-->
            <div class="input-group input-group-lg" v-if="!isTransferTab">
              <span class="input-group-addon half">Head Count</span>
              <input type="text" disabled class="form-control" :value="animal_IDS.length" />
            </div>
            <!--Head Count for transfer with inline error for digital tags-->
            <template v-else>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.duplicate_digital_tag.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.duplicate_digital_tag.$error }">Head Count</span>
                <input type="text" disabled class="form-control" :value="animal_IDS.length" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.duplicate_digital_tag.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.duplicate_digital_tag.requiredIf.$message }}
                </span>
              </div>
            </template>
            <!--Death Tab-->
            <div class="input-group input-group-lg" v-if="isDeathTab">
              <span class="input-group-addon half">Notes</span>
              <textarea class="form-control" v-model="bulk_animal.departure_note" style="max-width: 100%; min-height: 100px" id="ranchSalesNotes"></textarea>
            </div>
            <!--Sale Tab-->
            <template v-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.destination.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.destination.$error }">*Destination</span>
                <textarea class="form-control" v-model="ranch_sales.destination" style="max-width: 100%" id="ranchSalesDestination"></textarea>
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.ranch_sales.destination.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.destination.required.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.weight.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.weight.$error }">*Total Weight</span>
                <input type="number" class="form-control" v-model="ranch_sales.weight" style="width: 80%; border-radius: 8px" />
                <p>lbs</p>
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.ranch_sales.weight.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.weight.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.sale_cents.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.sale_cents.$error }">*Total Sale Price</span>
                <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.ranch_sales.sale_cents.$error }">$</span>
                <input type="number" class="form-control" v-model="sale_cents" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.ranch_sales.sale_cents.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.sale_cents.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.sale_expense_cents.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.sale_expense_cents.$error }">
                  *Sales Expense
                </span>
                <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.ranch_sales.sale_expense_cents.$error }">$</span>
                <input type="number" class="form-control" v-model="sale_expense_cents" />
              </div>
              <div class="validation-alert inline-error-multiple-departure" v-if="v$.ranch_sales.sale_expense_cents.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.sale_expense_cents.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Notes</span>
                <textarea class="form-control" v-model="ranch_sales.note" style="max-width: 100%; min-height: 100px" id="ranchSalesNotes"></textarea>
              </div>
            </template>
          </div>
          <!--Transfer Tab-->
          <template v-if="isTransferTab">
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.transfer.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.transfer.$error }">*Group</span>
              <select v-model="transfer" class="form-control" :class="{ 'form-control-validation-error': v$.transfer.$error }">
                <option selected v-for="(group, index) in this.animalStore.groups" :key="index" :value="group">
                  {{ group.name }}
                </option>
              </select>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.transfer.$errors">
              <span class="error-message" v-for="error in v$.transfer.$errors" :key="error.$uid">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ error.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.cents_per_head.$error }">
              <span class="input-group-addon input-group-lg half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.cents_per_head.$error }">
                Purchase Cost Total
              </span>
              <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.group_event_values.cents_per_head.$error }">$</span>
              <input type="number" class="form-control" v-model="group_event_values.cents_per_head" />
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.group_event_values.cents_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.cents_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.pounds_per_head.$error }">
              <span class="input-group-addon input-group-lg half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.pounds_per_head.$error }">
                Purchase Weight Total
              </span>
              <input type="number" class="form-control" v-model="group_event_values.pounds_per_head" style="width: 70%; border-radius: 8px" />
              <p>lbs</p>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.group_event_values.pounds_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.pounds_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">
              <span
                class="input-group-addon input-group-lg half"
                :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">
                Trucking Cost
              </span>
              <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">$</span>
              <input type="number" class="form-control" v-model="group_event_values.trucking_cents_per_head" />
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.group_event_values.trucking_cents_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.trucking_cents_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.cattle_condition.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.cattle_condition.$error }">
                *Cattle Condition
              </span>
              <select
                v-model="group_event_values.cattle_condition"
                class="form-control"
                :class="{ 'form-control-validation-error': v$.group_event_values.cattle_condition.$error }">
                <option value="green">Green</option>
                <option value="medium">Medium Flesh</option>
                <option value="heavy">Heavy Flesh</option>
              </select>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.group_event_values.cattle_condition.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.cattle_condition.required.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.breeds.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.breeds.$error }">Breeds</span>
              <select disabled class="form-control" :class="{ 'form-control-validation-error': v$.breeds.$error }">
                <template v-if="this.breeds.size > 0 && !this.breeds.has(null)">
                  <option selected>{{ this.breeds.size }} Breeds</option>
                </template>
                <template v-else>
                  <option selected>No Breed</option>
                </template>
              </select>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.breeds.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.breeds.requiredIf.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.genders.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.genders.$error }">Gender</span>
              <select disabled class="form-control" :class="{ 'form-control-validation-error': v$.genders.$error }">
                <option v-if="!this.genders.has(null) && !this.genders.has('ungendered') && !this.genders.has('---')" selected>
                  {{ this.genders.size }} Gender(s)
                </option>
                <option v-else selected>No Gender</option>
              </select>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.genders.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.genders.requiredIf.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.dest_pen_id.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.dest_pen_id.$error }">*Pen</span>
              <select
                v-model="group_event_values.dest_pen_id"
                class="form-control"
                :class="{ 'form-control-validation-error': v$.group_event_values.dest_pen_id.$error }">
                <option v-for="(pen, index) in animalStore.pens" :key="index" :value="pen._id">{{ pen.name }}</option>
              </select>
            </div>
            <div class="validation-alert inline-error-multiple-departure" v-if="v$.group_event_values.dest_pen_id.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.dest_pen_id.required.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg">
              <span class="input-group-addon half">Notes</span>
              <textarea v-model="group_event_values.notes" class="form-control" style="max-width: 100%; min-height: 100px" id="transferNotes"></textarea>
            </div>
          </template>
          <div class="text-center padding-x-sm" style="font-style: italic">* Indicates Required Field</div>
        </div>
        <div class="padding-x-sm">
          <div class="row padding-x-xs">
            <button
              v-if="isTransferTab"
              type="button"
              id="modal-select-button"
              class="btn btn-success padding-y-xl"
              @click="departureCheck"
              :disabled="disableTransferButton"
              style="border-radius: 30px; font-size: 18px">
              Create and Transfer
            </button>
            <button
              v-else
              type="button"
              id="modal-select-button"
              class="btn btn-success padding-y-xl"
              @click="departureCheck"
              style="border-radius: 30px; font-size: 18px">
              Save
            </button>
          </div>
          <div class="row padding-x-xs">
            <button type="button" class="btn btn-transparent padding-y-sm" data-dismiss="modal" style="border: none; font-size: 18px" @click="cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { ObjectId } from "@assets/js/helpers.js";
import { useUserStore } from "@stores/user";
import Animal from "@compositions/animal.vue";
import { useAnimalStore } from "@stores/animal";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf, minValue } from "@vuelidate/validators/dist/raw.esm";

export default {
  emits: ["emit_unselect"],
  props: {
    animal_IDS: { type: Object, required: true },
  },
  data() {
    return {
      tabs: [
        {
          name: "Sale",
          id: 0,
          isActive: false,
          show: true,
        },
        {
          name: "Death/Other",
          id: 1,
          isActive: true,
          show: true,
        },
        {
          name: "Transfer",
          id: 2,
          isActive: false,
          show: this.userStore.user.can_pb,
        },
      ],
      animals_to_be_departed: new Map(),
      active_tab: {},
      sale_cents: 0,
      sale_expense_cents: 0,
      bulk_animal: {},
      ranch_sales: {},
      death_departure: {},
      sale_departure: {},
      transfer: {},
      group_event_values: {},
      breeds: null,
      genders: null,
      max_applied_cost_visual_label: null,
      max_birth_date: null,
      max_birth_date_visual_label: null,
      max_wean_date_visual_label: null,
      max_wean_date: null,
      max_purchase_date_visual_label: null,
      max_purchase_date: null,
      transfer_date: null,
      disableTransferButton: false,
      duplicate_visual_tag: false,
      duplicate_digital_tag: false,
      applied_cost_date_conflict: false,
    };
  },
  setup() {
    const {
      formatCentsToDollars,
      formatDollarsToCents,
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      checkDigitalTagsOnTransfer,
      sanitizeVisualTagForValidation,
    } = Animal();
    const animalStore = useAnimalStore();

    const userStore = useUserStore();
    const v$ = useVuelidate({ $lazy: true, $scope: false });
    return {
      formatCentsToDollars,
      formatDollarsToCents,
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      checkDigitalTagsOnTransfer,
      sanitizeVisualTagForValidation,

      animalStore,
      userStore,
      v$,
    };
  },
  mounted() {
    window.$("#recordDepartureModal").on("hidden.bs.modal", (e) => {
      this.cancel();
    });
    this.active_tab = this.tabs[1];

    this.breeds = new Set();
    this.genders = new Set();
    //get all the breeds and genders from the animals selected
    for (let [key, value] of this.animals_to_be_departed) {
      //add genders to a set, later it's checked if it has null or ungendered
      if (!this.genders.has(value.gender)) {
        this.genders.add(value.gender);
      }
      //adds breeds to a set, later it checks if the animal's doesn't have a valid breed.
      if (value.breeds) {
        for (const breed of value.breeds) {
          if (this.animalStore.all_breeds.includes(breed.breed) && this.animalStore.options.pb_breeds.includes(breed.breed)) {
            this.breeds.add(breed.breed);
          } else if (this.animalStore.all_breeds.includes(breed.breed) && !this.animalStore.options.pb_breeds.includes(breed.breed)) {
            this.breeds.add("other");
          } else if (value.breeds.length === 1 && !this.animalStore.all_breeds.includes(breed.breed) && breed.breed == " ") {
            this.breeds.add(null);
          }
        }
      } else {
        this.breeds.add(null);
      }
    }

    this.transfer_date = this.userStore.user.work_date;
  },
  computed: {
    isDeathTab() {
      return this.active_tab.name === "Death/Other";
    },
    isSaleTab() {
      return this.active_tab.name === "Sale";
    },
    isTransferTab() {
      return this.active_tab.name === "Transfer";
    },
    returnIDS() {
      return this.animal_IDS;
    },
    validationErrors() {
      if (this.isTransferTab) {
        return [
          this.v$.transfer_date.$error,
          this.v$.transfer.$error,
          this.v$.group_event_values.$error,
          this.v$.breeds.$error,
          this.v$.genders.$error,
          this.v$.duplicate_digital_tag.$error,
        ];
      } else if (this.isDeathTab) {
        return [this.v$.death_departure.$error, this.v$.bulk_animal.$error];
      } else if (this.isSaleTab) {
        return [this.v$.sale_departure.$error, this.v$.bulk_animal.$error, this.v$.ranch_sales.$error];
      }
      return false;
    },
    fieldsToBeValidated() {
      if (this.isTransferTab) {
        return [this.v$.transfer_date, this.v$.transfer, this.v$.group_event_values, this.v$.breeds, this.v$.genders, this.v$.duplicate_digital_tag];
      } else if (this.isDeathTab) {
        return [this.v$.death_departure, this.v$.bulk_animal];
      } else if (this.isSaleTab) {
        return [this.v$.sale_departure, this.v$.bulk_animal, this.v$.ranch_sales];
      }
      return false;
    },
  },
  methods: {
    cancel() {
      this.v$.$reset();
      this.death_departure = {};
      this.sale_departure = {};
      this.bulk_animal.departure_dtz = null;
      this.bulk_animal.departure_reason = null;
      this.bulk_animal.departure_subreason = null;
      this.bulk_animal.departure_note = null;
      this.sale_cents = 0;
      this.sale_expense_cents = 0;
      this.ranch_sales_sales = {};
      this.disableTransferButton = false;
      this.transfer = {};
      this.group_event_values = {};
      window.$(".loader").fadeOut();
    },
    async checkForTags() {
      for (let [key, value] of this.animals_to_be_departed) {
        this.animal_digital_tag = this.getAnimalPrimaryDigitalLabel(value);
        if (!this.animal_digital_tag) {
          let animal_visual = value.visual ? value.visual : this.getAnimalPrimaryVisualLabel(value);
          if (animal_visual) {
            animal_visual = this.sanitizeVisualTagForValidation(animal_visual);
          }
          if (this.animalStore.visual_pb_values.has(animal_visual)) {
            this.duplicate_visual_tag = true;
            return true;
          }
        } else if (await this.checkDigitalTagsOnTransfer(this.animalStore.pb_eid_values, this.animal_digital_tag, value)) {
          this.duplicate_digital_tag = true;
          return true;
        }
      }
      this.duplicate_visual_tag = false;
      this.duplicate_digital_tag = false;
      return false;
    },
    async departureCheck() {
      this.applied_cost_date_conflict = false;
      let max_applied_cost_date = DateTime.fromISO(this.bulk_animal.departure_dtz);
      let luxon_departure_dtz = DateTime.fromISO(this.bulk_animal.departure_dtz);
      //formats/assigns the dates for comparison/validation
      for (let [id, animal] of this.animals_to_be_departed) {
        let luxon_birth_date = DateTime.fromISO(animal.birth_date);
        let luxon_purchase_date = animal.purchase_date ? DateTime.fromISO(animal.purchase_date) : null;
        let luxon_wean_date = animal.wean_date ? DateTime.fromISO(animal.wean_date) : null;
        //assigns a date for the first time only, if a date is already present, the max value is kept.
        if (!this.max_birth_date && luxon_birth_date) {
          this.max_birth_date = luxon_birth_date;
        }
        if (this.max_birth_date && luxon_birth_date) {
          this.max_birth_date = DateTime.max(this.max_birth_date, luxon_birth_date);
          if (this.max_birth_date <= luxon_birth_date) {
            this.max_birth_date_visual_label = animal.visual;
          }
        }
        if (!this.max_purchase_date && luxon_purchase_date) {
          this.max_purchase_date = luxon_purchase_date;
        }
        if (this.max_purchase_date && luxon_purchase_date) {
          this.max_purchase_date = DateTime.max(this.max_purchase_date, luxon_purchase_date);
          if (this.max_purchase_date <= luxon_purchase_date) {
            this.max_purchase_date_visual_label = animal.visual;
          }
        }
        if (!this.max_wean_date && luxon_wean_date) {
          this.max_wean_date = luxon_wean_date;
        }
        if (this.max_wean_date && luxon_wean_date) {
          this.max_wean_date = DateTime.max(this.max_wean_date, luxon_wean_date);
          if (this.max_wean_date <= luxon_wean_date) {
            this.max_wean_date_visual_label = animal.visual;
          }
        }
        //gets max date of applied costs
        if (animal.applied_costs.length > 0) {
          for (let cost of animal.applied_costs) {
            cost = DateTime.fromISO(cost.application_dtz);
            max_applied_cost_date = DateTime.max(max_applied_cost_date, cost);
          }
          if (luxon_departure_dtz < max_applied_cost_date) {
            this.applied_cost_date_conflict = true;
            this.max_applied_cost_visual_label = animal.visual;
          }
        }
      }
      //formats the dates if not null.
      if (this.bulk_animal.departure_dtz) {
        this.bulk_animal.departure_dtz = DateTime.fromISO(this.bulk_animal.departure_dtz).toFormat("yyyy-LL-dd");
      } else {
        this.bulk_animal.departure_dtz = null;
      }
      this.bulk_animal.birth_date = this.max_birth_date ? this.max_birth_date.toFormat("yyyy-LL-dd") : null;
      this.bulk_animal.purchase_date = this.max_purchase_date ? this.max_purchase_date.toFormat("yyyy-LL-dd") : null;
      this.bulk_animal.wean_date = this.max_wean_date ? this.max_wean_date.toFormat("yyyy-LL-dd") : null;

      this.disableTransferButton = true;

      if (this.isTransferTab) {
        //If there's a transfer ID(group selected), check for tags and proceed, otherwise validate the form.
        if (this.transfer._id) {
          window.$(".loader").fadeIn();
          this.animalStore.getVisualTagsFromPBAnimals(this.transfer._id).then(() => {
            this.checkForTags()
              .then(window.$(".loader").fadeOut())
              .then(() => {
                this.bulk_animal.departure_reason = "sold";
                this.bulk_animal.departure_subreason = "Transfer to Performance Beef";
                this.bulk_animal.departure_dtz = this.transfer_date;
                //check if the other fields are valid
                this.fieldsToBeValidated.forEach((field) => field.$touch());
                if (this.validationErrors.includes(true)) {
                  this.$nextTick(() =>
                    //scrolls to the first error div
                    document.getElementsByClassName("inline-error-multiple-departure")[0].scrollIntoView({
                      block: "start",
                      behavior: "smooth",
                    })
                  );
                  this.disableTransferButton = false;
                  return false;
                } else {
                  this.recordTransfer();
                }
              });
          });
        } else {
          //if no group is selected we can target that error directly
          this.v$.transfer.$touch();
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-multiple-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        }
      } else if (this.isDeathTab) {
        this.bulk_animal.departure_subreason = this.death_departure.subreason;
        this.bulk_animal.departure_reason = this.death_departure.reason;
        this.fieldsToBeValidated.forEach((field) => field.$touch());
        if (this.validationErrors.includes(true)) {
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-multiple-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        } else {
          this.recordDeath();
        }
      } else if (this.isSaleTab) {
        this.bulk_animal.departure_subreason = this.sale_departure.subreason;
        this.bulk_animal.departure_reason = this.sale_departure.reason;
        this.ranch_sales.sale_cents = this.sale_cents;
        this.ranch_sales.sale_expense_cents = this.sale_expense_cents;

        this.fieldsToBeValidated.forEach((field) => field.$touch());
        if (this.validationErrors.includes(true)) {
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-multiple-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        } else {
          this.recordSale();
        }
      }
    },
    isEmpty(value) {
      return value == null || value.length === 0;
    },
    recordDeath() {
      window.$(".loader").fadeIn();
      for (let [id, animal] of this.animals_to_be_departed) {
        animal.departure_dtz = this.bulk_animal.departure_dtz;
        animal.departure_note = this.bulk_animal.departure_note;
        animal.departure_reason = this.bulk_animal.departure_reason;
        animal.departure_subreason = this.bulk_animal.departure_subreason;
        this.animalStore.editAnimal(animal);
      }
      window.$("#recordMultipleDepartureModal").modal("hide");
      this.$emit("emit_unselect");
      this.animals_to_be_departed.clear();
      this.v$.$reset();
      window.$(".loader").fadeOut();
    },
    recordTransfer() {
      window.$(".loader").fadeIn();
      this.group_event_values.head_diff = this.animal_IDS.length;
      this.group_event_values._id = ObjectId();
      this.group_event_values.work_date = this.bulk_animal.departure_dtz;
      this.group_event_values.type = "add";

      this.group_event_values.pounds_per_head = this.group_event_values.pounds_per_head ? this.group_event_values.pounds_per_head : 0;
      this.group_event_values.cents_per_head = this.formatDollarsToCents(this.group_event_values.cents_per_head);
      this.group_event_values.trucking_cents_per_head = this.formatDollarsToCents(this.group_event_values.trucking_cents_per_head);
      this.group_event_values.breeds = Array.from(this.breeds);
      this.group_event_values.genders = Array.from(this.genders);

      this.bulk_animal.departure_note = this.group_event_values.notes ? this.group_event_values.notes : null;

      this.animalStore
        .putGroupEvent(this.transfer._id, this.group_event_values)
        .then((response) => {
          //loops through each animal, creating a pb copy.
          for (let [key, value] of this.animals_to_be_departed) {
            let pb_animal = {};
            pb_animal.events = [];
            let primary_tag_value = null;
            let primary_visual_value = null;
            pb_animal._id = ObjectId();
            pb_animal.group_id = this.transfer._id;
            pb_animal.gender = value.gender;
            pb_animal.was_id = value._id;

            //loop through the ranch animal breeds and assign a new mongo ID for each breed
            pb_animal.breeds = [];
            const breeds = JSON.parse(JSON.stringify(value.breeds));
            for (let breedObj of breeds) {
              if (this.animalStore.all_breeds.includes(breedObj.breed)) {
                pb_animal.breeds.push({
                  _id: ObjectId(),
                  fraction: (breedObj.fraction /= 100),
                  breed: breedObj.breed,
                });
              }
            }

            //loop through the ranch animal tags, assign new ID's and set the primary visual and digital tags id.
            pb_animal.tags = JSON.parse(JSON.stringify(value.tags));
            for (let index in pb_animal.tags) {
              if (value.primary_tag_id === pb_animal.tags[index]._id) {
                primary_tag_value = pb_animal.tags[index].digital_label;
              }
              if (value.primary_visual_id === pb_animal.tags[index]._id) {
                primary_visual_value = pb_animal.tags[index].visual_label;
              }
              pb_animal.tags[index]._id = ObjectId();
            }
            pb_animal.primary_tag_id = pb_animal.tags.find((x) => x.digital_label === primary_tag_value)._id;
            pb_animal.primary_visual_id = pb_animal.tags.find((x) => x.visual_label === primary_visual_value)._id;
            pb_animal.feedyard_id = value.feedyard_id;
            pb_animal.created_dtz = DateTime.fromISO(this.bulk_animal.departure_dtz).set({ hour: 12 }).toUTC().toISO();

            pb_animal.events.push({
              _id: ObjectId(),
              created_dtz: pb_animal.created_dtz,
              type: null,
              note: this.bulk_animal.departure_note ? this.bulk_animal.departure_note : null,
              pen_id: this.group_event_values.dest_pen_id,
            });
            this.animalStore.addPBAnimal(pb_animal);
            value.departure_dtz = this.bulk_animal.departure_dtz;
            value.departure_note = this.bulk_animal.departure_note;
            value.departure_reason = this.bulk_animal.departure_reason;
            value.departure_subreason = this.bulk_animal.departure_subreason;
            this.animalStore.editAnimal(value);
          }
        })
        .then(() => {
          window.$("#recordMultipleDepartureModal").modal("hide");
          window.$(".loader").fadeOut();
          this.$emit("emit_unselect");
          this.animals_to_be_departed.clear();
          this.v$.$reset();
        });
    },
    recordSale() {
      window.$(".loader").fadeIn();
      //ranch_sales info
      this.ranch_sales._id = ObjectId();
      this.ranch_sales.feedyard_id = this.userStore.auth.feedyard_id;
      this.ranch_sales.departure_date = this.bulk_animal.departure_dtz;
      this.ranch_sales.animal_ids = this.animal_IDS;
      this.ranch_sales.sale_cents = this.formatDollarsToCents(this.ranch_sales.sale_cents);
      this.ranch_sales.sale_expense_cents = this.formatDollarsToCents(this.ranch_sales.sale_expense_cents);
      this.bulk_animal.departure_note = this.ranch_sales.note;
      this.animalStore
        .addRanchSales(this.ranch_sales)
        .then(() => {
          for (let [id, animal] of this.animals_to_be_departed) {
            animal.departure_dtz = this.bulk_animal.departure_dtz;
            animal.departure_note = this.bulk_animal.departure_note;
            animal.departure_reason = this.bulk_animal.departure_reason;
            animal.departure_subreason = this.bulk_animal.departure_subreason;
            this.animalStore.editAnimal(animal);
          }
        })
        .then(() => {
          window.$("#recordMultipleDepartureModal").modal("hide");
          this.$emit("emit_unselect");
          this.animals_to_be_departed.clear();
          this.v$.$reset();
          window.$(".loader").fadeOut();
        });
    },
    setActiveTab(tab) {
      let self = this;
      tab.isActive = true;
      if (tab.name !== this.active_tab.name) {
        this.death_departure = {};
        this.sale_departure = {};
        this.transfer = {};
        this.group_event_values = {};
        this.v$.$reset();
      }
      this.active_tab = tab;
      this.tabs.forEach(function (tab) {
        if (tab.id !== self.active_tab.id) {
          tab.isActive = false;
        }
      });
      if (tab.name === "Transfer") {
        window.$(".loader").fadeIn();
        //get groups and pens only if the user is on the transfer tab.
        this.animalStore.getGroups().then(this.animalStore.getPens()).then(window.$(".loader").fadeOut());
      }
    },
  },
  validations() {
    return {
      //Transfers
      transfer_date: {
        required: helpers.withMessage("Date is required.", required),
        requiredIfDepartureDateIsGreaterThanWorkDate: helpers.withMessage(
          "Departure Date can't be greater than " + this.userStore.user.work_date + ".",
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz > this.userStore.user.work_date) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfBirthDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_birth_date_visual_label} birthdate.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.bulk_animal.birth_date > this.bulk_animal.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfWeanDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_wean_date_visual_label} wean date.`,
          requiredIf(() => {
            if (this.bulk_animal.wean_date > this.bulk_animal.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfPurchaseDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_purchase_date_visual_label} purchase date.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.bulk_animal.purchase_date > this.bulk_animal.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
      },
      transfer: {
        name: {
          required: helpers.withMessage("Group is required.", required),
          //Visual tag error is based on the group selected so I placed it here.
          requiredIf: helpers.withMessage(
            "Visual tag(s) already present in the selected group.",
            requiredIf(() => {
              if (this.duplicate_visual_tag) {
                this.v$.duplicate_visual_tag.touch();
                return this.duplicate_visual_tag;
              }
            })
          ),
        },
      },
      group_event_values: {
        cents_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        pounds_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        trucking_cents_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        cattle_condition: { required: helpers.withMessage("Cattle Condition is required.", required) },
        dest_pen_id: { required: helpers.withMessage("Pen is required.", required) },
      },
      breeds: {
        requiredIf: helpers.withMessage(
          "Breed is required.",
          requiredIf(() => {
            return this.breeds.has(null);
          })
        ),
      },
      genders: {
        requiredIf: helpers.withMessage(
          "Gender is required.",
          requiredIf(() => {
            return this.genders.has(null) || this.genders.has("ungendered") || this.genders.has("---");
          })
        ),
      },
      duplicate_digital_tag: {
        requiredIf: helpers.withMessage(
          "Digital tag(s) already present in Performance Beef.",
          requiredIf(() => {
            if (this.duplicate_digital_tag) {
              this.v$.duplicate_digital_tag.touch();
              return this.duplicate_digital_tag;
            }
          })
        ),
      },
      //Deaths
      death_departure: {
        reason: { required: helpers.withMessage("Departure reason is required.", required) },
      },
      //Dates for the sale and death/other departure
      bulk_animal: {
        departure_dtz: { required: helpers.withMessage("Departure Date is required.", required) },
        requiredIfBirthDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_birth_date_visual_label} birthdate.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.bulk_animal.birth_date > this.bulk_animal.departure_dtz) {
              this.v$.bulk_animal.touch();
              return true;
            }
          })
        ),
        requiredIfWeanDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_wean_date_visual_label} wean date.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.bulk_animal.wean_date > this.bulk_animal.departure_dtz) {
              this.v$.bulk_animal.touch();
              return true;
            }
          })
        ),
        requiredIfPurchaseDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) => `Departure Date should not be before ${this.max_purchase_date_visual_label} purchase date.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.bulk_animal.purchase_date > this.bulk_animal.departure_dtz) {
              this.v$.bulk_animal.touch();
              return true;
            }
          })
        ),
        requiredIfAppliedCostDateIsGreaterThanDepartureDate: helpers.withMessage(
          ({ $model }) =>
            `${this.max_applied_cost_visual_label} has an event/cost recorded after the departure date. Please remove/edit the associated cost or change the departure date.`,
          requiredIf(() => {
            if (this.bulk_animal.departure_dtz && this.applied_cost_date_conflict) {
              this.v$.bulk_animal.touch();
              return true;
            }
          })
        ),
      },
      //Sales
      sale_departure: {
        reason: { required: helpers.withMessage("Departure reason is required.", required) },
      },
      ranch_sales: {
        destination: { required: helpers.withMessage("Destination is required.", required) },
        weight: {
          requiredIf: helpers.withMessage(
            "Total Weight must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.weight) || this.ranch_sales.weight < 0) {
                this.v$.ranch_sales.weight.touch();
                return true;
              }
            })
          ),
        },
        sale_cents: {
          requiredIf: helpers.withMessage(
            "Total Sale Price must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.sale_cents) || this.ranch_sales.sale_cents < 0) {
                this.v$.ranch_sales.sale_cents.touch();
                return true;
              }
            })
          ),
        },
        sale_expense_cents: {
          requiredIf: helpers.withMessage(
            "Sales Expense must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.sale_expense_cents) || this.ranch_sales.sale_expense_cents < 0) {
                this.v$.ranch_sales.sale_expense_cents.touch();
                return true;
              }
            })
          ),
        },
      },
    };
  },
  watch: {
    //We need this watcher because when opening the departure modal, the user needs to see how many breeds, genders are selected without hitting submit.
    animal_IDS: {
      immediate: true,
      handler(newValue, oldValue) {
        for (let id of newValue) {
          const animal = this.animalStore.animals.find((x) => id === x._id);
          this.animals_to_be_departed.set(animal._id, animal);
        }
      },
    },
  },
};
</script>
<style>
.modal-body {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
p {
  padding-top: 10px;
}
</style>
