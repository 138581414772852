function RandUints(len) {
  var array = new Uint32Array(len);
  if (typeof window != "undefined" && typeof window.crypto != "undefined") {
    window.crypto.getRandomValues(array);
  } else {
    for (let idx = 0; idx < len; idx++) {
      array[idx] = Math.floor(Math.random() * (1 << 16));
      array[idx] <<= 16;
      array[idx] |= Math.floor(Math.random() * (1 << 16));
    }
  }
  return array;
}

const FakeMicros = {
  // Microsecond timestamps would be nice, but what we really want is to avoid collisions and keep IDs in order.
  // Using milliseconds with a counter for the micro-part satisfies this.
  last_microtime: 0,
  get() {
    let micronow = Date.now() * 1000;
    if (this.last_microtime < micronow) {
      this.last_microtime = micronow + (RandUints(1)[0] % 1000);
    } else {
      this.last_microtime += 1;
    }
    return this.last_microtime;
  },
};

export function ObjectId(time) {
  let microtime = 0;
  let rand_array = RandUints(3);

  if (time == undefined) {
    // If unspecified, default to current time.
    microtime = FakeMicros.get();
  } else if (typeof time == "string") {
    // Caller may pass a date-time as a string.
    time = new Date(time);
    if (time == undefined) return undefined;
    time = time.getTime();
    if (isNaN(time)) return undefined;
    microtime = time * 1000 + (rand_array[0] % 1000);
  } else if (typeof time == "object" && time.constructor.name == "Date") {
    // Caller may pass a Date object.
    time = time.getTime();
    if (isNaN(time)) return undefined;
    microtime = time * 1000 + (rand_array[0] % 1000);
  } else {
    return undefined;
  }

  let sec = Math.floor(microtime / 1000000);
  let us = microtime % 1000000;
  let oidhex = ("00000000" + sec.toString(16)).slice(-8);
  oidhex += ("00000" + us.toString(16)).slice(-5);
  oidhex += ("000" + rand_array[1].toString(16)).slice(-3);
  rand_array[2] = 0; // TODO: Remove this line when www/public is deleted.
  oidhex += ("00000000" + rand_array[2].toString(16)).slice(-8);
  return oidhex;
}

export function errorHandler(error) {
  window.$("#error").html(error).show();
  window.$("#saveButton").prop("disabled", false);
  document.getElementsByClassName("page-content")[0].scrollTop = 0;
  window.$(".loader").fadeOut();
}
