<script>
import { DateTime } from "luxon";

import Util from "@compositions/util.vue";

import { useAnimalStore } from "@stores/animal";
import { useInventoryStore } from "@stores/inventory";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";
import { useToast } from "vue-toastification";

import { ObjectId } from "@assets/js/helpers.js";

export default function Animals() {
  const animalStore = useAnimalStore();
  const inventoryStore = useInventoryStore();
  const herdStore = useHerdStore();
  const pastureStore = usePastureStore();
  const toast = useToast();

  const getAnimalPrimaryDigitalLabel = (animal) => {
    if (animal && animal.tags && animal.tags.length > 0 && animal.primary_tag_id && animal.tags.find((x) => x._id === animal.primary_tag_id).digital_label) {
      return animal.tags.find((x) => x._id === animal.primary_tag_id).digital_label;
    }
    return "";
  };

  const getAnimalPrimaryVisualLabel = (animal) => {
    if (animal && animal.tags && animal.tags.length > 0 && animal.primary_visual_id && animal.tags.find((x) => x._id === animal.primary_visual_id).visual_label) {
      return animal.tags.find((x) => x._id === animal.primary_visual_id).visual_label;
    }
    return "";
  };

  const getSireLabel = (animal) => {
    if (animal && animal.tags && animal.tags.length > 0 && animal.primary_visual_id && animal.tags.find((x) => x._id === animal.primary_visual_id).visual_label) {
      return animal.tags.find((x) => x._id === animal.primary_visual_id).visual_label;
    }
    if (animal && animal.category == "artificial_insemination") {
      return animal.name;
    }
    return "";
  };

  const hasDuplicateEID = async (ranch_eid, pb_eid, animal_tag, animal) => {
    const regex = new RegExp("^(\\-)|[^\\d\\n]", "gm");
    const result = ``;
    animal_tag = animal_tag.replace(regex, result);
    //Validation against ranch animals
    if (animal_tag === "") {
      return false;
    }
    //If the animal doesn't have a was_id, we check the length of eid values, if it's not equal to one there's a dupe. Otherwise the id's should match.
    //these values are set on the animal store.
    if (animal._id && !animal.was_id) {
      if (ranch_eid.has(animal_tag) && ranch_eid.get(animal_tag).length !== 1) {
        return true;
      } else if (ranch_eid.has(animal_tag) && ranch_eid.get(animal_tag).join() !== animal._id) {
        return true;
      }
    }
    //for animals without a was_id and for new animals (no animal._id, created on the animal form) we just check if the value is present.
    else if (!animal.was_id) {
      return ranch_eid.has(animal_tag) || pb_eid.has(animal_tag);
    }

    //Validation against beef animals.
    if (animal._id && !animal.was_id) {
      //if there's a digital tag on beef and the beef animal has a was_id key that doesn't match the ranch animal id
      return pb_eid.has(animal_tag) && pb_eid.get(animal_tag)[0] !== animal._id;
    }
    //for animals transfered from perfomance beef into performance ranch, where the performance beef animal has a was_id not null, meaning it came from ranch.
    //And where there are no other existing tags on ranch. We check against beef and ranch
    if (animal.was_id && !pb_eid.has(animal_tag)) {
      if (ranch_eid.has(animal_tag) && ranch_eid.get(animal_tag) !== animal._id) {
        return true;
      }
    } else if (animal.was_id && pb_eid.has(animal_tag) && pb_eid.get(animal_tag)[0]) {
      let ranch_animal = await animalStore.getAnimalById(pb_eid.get(animal_tag)[0]);
      //if the beef animal was_id matches the original ranch id, we allow the user to have a dupe.
      //However on the original ranch document, this will trigger a conflict, so the user can only edit the most recent version of the animal.
      if (ranch_animal) {
        return pb_eid.get(animal_tag)[0] !== ranch_animal._id;
      }
    }
    //handles animals that are transferred from beef into ranch
    else if (animal.was_id && pb_eid.has(animal_tag) && !pb_eid.get(animal_tag)[0]) {
      return pb_eid.get(animal_tag)[1] !== animal.was_id;
    }
    return false;
  };

  const checkDigitalTagsOnTransfer = async (pb_eid, animal_tag, animal) => {
    const regex = new RegExp("^(\\-)|[^\\d\\n]", "gm");
    const result = ``;
    animal_tag = animal_tag.replace(regex, result);

    //Validation against beef animals for ranch animals without a was_id.
    if (animal._id && !animal.was_id) {
      return pb_eid.has(animal_tag);
    }
    //for animals transfered from perfomance beef into performance ranch, where the performance beef animal has a was_id not null, meaning it came from ranch
    else if (animal.was_id && pb_eid.get(animal_tag)[0]) {
      let ranch_animal = await animalStore.getAnimalById(pb_eid.get(animal_tag)[0]);
      if (ranch_animal && pb_eid.has(animal_tag) && pb_eid.get(animal_tag)[0] === ranch_animal._id) {
        //if the beef animal was_id matches the original ranch id, we allow the user to have a dupe.
        //However on the original ranch document, this will trigger a conflict, so the user can only edit the most recent version of the animal.
        return false;
      } else {
        return true;
      }
    } else if (animal.was_id && pb_eid.has(animal_tag) && !pb_eid.get(animal_tag)[0]) {
      //if the beef animal doesn't have a was_id, we compare ranch was_id against the beef animal id
      return pb_eid.has(animal_tag) && pb_eid.get(animal_tag)[1] !== animal.was_id;
    }
    return false;
  };

  const getBreedsLabel = (animal, separator = " ", percent_separator = " ", empty_label = "N/A", combine_others = true) => {
    let breeds = [];
    let count = 0;
    let totalFraction = 100;

    if (!animal.breeds || !animal.breeds.length) {
      breeds.push("N/A");
      return breeds.join(separator);
    }

    breedObj: for (const breedObj of animal.breeds) {
      if (combine_others && count == 2) {
        breeds.push(` More${percent_separator}` + totalFraction.toFixed(1) + "%");
        break breedObj;
      } else if ((breedObj.breed === " " && breedObj.fraction) || !breedObj.breed || breedObj.breed === "Unknown") {
        breeds.push("Unknown" + percent_separator + (breedObj.fraction > 0 ? breedObj.fraction : "0") + "%");
        totalFraction -= breedObj.fraction;
        count++;
      } else {
        breeds.push(animalStore.breed_labels[breedObj.breed] + percent_separator + (breedObj.fraction > 0 ? breedObj.fraction : "0") + "%");
        totalFraction -= breedObj.fraction;
        count++;
      }
    }

    if ((breeds.length === 1 && breeds[0] === `Unknown${percent_separator}100.0%`) || !breeds.length || !breeds) {
      breeds = [];
      breeds.push(empty_label);
      return breeds.join(separator);
    }

    return breeds.join(separator);
  };

  const updateHeiferToCow = (heifer) => {
    try {
      heifer.gender = "cow";
      heifer.role = "dam";
      animalStore.editAnimal(heifer);
      console.log("The heifer has been updated to a cow/dam");
    } catch (error) {
      console.log(error);
    }
  };

  const updateDamBCS = async (dam, calf) => {
    try {
      // calf birth_date is required to update bcs
      dam.calf_birth_date = calf.birth_date;
      dam.calf_animal_id = calf._id;

      // update dam's events bcs, then update calf to get the dam_body_condition value on the top lvl document.
      let damToBeUpdated = await animalStore.editAnimal(dam);
      if (damToBeUpdated) {
        await animalStore.editAnimal(calf);
      }

      console.log("The Dam has been updated with new BCS score");
    } catch (error) {
      console.log(error);
    }
  };

  const hasDuplicateVisualIDAndBirthdate = async (animal, visual_and_birthdate_values) => {
    //Sanitize the visual tag and compare against the values that are built in the store.
    //Even though web tags will be sanitized upon saving, we do it again here for validation purposes.
    //This will handle animals that are created/transferred from other sources but web.
    let visual_tag = getAnimalPrimaryVisualLabel(animal).toLowerCase();
    const animalVisualID = removeWhiteTrailingSpacesFromVisualTags(visual_tag);
    if (!animal.was_id && visual_and_birthdate_values.has(animalVisualID)) {
      // if there's an existing visual tag in the map, and a matching birthdate key with different animal._id || more than one animal._id with the same birthdate key
      // as a value, there's a tag conflict.
      if (
        visual_and_birthdate_values.get(animalVisualID).has(animal.birth_date) &&
        visual_and_birthdate_values.get(animalVisualID).get(animal.birth_date).length !== 1
      ) {
        return true;
      } else if (
        visual_and_birthdate_values.get(animalVisualID).has(animal.birth_date) &&
        visual_and_birthdate_values.get(animalVisualID).get(animal.birth_date).length === 1 &&
        visual_and_birthdate_values.get(animalVisualID).get(animal.birth_date).join() !== animal._id
      ) {
        return true;
      } else {
        return false;
      }
    }
    //if the ranch animal has a was_id we will have to check if it points to an existing animal on beef, that points to an existing animal on ranch and allow the user to save.
    //Since the animal's can be transfered multiple times, there might be multiple visuals, birthdates and ids that match.
    if (animal.was_id) {
      let performance_beef_animal = await animalStore.getAnimalById(animal.was_id);
      if (performance_beef_animal && performance_beef_animal.was_id) {
        let ranch_animal = await animalStore.getAnimalById(performance_beef_animal.was_id);
        if (ranch_animal && visual_and_birthdate_values.has(animalVisualID) && visual_and_birthdate_values.get(animalVisualID).has(animal.birth_date)) {
          return !visual_and_birthdate_values.get(animalVisualID).get(animal.birth_date).includes(ranch_animal._id);
        } else {
          return true;
        }
      } else {
        return (
          visual_and_birthdate_values.get(animalVisualID).has(animal.birth_date) &&
          visual_and_birthdate_values.get(animalVisualID).get(animal.birth_date).join() !== animal._id
        );
      }
    }
  };

  //removes white/trailing spaces from the visual tag the user is saving. This modifies the tag.
  const removeWhiteTrailingSpacesFromVisualTags = (animal_visual_tag) => {
    return animal_visual_tag.replace(/\s+/gm, " ").trim();
  };

  //Used to detect invalid characters and alert the user.
  const visualTagHasInvalidCharacters = (animal_visual_tag) => {
    const regex = /[^~!@#$%^&*()-_=+[\]{}:;'",.<>/? A-Za-zŽžÀ-ÖØ-Ýà-öø-ÿ 0-9]/g;
    return regex.test(animal_visual_tag);
  };

  //used for validation purposes only, removes white/trailing spaces and sets the visual tags to lower case.
  //called when doing transfers. The same approach is done when building the visual_and_birthdate_values and pb_animals_visual tags on the store
  const sanitizeVisualTagForValidation = (animal_visual_tag) => {
    return animal_visual_tag.replace(/\s+/gm, " ").trim().toLowerCase();
  };

  const validateBreedFraction = (breeds) => {
    let totalFraction = 0;
    if (!breeds || breeds.length === 0) {
      return true;
    }
    for (let breed of breeds) {
      breed.fraction = parseFloat(breed.fraction);
      if (breed.fraction <= 0) {
        return false;
      }
      totalFraction += breed.fraction;
    }
    totalFraction = parseFloat(totalFraction.toFixed(1));
    return totalFraction <= 100.0;
  };

  const formatDollarsToCents = (value) => {
    value = (value + "").replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  };

  const formatCentsToDollars = (value) => {
    value = (value + "").replace(/[^\d.-]/g, "");
    value = parseFloat(value);
    return value ? value / 100 : 0;
  };

  const formatAnimal = (animal) => {
    const herd = herdStore.herds.find((x) => x._id == animal.herd_id);
    if (herd) {
      animal.herd = herd.name;
    }

    const pasture = pastureStore.pastures.find((x) => x._id == animal.pasture_id);
    if (pasture) {
      animal.pasture = pasture.name;
    }

    const birth_pasture = pastureStore.pastures.find((x) => x._id == animal.birth_pasture_id);
    if (birth_pasture) {
      animal.birth_pasture = birth_pasture ? birth_pasture.name : null;
    }

    return animal;
  };

  const addCompletedCost = (animal) => {
    if (!animal.applied_costs) {
      animal.applied_costs = [];
    }

    animal.applied_costs.push({
      _id: ObjectId(),
      application_dtz: null,
      dose: 0.0,
      inventory_event_id: ObjectId(),
      type: "typical",
    });
  };

  const removeCompletedCost = (index, applied_costs, removed_completed_costs) => {
    removed_completed_costs.push(applied_costs[index]);
    applied_costs.splice(index, 1);
  };

  const validateCompletedCosts = (applied_costs) => {
    let error = "";
    for (const idx in applied_costs) {
      if (!applied_costs[idx].type) {
        continue;
      }

      if (!applied_costs[idx].application_dtz || !DateTime.fromISO(applied_costs[idx].application_dtz).isValid) {
        error += "- Date is required.\n";
      }

      if (applied_costs[idx].type === "typical" && !applied_costs[idx].inventory) {
        error += "- Treatment is required.\n";
      }

      if (
        applied_costs[idx].application_dtz &&
        applied_costs[idx].inventory &&
        applied_costs[idx].inventory.property &&
        DateTime.fromISO(applied_costs[idx].application_dtz) < DateTime.fromISO(applied_costs[idx].inventory.property.start_date)
      ) {
        error += `- ${applied_costs[idx].inventory.name}'s date must be on or after ${applied_costs[idx].inventory.property.start_date}. This is the date when you added ${applied_costs[idx].inventory.name} to your inventory. Navigate to Inventory tab to adjust the start date.`;
      }

      if (applied_costs[idx].dose <= 0) {
        error += "- Dosage must be more than zero.\n";
      }

      if (error.length > 0) {
        toast.error("Completed Costs Errors:\n" + error);
        return false;
      }
    }
    return true;
  };

  const setInventoryInCosts = (applied_costs) => {
    for (let applied_cost of applied_costs) {
      if (inventoryStore.map_events.has(applied_cost.inventory_event_id)) {
        const event = inventoryStore.map_events.get(applied_cost.inventory_event_id);

        applied_cost.inventory = inventoryStore.allInventory.get(event.inventory_id);
        applied_cost.dose = event.diff * -1;
      }
    }
  };

  const setDiagnosisInEvents = (events) => {
    for (let event of events) {
      // only single diagnosis is supported at the moment
      if (event.diagnosis_ids?.length > 0 && animalStore.map_diagnoses.has(event.diagnosis_ids[0])) {
        const diagnosis = animalStore.map_diagnoses.get(event.diagnosis_ids[0]);
        event.diagnosis = diagnosis;
      }
    }
  };

  const getSafeToShipDate = (applied_cost) => {
    if (!applied_cost.inventory || typeof applied_cost.inventory === "undefined" || applied_cost.inventory.withdrawal_days <= 0) {
      return "---";
    }
    const inventory = applied_cost.inventory;
    return DateTime.fromISO(applied_cost.application_dtz).plus({ days: inventory.withdrawal_days }).toFormat("D");
  };

  const addWeight = (animal) => {
    if (!animal.events) {
      animal.events = [];
    }

    animal.events.push({
      _id: ObjectId(),
      created_dtz: null,
      pounds: 0,
    });
  };

  const removeWeight = (index, events) => {
    events.splice(index, 1);
  };

  const addAnimalHistory = (animal) => {
    if (!animal.events) {
      animal.events = [];
    }

    animal.events.push({
      _id: ObjectId(),
      created_dtz: null,
      pounds: 0.0,
    });
  };

  const validateAppliedCostsRoleAndGender = (applied_costs, role, gender) => {
    let error = "";
    return applied_costs.some((cost) => {
      const incompatible_roles_for_cost = animalStore.options.incompatible_roles_for_applied_costs[cost.type] || [];
      const incompatible_genders_for_cost = animalStore.options.incompatible_genders_for_applied_costs[cost.type] || [];
      //We need to check for genders on events where the roles (calf and weaned) allow male and female genders.
      if (incompatible_roles_for_cost.includes(role) && incompatible_genders_for_cost.includes(gender)) {
        const errorMessage = `Invalid applied cost for this animal: ${animalStore.options.applied_costs_labels[cost.type]} event is not compatible with role: ${
          animalStore.options.role_labels[role]
        } and gender: ${animalStore.options.gender_labels[gender]}`;
        if (error.length > 0) {
          error += "\n";
        }
        error += errorMessage;
        toast.error("Event History Error:\n" + error);
        return true;
      } else if (incompatible_roles_for_cost.includes(role) && !incompatible_genders_for_cost.includes(gender)) {
        const errorMessage = `Invalid applied cost for this animal: ${animalStore.options.applied_costs_labels[cost.type]} event is not compatible with role: ${
          animalStore.options.role_labels[role]
        }`;
        if (error.length > 0) {
          error += "\n";
        }
        error += errorMessage;
        toast.error("Event History Error:\n" + error);
        return true;
      } else if (!incompatible_roles_for_cost.includes(role) && incompatible_genders_for_cost.includes(gender)) {
        const errorMessage = `Invalid applied cost for this animal: ${animalStore.options.applied_costs_labels[cost.type]} event is not compatible with gender: ${
          animalStore.options.gender_labels[gender]
        }`;
        if (error.length > 0) {
          error += "\n";
        }
        error += errorMessage;
        toast.error("Event History Error:\n" + error);
        return true;
      }
      return false;
    });
  };

  const getEventDOF = (event, birth_date) => {
    const date1 = DateTime.fromISO(event.created_dtz);
    if (!date1.isValid) {
      return null;
    }
    const date2 = DateTime.fromISO(birth_date);
    return date1.diff(date2, "days").days;
  };

  const getEventADG = (event, last_weight, last_date) => {
    const days_on_feed = getEventDOF(event, last_date);
    if (!last_weight || !event.pounds || !days_on_feed) {
      return 0;
    }
    return ((event.pounds - last_weight) / days_on_feed).toFixed(2);
  };

  const getEventAge = (birth_date, event_date) => {
    if (!birth_date || !event_date) {
      return null;
    }
    let monthDiff = Util.monthDiff(birth_date, event_date);
    return monthDiff.months < 12 ? monthDiff.toFormat("M") + " month(s)" : monthDiff.toFormat("y") + " year(s)";
  };

  const addEventHistory = (animal_events) => {
    if (!animal_events) {
      animal_events = [];
    }

    animal_events.push({
      _id: ObjectId(),
      application_dtz: null,
      type: "ai",
    });
  };

  const addNote = (animal_notes, animal) => {
    if (!animal_notes) {
      animal_notes = [];
    }

    const _id = ObjectId();

    animal_notes.push({
      _id: _id,
      date: null,
      type: null,
    });

    animal.events.push({
      _id: _id,
      date: null,
      type: "note",
    });
  };

  return {
    getAnimalPrimaryDigitalLabel,
    getAnimalPrimaryVisualLabel,
    getSireLabel,
    getBreedsLabel,
    updateHeiferToCow,
    updateDamBCS,
    validateBreedFraction,
    formatAnimal,
    hasDuplicateEID,
    hasDuplicateVisualIDAndBirthdate,
    checkDigitalTagsOnTransfer,
    formatDollarsToCents,
    formatCentsToDollars,
    addCompletedCost,
    removeCompletedCost,
    validateCompletedCosts,
    setInventoryInCosts,
    setDiagnosisInEvents,
    getSafeToShipDate,
    addWeight,
    removeWeight,
    addAnimalHistory,
    getEventDOF,
    getEventADG,
    sanitizeVisualTagForValidation,
    visualTagHasInvalidCharacters,
    removeWhiteTrailingSpacesFromVisualTags,
    getEventAge,
    validateAppliedCostsRoleAndGender,
    addEventHistory,
    addNote,
  };
}
</script>
