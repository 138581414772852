import * as VueRouter from "vue-router";

const routes = [
  {
    path: "/login",
    component: () => import("@/views/log-in.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/main.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/overview/overview.vue"),
      },
      {
        path: "overview",
        component: () => import("@/views/overview/overview.vue"),
      },
      {
        path: "bulk-edit",
        component: () => import("@/views/overview/bulk-edit.vue"),
      },
      {
        path: "edit/:id",
        name: "edit",
        component: () => import("@/views/overview/edit.vue"),
      },
      {
        path: "account_settings",
        component: () => import("@/views/account_settings/account_settings.vue"),
      },
      {
        path: "inventory/account_settings",
        component: () => import("@/views/account_settings/account_settings.vue"),
      },
      {
        path: "edit/account_settings",
        component: () => import("@/views/account_settings/account_settings.vue"),
      },
      {
        path: "pastures",
        component: () => import("@/views/pastures/pastures.vue"),
      },
      {
        path: "pastures/add",
        component: () => import("@/views/pastures/add.vue"),
      },
      {
        path: "pastures/manage",
        component: () => import("@/views/pastures/manage.vue"),
      },
      {
        path: "pastures/edit/:id",
        name: "editPasture",
        component: () => import("@/views/pastures/add.vue"),
      },
      {
        path: "herds",
        component: () => import("@/views/herds/herds.vue"),
      },
      {
        path: "herds/add",
        component: () => import("@/views/herds/add.vue"),
      },
      {
        path: "herds/manage",
        component: () => import("@/views/herds/manage.vue"),
      },
      {
        path: "herds/edit/:id",
        name: "editHerd",
        component: () => import("@/views/herds/add.vue"),
      },
      {
        path: "inventory",
        component: () => import("@/views/inventory/inventory.vue"),
      },
      {
        path: "inventory/add",
        component: () => import("@/views/inventory/add.vue"),
      },
      {
        path: "inventory/manage",
        component: () => import("@/views/inventory/manage.vue"),
      },
      {
        path: "inventory/edit/:id",
        name: "editInventory",
        component: () => import("@/views/inventory/edit.vue"),
      },
      {
        path: "reports",
        component: () => import("@/views/reports/reports.vue"),
      },
      {
        path: "sessions",
        component: () => import("@/views/sessions/sessions.vue"),
      },
    ],
  },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
  linkActiveClass: "active-link",
});

export default router;
