<template>
  <div class="title">
    Event History
    <button type="button" class="btn btn-gray btn-sm btn-right-title" data-el="addEventHistory" @click="addEventHistory(animal_events)">
      &#43;
      <span class="hidden-xs hidden-sm hidden-md">Add Row</span>
    </button>
  </div>
  <div class="animal-edit-table">
    <table class="table dt-responsive table-center" style="width: 100% !important">
      <thead class="thead-normal">
        <tr class="">
          <th style="width: 10%" class="text-center">Date</th>
          <th style="width: 15%" class="text-center">Event</th>
          <th style="width: 25%" class="text-center">Value</th>
          <th style="width: 10%" v-if="!disableEditParent" class="text-center">Delete</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="animalHasEvents || animalHasAppliedCosts">
          <template v-for="(event, index) of animal_events" :key="index">
            <!-- animal.events -->
            <tr class="text-center" v-if="event.type === 'departure'">
              <td>
                <input
                  v-if="event.reason != 'sold'"
                  type="date"
                  :min="this.animal.birth_date"
                  :max="this.userStore.user.work_date"
                  v-model="animal.departure_dtz"
                  class="form-control"
                  :disabled="disableEditParent"
                  required />
                <input v-else disabled type="date" :max="this.userStore.user.work_date" v-model="animal.departure_dtz" class="form-control" />
              </td>
              <td>{{ event.label }}</td>
              <td v-if="event.reason != 'sold'">
                <Dropdown
                  v-model="event.value"
                  class="col-xs-24"
                  v-if="isCalf"
                  @change="updateEvents(event)"
                  :disabled="disableEditParent"
                  :options="animalStore.options.pre_death_reasons"
                  optionLabel="subreason"
                  optionValue="subreason"
                  placeholder="Select departure reason" />
                <Dropdown
                  v-model="event.value"
                  class="col-xs-24"
                  v-if="!isCalf"
                  @change="updateEvents(event)"
                  :disabled="disableEditParent"
                  :options="animalStore.options.post_death_reasons"
                  optionLabel="subreason"
                  optionValue="subreason"
                  placeholder="Select departure reason" />
              </td>
              <td v-if="event.reason === 'sold'">
                <input class="form-control" v-model="event.value" disabled />
              </td>
              <td v-if="event.reason === 'died' && !disableEditParent">
                <img
                  src="/home/buttons/red_x.svg"
                  alt="X"
                  style="cursor: pointer"
                  @click="setEventToBeDeleted(event)"
                  href="#removeEventModal"
                  data-
                  target="#removeEventModal" />
              </td>
              <td v-else-if="!disableEditParent">---</td>
            </tr>

            <!-- animal.applied_costs -->
            <tr class="text-center" v-else @change="updateEvents(event)">
              <td>
                <input
                  type="date"
                  class="form-control"
                  v-model="event.application_dtz"
                  :min="this.animal.birth_date"
                  :max="this.userStore.user.work_date"
                  required />
              </td>
              <td>
                <Dropdown
                  v-model="event.type"
                  class="col-xs-24"
                  :disabled="disableEditParent"
                  :options="lodash.values(event_history)"
                  optionLabel="label"
                  optionValue="key"
                  placeholder="Select event type"
                  @change="updateEvents(event)" />
              </td>
              <td>
                <event-inputs :prop_event="event" :key="event._id" @updateEventsHandler="updateEvents(event)"></event-inputs>
              </td>
              <td v-if="!disableEditParent">
                <img
                  src="/home/buttons/red_x.svg"
                  alt="X"
                  style="cursor: pointer"
                  @click="setEventToBeDeleted(event)"
                  href="#removeEventModal"
                  data-
                  target="#removeEventModal" />
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">No data available</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import _ from "lodash";
import Dropdown from "primevue/dropdown";

import Animal from "@compositions/animal.vue";
import { useUserStore } from "@stores/user";
import { useAnimalStore } from "@/stores/animal";
import { usePastureStore } from "@stores/pasture";

import EventInputs from "./event-inputs";

export default defineComponent({
  props: {
    prop_animal: { type: Object, required: true },
    animal_events: { type: Object, required: true },
    disableEditParent: { type: Boolean },
  },
  components: {
    EventInputs,
    Dropdown,
  },
  emits: ["setEventToBeDeletedHandler", "updateEventsHandler"],
  data() {
    return {
      animal: this.prop_animal,
      event_history: {
        ai: {
          label: "AI",
          key: "ai",
        },
        body_condition: {
          label: "Body Condition",
          key: "body_condition",
        },
        bull_breeding_exam: {
          label: "Breeding Soundness Exam",
          key: "bull_breeding_exam",
        },
        castration: {
          label: "Castration",
          key: "castration",
        },
        cull: {
          label: "Cull",
          key: "cull",
        },
        dehorn: {
          label: "Dehorn",
          key: "dehorn",
        },
        in_estrus: {
          label: "In Estrus",
          key: "in_estrus",
        },
        morphology: {
          label: "Morphology",
          key: "morphology",
        },
        motility: {
          label: "Motility",
          key: "motility",
        },
        pelvic_area: {
          label: "Pelvic Area",
          key: "pelvic_area",
        },
        physical: {
          label: "Physical",
          key: "physical",
        },
        preg_check: {
          label: "Pregnancy Check",
          key: "preg_check",
        },
        reproductive_tract_score: {
          label: "Reproductive Tract Score",
          key: "reproductive_tract_score",
        },
        scrotal_circumference: {
          label: "Scrotal Circumference",
          key: "scrotal_circumference",
        },
      },
      applied_costs_clone: null,
    };
  },
  setup(props) {
    const { addEventHistory } = Animal();

    const userStore = useUserStore();
    const animalStore = useAnimalStore();
    const pastureStore = usePastureStore();

    const lodash = _;
    return {
      addEventHistory,

      userStore,
      animalStore,
      pastureStore,
      lodash,
    };
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  created() {},
  computed: {
    animalHasEvents() {
      return this.animal.events;
    },
    animalHasAppliedCosts() {
      return this.animal.applied_costs;
    },
    returnAnimalDepartureDate() {
      return this.animal.departure_dtz;
    },
    returnAnimalDepartureSubReason() {
      return this.animal.departure_subreason;
    },
    isCalf() {
      return (this.animalStore.options.calf_genders.includes(this.animal.gender) || this.animal.gender === null) && this.animal.role === "calf";
    },
  },
  methods: {
    setEventToBeDeleted(event) {
      event.label = this.event_history[event.type]?.label;
      this.$emit("setEventToBeDeletedHandler", event);
    },
    updateEvents(event) {
      this.$emit("updateEventsHandler", event);
    },
  },
  watch: {},
});
</script>

<style>
.form-group {
  margin-bottom: 0;
}
</style>
