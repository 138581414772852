<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: darkred; padding: 5px">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <p style="color: white; font-size: 25px; margin: 4px; margin-left: 10px">Saving Error</p>
      </div>
      <div class="modal-body text-center">
        <template v-for="(message, index) in this.error_messages" :key="index">
          <h3>- {{ message }}</h3>
        </template>
        <div style="border-bottom: 1px solid #e5e5e5"></div>
        <div>
          <div class="row padding-x">
            <button
              type="button"
              id="modal-select-button"
              class="btn btn-success padding-y-sm pull-right"
              data-dismiss="modal"
              style="border-radius: 30px; margin: 10px; width: 70px">
              <small>OK</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    animal: { type: Object },
    error_messages: { type: Object },
  },
  data() {
    return {
      animalCopy: this.animal,
    };
  },
};
</script>
