<template>
  <div class="input-group input-group-lg">
    <span class="input-group-addon half">Role</span>
    <select v-model="animalCopy.role" class="form-control" id="roleSelector" data-el="roleSelector" :disabled="disableEditParent">
      <option selected v-for="(role, index) in animalStore.options.roles" :key="index" :value="role">
        {{ animalStore.options.role_labels[role] }}
      </option>
      <option :value="null">---</option>
    </select>
  </div>
  <br />
  <hr class="roleDivider" />
  <div class="row">
    <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.birth_date.$error }">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.birth_date.$error }">*Birthdate</span>
      <input
        type="date"
        :max="this.userStore.user.work_date"
        class="form-control"
        data-el="birth_date"
        v-model="animalCopy.birth_date"
        :disabled="disableEditParent" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.birth_date.$errors">
      <span class="error-message" v-for="error in v$.animalCopy.birth_date.$errors" :key="error.$uid">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ error.$message }}
        <br />
      </span>
    </div>
    <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.visual_tag.$error }" v-if="!editPage">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.visual_tag.$error }">*Visual ID</span>
      <input
        type="text"
        class="form-control"
        data-el="primary_visual_id"
        v-model="animalCopy.tags.find((x) => x._id === animalCopy.primary_visual_id).visual_label" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.visual_tag.$errors && !editPage">
      <span class="error-message" v-for="error in v$.animalCopy.visual_tag.$errors" :key="error.$uid">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ error.$message }}
        <br />
      </span>
    </div>
    <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.digital_tag.$error }" v-if="!editPage && !AIRoleChecker">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.digital_tag.$error }">EID</span>
      <input type="text" class="form-control" data-el="primary_tag_id" v-model="animalCopy.tags.find((x) => x._id === animalCopy.primary_tag_id).digital_label" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.digital_tag.$errors && !editPage && !AIRoleChecker">
      <span class="error-message" v-for="error in v$.animalCopy.digital_tag.$errors" :key="error.$uid">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ error.$message }}
        <br />
      </span>
    </div>
    <div class="input-group input-group-lg" v-if="isSireRole">
      <span class="input-group-addon half">Registration Number</span>
      <input type="text" class="form-control" data-el="breed_registrations" v-model="animalCopy.breed_registrations" :disabled="disableEditParent" />
    </div>
    <div class="input-group input-group-lg" v-if="editPage || !AIRoleChecker">
      <span class="input-group-addon half">Brand/Tattoo</span>
      <input type="text" class="form-control" data-el="brand" v-model="animalCopy.brand" :disabled="disableEditParent" />
    </div>
    <div class="input-group input-group-lg" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half">Vigor</span>
      <select v-model="animalCopy.birth_vigor" data-el="birth_vigor" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(vigor, index) in animalStore.options.vigors" :key="index" :value="vigor">
          {{ animalStore.options.vigor_labels[vigor] }}
        </option>
      </select>
    </div>
    <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.role_gender.$error }">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.role_gender.$error }">Gender</span>
      <select v-if="editPage || noRole" data-el="gender" v-model="animalCopy.gender" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(gender, index) in animalStore.options.genders" :key="index" :value="gender">
          {{ animalStore.options.gender_labels[gender] }}
        </option>
      </select>
      <select v-else-if="!editPage && cullRoleChecker" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option :value="null">---</option>
        <option v-for="(gender, index) in animalStore.options.genders" :key="index" :value="gender">
          {{ animalStore.options.gender_labels[gender] }}
        </option>
      </select>
      <select v-else-if="!editPage && calfRoleChecker" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option :value="null">---</option>
        <option v-for="(gender, index) in animalStore.options.calf_genders" :key="index" :value="gender">
          {{ animalStore.options.gender_labels[gender] }}
        </option>
      </select>
      <select v-else-if="!editPage && weanedRoleChecker" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option v-for="(gender, index) in animalStore.options.weaned_genders" :key="index" :value="gender">
          {{ animalStore.options.gender_labels[gender] }}
        </option>
      </select>
      <select v-else-if="!editPage && damRoleChecker" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option value="cow">Cow</option>
      </select>
      <select v-else-if="!editPage && replacementRoleChecker" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option value="heifer">Heifer</option>
      </select>
      <select v-else-if="!editPage && (naturalSireAndNaturalAIRoleChecker || AIRoleChecker)" data-el="gender" v-model="animalCopy.gender" class="form-control">
        <option value="bull">Bull</option>
      </select>
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.role_gender.$errors">
      <span class="error-message" v-for="error in v$.animalCopy.role_gender.$errors" :key="error.$uid">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ error.$message }}
        <br />
      </span>
    </div>
    <div class="input-group input-group-lg">
      <span class="input-group-addon half">Origin</span>
      <select v-model="animalCopy.origin" class="form-control" data-el="origin" :disabled="disableEditParent" :value="animalCopy.origin">
        <option :value="Raised">Raised</option>
        <option :value="Purchased">Purchased</option>
      </select>
    </div>
    <template v-if="animalCopy.origin === `Purchased`">
      <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.purchase_date.$error }">
        <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.purchase_date.$error }">Purchase Date</span>
        <input
          type="date"
          class="form-control"
          data-el="purchase_date"
          :min="minBirthDate"
          max="9999-12-31"
          v-model="animalCopy.purchase_date"
          :disabled="disableEditParent" />
      </div>
      <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.purchase_date.$errors">
        <span class="error-message" v-for="error in v$.animalCopy.purchase_date.$errors" :key="error.$uid">
          <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
          {{ error.$message }}
          <br />
        </span>
      </div>
    </template>
    <div class="input-group input-group-lg" v-if="editPage || !AIRoleChecker">
      <span class="input-group-addon half">Herd</span>
      <select v-model="animalCopy.herd_id" data-el="herd_id" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(herd, index) in herdStore.herds" :key="index" :value="herd._id">{{ herd.name }}</option>
      </select>
    </div>
    <div class="input-group input-group-lg" v-if="editPage || !AIRoleChecker">
      <span class="input-group-addon half">Pasture</span>
      <template v-if="!animalCopy.pasture_id">
        <select v-model="animalCopy.pasture_id" data-el="pasture_id" class="form-control" :disabled="disableEditParent">
          <option :value="null">---</option>
          <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">
            {{ pasture.name }}
          </option>
        </select>
      </template>
      <template v-else>
        <select v-model="animalCopy.pasture_id" data-el="pasture_id" class="form-control" :disabled="disableEditParent">
          <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">{{ pasture.name }}</option>
        </select>
      </template>
    </div>
    <div class="row margin-sm" v-if="editPage || !AIRoleChecker">
      <div class="col-xs-10 text-center">
        <span style="font-size: 20px">Sire ID</span>
      </div>
      <div class="col-xs-10">
        <template v-for="(sire, index) in getSires" :key="index">
          <span>{{ sire }}</span>
          <br />
        </template>
      </div>
      <div class="col-xs-4">
        <button
          id="setSire"
          class="btn btn-outline btn-sm btn-round padding-y-md"
          data-toggle="modal"
          data-target="#changeSireModal"
          :disabled="disableEditParent"
          type="button">
          <small>Edit</small>
        </button>
      </div>
    </div>
    <div class="row margin-sm" v-if="editPage">
      <div class="col-xs-10 text-center">
        <span style="font-size: 20px">Dam ID</span>
      </div>
      <div class="col-xs-10">
        <span>{{ getDamID }}</span>
      </div>
      <div class="col-xs-4">
        <button
          id="setDam"
          class="btn btn-outline btn-sm btn-round padding-y-md"
          data-toggle="modal"
          data-target="#changeDamModal"
          :disabled="disableEditParent"
          type="button">
          <small>Edit</small>
        </button>
      </div>
    </div>
    <div class="row margin-sm" v-if="!AIRoleChecker && !editPage">
      <div class="col-xs-10 text-center">
        <span style="font-size: 20px">Dam ID</span>
      </div>
      <div class="col-xs-10">
        <span>{{ getDamID }}</span>
      </div>
      <div class="col-xs-4">
        <button
          id="setDam"
          class="btn btn-outline btn-sm btn-round padding-y-md"
          data-toggle="modal"
          data-target="#changeDamModal"
          :disabled="disableEditParent"
          type="button">
          <small>Edit</small>
        </button>
      </div>
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.dam_id.$error">
      <span class="error-message">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ v$.animalCopy.dam_id.requiredIfNoDamButBodyCondition.$message }}
        <br />
      </span>
    </div>
    <div class="input-group input-group-lg" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half">Dam BCS at Birth</span>
      <select v-model="animalCopy.dam_body_condition" data-el="dam_body_condition" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(body_condition, index) in animalStore.options.body_conditions" :key="index" :value="body_condition">
          {{ animalStore.options.body_condition_labels[body_condition] }}
        </option>
      </select>
    </div>
    <div class="row margin-sm" v-if="editPage">
      <div class="col-xs-10 text center">
        <span style="font-size: 20px">Grafted To</span>
      </div>
      <div class="col-xs-10">
        <span>{{ getGraftedDamID }}</span>
      </div>
      <div class="col-xs-4">
        <button
          id="setGraftedDam"
          class="btn btn-outline btn-sm btn-round padding-y-md"
          data-toggle="modal"
          data-target="#changeGraftedDamModal"
          :disabled="disableEditParent"
          type="button">
          <small>Edit</small>
        </button>
      </div>
    </div>
    <div class="row margin-sm">
      <div class="col-xs-10 text-center">
        <span style="font-size: 20px">Breed</span>
      </div>
      <div class="col-xs-10">
        <template v-for="(breed, index) in getBreedsLabel(this.animalCopy)" :key="index">
          <span>
            <b>{{ breed }}</b>
          </span>
        </template>
      </div>
      <div class="col-xs-4">
        <button
          id="setBreed"
          class="btn btn-outline btn-sm btn-round padding-y-md"
          data-toggle="modal"
          data-target="#changeBreedsModal"
          :disabled="disableEditParent"
          type="button">
          <small>Edit</small>
        </button>
      </div>
      <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.breeds.$error">
        <span class="error-message">
          <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
          {{ v$.animalCopy.breeds.requiredIfBreedError.$message }}
          <br />
        </span>
      </div>
    </div>
    <div class="input-group input-group-lg">
      <span class="input-group-addon half">Color</span>
      <select v-model="animalCopy.coat_color" data-el="coat_color" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(coat_color, index) in animalStore.options.coat_colors" :key="index" :value="coat_color">
          {{ animalStore.options.coat_color_labels[coat_color].label }}
        </option>
      </select>
    </div>
    <div class="input-group input-group-lg">
      <span class="input-group-addon half">Horned Status</span>
      <select v-model="animalCopy.horned_status" data-el="horned_status" class="form-control" :disabled="disableEditParent || hasDehornEvent">
        <option :value="null">---</option>
        <option v-for="(horn_status, index) in animalStore.options.horn_statuses" :key="index" :value="horn_status">
          {{ animalStore.options.horn_status_labels[horn_status] }}
        </option>
      </select>
    </div>
    <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.birth_weight.$error }" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.birth_weight.$error }">Birth Weight</span>
      <input type="number" class="form-control" data-el="birth_weight" v-model="animalCopy.birth_weight" :disabled="disableEditParent" min="0" step="any" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.birth_weight.$error">
      <span class="error-message">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ v$.animalCopy.birth_weight.minValue.$message }}
      </span>
    </div>
    <div class="input-group input-group-lg" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half">Birth Pasture</span>
      <select v-model="animalCopy.birth_pasture_id" data-el="birth_pasture_id" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(pasture, index) in pastureStore.pastures" :key="index" :value="pasture._id">
          {{ pasture.name }}
        </option>
      </select>
    </div>
    <div class="input-group input-group-lg" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half">Management Code</span>
      <select v-model="animalCopy.management_code" data-el="management_code" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(management_code, index) in animalStore.options.management_codes" :key="index" :value="management_code">
          {{ animalStore.options.management_code_labels[management_code] }}
        </option>
      </select>
    </div>
    <div class="input-group input-group-lg" v-if="editPage || calfRoleChecker">
      <span class="input-group-addon half">Calving Ease</span>
      <select v-model="animalCopy.birth_assistance" data-el="birth_assistance" class="form-control" :disabled="disableEditParent">
        <option :value="null">---</option>
        <option v-for="(birth_assistance, index) in animalStore.options.birth_assistances" :key="index" :value="birth_assistance">
          {{ animalStore.options.birth_assistance_labels[birth_assistance] }}
        </option>
      </select>
    </div>
    <div
      class="input-group input-group-lg"
      :class="{ 'input-group-lg-validation-error': v$.animalCopy.wean_date.$error }"
      v-if="editPage || weanedRoleChecker || cullRoleChecker">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.wean_date.$error }">Weaned Date</span>
      <input
        type="date"
        :min="minBirthDate"
        data-el="wean_date"
        :max="this.userStore.user.work_date"
        class="form-control"
        v-model="animalCopy.wean_date"
        :disabled="disableEditParent" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.wean_date.$errors">
      <span class="error-message" v-for="error in v$.animalCopy.wean_date.$errors" :key="error.$uid">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ error.$message }}
        <br />
      </span>
    </div>
    <div
      class="input-group input-group-lg"
      :class="{ 'input-group-lg-validation-error': v$.animalCopy.wean_pounds.$error }"
      v-if="editPage || weanedRoleChecker || cullRoleChecker">
      <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.wean_pounds.$error }">Weaned Weight</span>
      <input type="number" class="form-control" data-el="wean_pounds" v-model="animalCopy.wean_pounds" :disabled="disableEditParent" />
    </div>
    <div class="validation-alert inline-error-animal-form" v-if="v$.animalCopy.wean_pounds.$error">
      <span class="error-message">
        <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
        {{ v$.animalCopy.wean_pounds.minValue.$message }}
      </span>
    </div>
    <div class="input-group input-group-lg">
      <span class="input-group-addon half">Notes</span>
      <textarea type="text" class="form-control" data-el="note" v-model="animalCopy.note" rows="2" id="animal-notes" :disabled="disableEditParent"></textarea>
    </div>
  </div>
</template>

<script>
import Animal from "@compositions/animal.vue";
import { useUserStore } from "@stores/user";
import { DateTime } from "luxon";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf, minValue } from "@vuelidate/validators/dist/raw.esm";

export default {
  emits: ["emit_error_messages", "selected-dam"],
  props: {
    animal: { type: Object, required: true },
    active_dam: { type: Object, required: false },
    active_graft_dam: { type: Object, required: false },
    editPage: { type: Boolean },
    disableEditParent: { type: Boolean },
    duplicate_digital_tag: { type: Boolean },
    duplicate_visual_tag: { type: Boolean },
    scope: { type: Boolean },
  },
  data() {
    return {
      animalCopy: this.animal,
      error_messages: [],
      Raised: "Raised",
      Purchased: "Purchased",
    };
  },
  setup(props) {
    const animalStore = useAnimalStore();

    const herdStore = useHerdStore();
    const userStore = useUserStore();
    const pastureStore = usePastureStore();
    const v$ = useVuelidate({ $lazy: true, $scope: props.scope });

    const { getAnimalPrimaryDigitalLabel, getAnimalPrimaryVisualLabel, getBreedsLabel, validateBreedFraction, visualTagHasInvalidCharacters } = Animal();
    return {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      getBreedsLabel,
      validateBreedFraction,
      visualTagHasInvalidCharacters,

      animalStore,
      herdStore,
      pastureStore,
      userStore,
      v$,
    };
  },
  created() {
    this.animalStore.getArtificialInseminations();
    if (!this.animal.purchase_date) {
      this.animalCopy.origin = "Raised";
    } else {
      this.animalCopy.origin = "Purchased";
    }
  },
  computed: {
    calfRoleChecker() {
      return this.animalCopy.role === "calf";
    },
    cullRoleChecker() {
      return this.animalCopy.role === "cull";
    },
    weanedRoleChecker() {
      return this.animalCopy.role === "weaned";
    },
    damRoleChecker() {
      if (this.animalCopy.role === "dam" && !this.editPage) {
        this.setGenderToCow();
        return true;
      }
      return false;
    },
    replacementRoleChecker() {
      if (this.animalCopy.role === "replace" && !this.editPage) {
        this.setGenderToHeifer();
        return true;
      }
      return false;
    },
    naturalSireAndNaturalAIRoleChecker() {
      if ((this.animalCopy.role === "natural_sire" || this.animalCopy.role === "natural_ai") && !this.editPage) {
        this.setGenderToBull();
        return true;
      }
      return false;
    },
    AIRoleChecker() {
      if (this.animalCopy.role === "ai_sire" && !this.editPage) {
        this.setGenderToBull();
        return true;
      }
      return false;
    },
    isSireRole() {
      return this.animalStore.options.male_roles.includes(this.animalCopy.role);
    },
    noRole() {
      if (!this.animalCopy.role && !this.editPage) {
        this.setGenderToEmpty();
        return true;
      }
      return false;
    },
    returnAnimalCopy() {
      return this.animalCopy;
    },
    minBirthDate() {
      return this.animalCopy.birth_date;
    },
    getDamID() {
      if (this.editPage) {
        if (this.animalCopy.dam) {
          return this.getAnimalPrimaryVisualLabel(this.animalCopy.dam);
        } else if (this.animalCopy.dam_id && this.active_dam) {
          return this.getAnimalPrimaryVisualLabel(this.active_dam);
        } else {
          return "---";
        }
      }
      if (!this.animalCopy.dam_id) {
        return "---";
      }

      const dam = this.animalStore.replacement_and_dams.find((x) => x._id === this.animalCopy.dam_id);
      // emits the dam to the parent component (overview) to be re-used by the dam modal in the overview component.

      if (dam) {
        this.$emit("selected-dam", dam);
      }
      return this.getAnimalPrimaryVisualLabel(dam);
    },
    getGraftedDamID() {
      if (this.animalCopy.grafted_to_id && this.active_graft_dam) {
        return this.getAnimalPrimaryVisualLabel(this.active_graft_dam);
      } else {
        return "---";
      }
    },
    getSires() {
      let sires = [];
      let assignedNaturalSire = [];

      if (this.animalCopy.sire_ids) {
        assignedNaturalSire = this.animalStore.sires.filter((x) => this.animalCopy.sire_ids.includes(x._id));
      }

      if (assignedNaturalSire.length) {
        for (let sire of assignedNaturalSire) {
          sires.push(this.getAnimalPrimaryVisualLabel(sire));
        }
      }

      if (!sires.length) {
        sires.push("---");
      }

      return sires;
    },
    hasDehornEvent() {
      return this.animalCopy.applied_costs?.find((e) => e.type === "dehorn");
    },
  },
  methods: {
    setGenderToBull() {
      this.animalCopy.gender = "bull";
    },
    setGenderToCow() {
      this.animalCopy.gender = "cow";
    },
    setGenderToEmpty() {
      this.animalCopy.gender = null;
    },
    setGenderToHeifer() {
      this.animalCopy.gender = "heifer";
    },
  },
  validations() {
    return {
      animalCopy: {
        //Date Checks
        birth_date: {
          required: helpers.withMessage(this.animalStore.animal_form_error_messages.required_birthdate, required),
          requiredIfBirthDateIsGreaterThanWorkDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than + this.userStore.user.work_date + ".",
            requiredIf(() => {
              if (this.animalCopy.birth_date && this.animalCopy.birth_date > this.userStore.user.work_date) {
                this.v$.animalCopy.birth_date.touch();
                return true;
              }
            })
          ),
          requiredIfBirthDateIsGreaterThanDepartureDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than_departure_date,
            requiredIf(() => {
              if (this.animalCopy.birth_date && this.animalCopy.birth_date > this.animalCopy.departure_dtz) {
                this.v$.animalCopy.birth_date.touch();
                return true;
              }
            })
          ),
          requiredIfBirthDateIsGreaterThanDamsBirthdate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than_dams_birthdate,
            requiredIf(() => {
              if (this.animalCopy.birth_date && this.animalCopy.dam_id) {
                let dam = this.animalStore.replacement_and_dams.find((x) => x._id === this.animalCopy.dam_id);
                if (dam && this.animalCopy.birth_date < dam.birth_date) {
                  this.v$.animalCopy.birth_date.touch();
                  return true;
                }
              }
            })
          ),
          requiredIfBirthDateIsGreaterThanSiresBirthdate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than_sire_birthdate,
            requiredIf(() => {
              if (this.animalCopy.birth_date && this.animalCopy.sire_ids.length) {
                //gets the max date based on the sire's birthdate to compare against the calf.
                let max_sire_birth_date = DateTime.fromISO(this.animalCopy.birth_date);
                let luxon_calf_birth_date = DateTime.fromISO(this.animalCopy.birth_date);
                let sire_birthdates = this.animalStore.sires.filter((x) => this.animalCopy.sire_ids.includes(x._id)).map((x) => x.birth_date);
                for (let birthdate of sire_birthdates) {
                  birthdate = DateTime.fromISO(birthdate);
                  max_sire_birth_date = DateTime.max(max_sire_birth_date, birthdate);
                }
                if (luxon_calf_birth_date < max_sire_birth_date) {
                  this.v$.animalCopy.birth_date.touch();
                  return true;
                }
              }
            })
          ),
        },
        purchase_date: {
          requiredIfBirthDateIsGreaterThanPurchaseDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than_purchase_date,
            requiredIf(() => {
              if (this.animalCopy.birth_date && this.animalCopy.purchase_date && this.animalCopy.birth_date > this.animalCopy.purchase_date) {
                this.v$.animalCopy.purchase_date.touch();
                return true;
              }
            })
          ),
          requiredIfPurchaseDateIsGreaterThanDepartureDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_purchase_date_is_greater_than_departure_date,
            requiredIf(() => {
              if (this.animalCopy.purchase_date && this.animalCopy.purchase_date > this.animalCopy.departure_dtz) {
                this.v$.animalCopy.wean_date.touch();
                return true;
              }
            })
          ),
          requiredIfAnimalOriginIsPurchasedButPurchaseDateIsEmpty: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_animal_origin_is_purchased_but_purchase_date_is_empty,
            requiredIf(() => {
              if (this.animalCopy.origin === "Purchased" && !this.animalCopy.purchase_date) {
                this.v$.animalCopy.purchase_date.touch();
                return true;
              }
            })
          ),
        },
        wean_date: {
          requiredIfBirthDateIsGreaterThanWeanDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_birthdate_is_greater_than_wean_date,
            requiredIf(() => {
              if (this.animalCopy.wean_date && this.animalCopy.birth_date > this.animalCopy.wean_date) {
                this.v$.animalCopy.wean_date.touch();
                return true;
              }
            })
          ),
          requiredIfWeanDateIsLesserThanBirthDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_wean_date_is_lesser_than_birth_date,
            requiredIf(() => {
              if (this.animalCopy.wean_date && this.animalCopy.wean_date <= this.animalCopy.birth_date) {
                this.v$.animalCopy.wean_date.touch();
                return true;
              }
            })
          ),
          requiredIfWeanDateIsGreaterThanDepartureDate: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_wean_date_is_greater_than_departure_date,
            requiredIf(() => {
              if (this.animalCopy.wean_date && this.animalCopy.wean_date > this.animalCopy.departure_dtz) {
                this.v$.animalCopy.wean_date.touch();
                return true;
              }
            })
          ),
          requiredIfWeanDateIsMissingAndWeanPoundsIsPresent: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_wean_date_is_missing_and_wean_pounds_is_present,
            requiredIf(() => {
              if (this.animalCopy.wean_pounds && !this.animalCopy.wean_date) {
                this.v$.animalCopy.wean_date.touch();
                return true;
              }
            })
          ),
        },
        //Negative weight / breed fraction
        wean_pounds: { minValue: helpers.withMessage(this.animalStore.animal_form_error_messages.wean_date_negative_value, minValue(0)) },
        birth_weight: { minValue: helpers.withMessage(this.animalStore.animal_form_error_messages.birth_weight_negative_value, minValue(0)) },
        breeds: {
          requiredIfBreedError: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_breed_error,
            requiredIf(() => {
              if (!this.validateBreedFraction(this.animalCopy.breeds)) {
                this.v$.animalCopy.breeds.touch();
                return true;
              }
            })
          ),
        },
        //Dam ID
        dam_id: {
          requiredIfNoDamButBodyCondition: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_no_dam_but_body_condition,
            requiredIf(() => {
              if (this.animalCopy.dam_body_condition && !this.animalCopy.dam_id) {
                this.v$.animalCopy.dam_id.touch();
                return true;
              }
            })
          ),
        },
        //Role-Gender
        role_gender: {
          requiredIfRoleIsReplaceButGenderIsNotHeifer: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_role_is_replace_but_gender_is_not_heifer,
            requiredIf(() => {
              if (this.animalCopy.role === "replace" && this.animalCopy.gender !== "heifer") {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfRoleIsNotCalfOrCullOrNullButGenderIsNull: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_role_is_not_calf_or_cull_or_null_but_gender_is_null,
            requiredIf(() => {
              if (this.animalCopy.role !== "calf" && this.animalCopy.role !== "cull" && this.animalCopy.role !== null && !this.animalCopy.gender) {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfRoleIsSireButGenderIsNotBull: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_role_is_sire_but_gender_is_not_bull,
            requiredIf(() => {
              if (this.animalStore.options.male_roles.includes(this.animal.role) && this.animal.gender !== "bull") {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfRoleIsDamButGenderIsNotCow: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_role_is_dam_but_gender_is_not_cow,
            requiredIf(() => {
              if (this.animal.role === "dam" && this.animal.gender !== "cow") {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfGenderIsCowButRoleIsNotDamOrCull: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_gender_is_cow_but_role_is_not_dam_or_cull,
            requiredIf(() => {
              if (this.animal.gender === "cow" && this.animal.role !== "dam" && this.animal.role !== "cull") {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfCalfRoleWithNoGenderIsBeingWeaned: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_calf_role_with_no_gender_is_being_weaned,
            requiredIf(() => {
              if (this.animal.role === "calf" && this.animal.wean_date && !this.animal.gender) {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
          requiredIfRoleIsNullButGenderIsNot: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_role_is_null_but_gender_is_not,
            requiredIf(() => {
              if (!this.animal.role && this.animal.gender) {
                this.v$.animalCopy.role_gender.touch();
                return true;
              }
            })
          ),
        },
        //Visual tag / birthdate conflict
        visual_tag: {
          requiredIfNoVisualTag: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_no_visual_tag,
            requiredIf(() => {
              if (this.animalCopy.primary_visual_id && !this.animalCopy.tags.find((x) => x._id === this.animalCopy.primary_visual_id).visual_label) {
                this.v$.animalCopy.visual_tag.touch();
                return true;
              }
            })
          ),
          requiredIfVisualTagAndBirthdateAlreadyExist: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_visual_tag_and_birthdate_already_exist,
            requiredIf(() => {
              if (this.duplicate_visual_tag) {
                this.v$.animalCopy.visual_tag.touch();
                return this.duplicate_visual_tag;
              }
            })
          ),
          requiredIfVisualTagFailsRegexTest: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_visual_tag_fails_regex_test,
            requiredIf(() => {
              if (
                this.animalCopy.primary_visual_id &&
                this.visualTagHasInvalidCharacters(this.animalCopy.tags.find((x) => x._id === this.animalCopy.primary_visual_id).visual_label)
              ) {
                this.v$.animalCopy.visual_tag.touch();
                return true;
              }
            })
          ),
        },
        //EID
        digital_tag: {
          requiredIfEIDIsInTheWrongFormat: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_EID_is_in_the_wrong_format,
            requiredIf(() => {
              if (this.animalCopy.primary_tag_id && this.animalCopy.tags.find((x) => x._id === this.animalCopy.primary_tag_id).digital_label) {
                let regExp = new RegExp("^[1-9]{1}\\d{2}-\\d{3}-\\d{3}-\\d{3}-\\d{3}$|^[1-9]\\d{14}$", "m");
                let isEIDValid = regExp.test(this.animalCopy.tags.find((x) => x._id === this.animalCopy.primary_tag_id).digital_label);
                if (!isEIDValid) {
                  this.v$.animalCopy.digital_tag.touch();
                  return true;
                }
              }
            })
          ),
          requiredIfDuplicateDigitalTagExists: helpers.withMessage(
            this.animalStore.animal_form_error_messages.required_if_duplicate_digital_tag_exists,
            requiredIf(() => {
              if (this.duplicate_digital_tag) {
                this.v$.animalCopy.digital_tag.touch();
                return this.animalCopy.duplicate_digital_tag;
              }
            })
          ),
        },
      },
      //this is only used to emit the messages to the parent component(if on the edit page) to be displayed on the additional prompt alert.
      error_messages: {
        requiredIfThereAreErrorMessages: requiredIf(() => {
          if (this.editPage && this.v$.animalCopy.$errors.length) {
            this.error_messages = [];
            for (let error of this.v$.animalCopy.$errors) {
              if (!this.error_messages.includes(error.$message)) {
                this.error_messages.push(error.$message);
              }
            }
            this.$emit("emit_error_messages", this.error_messages);
          }
        }),
      },
    };
  },
};
</script>
