import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import axios from "axios";
import _ from "lodash";
import { Settings } from "luxon";

export const useUserStore = defineStore("user", {
  state: () => ({
    auth: useStorage("auth", {
      email: "",
      password: "",
      feedyard_id: null,
    }),
    user: useStorage("user", {}),
    settings: useStorage("settings", {
      filters: [],
    }),
    default_filters: [
      {
        key: "id",
        name: "EID, Visual ID",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "age",
        name: "Age",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "gender",
        name: "Gender",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "role",
        name: "Role",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "herd",
        name: "Herd",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "pasture",
        name: "Pasture",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "brand",
        name: "Brand/Tattoo",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "breed",
        name: "Breed",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "origin",
        name: "Origin",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "coat_color",
        name: "Color",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "horned_status",
        name: "Horned Status",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "birth_date",
        name: "Birthdate",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "birth_pasture",
        name: "Birth Pasture",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "birth_weight",
        name: "Birth Weight",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "sire_ids",
        name: "Sire IDs",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "dam_id",
        name: "Dam ID",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "dam_bcs",
        name: "Dam BCS",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "management_code",
        name: "Management Code",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "calving_ease",
        name: "Calving Ease",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "birth_vigor",
        name: "Birth Vigor",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "wean_weight",
        name: "Weaned Weight",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "wean_date",
        name: "Weaned Date",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "departure_dtz",
        name: "Departure Date",
        isRange: true,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
      {
        key: "departure_reason",
        name: "Departure Reason",
        isRange: false,
        isChecked: true,
        min: null,
        max: null,
        exclusions: [],
      },
    ],
  }),
  getters: {
    filters: (state) => state.settings.filters,
  },
  actions: {
    fetchAuthStatus() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/settings/")
          .then((response) => {
            const userData = response.data;
            if (userData && userData.can_pr) {
              this.setAuth({
                email: userData.login_username,
                feedyard_id: userData.feedyard_id,
              });

              this.setUser(userData);
              resolve(userData);
            } else {
              resolve({ error: "Access denied for Performance Ranch. Please try another account or Sign up with an existing Performance Beef account." });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status == 401) {
              // Do nothing. User is not authorized and wait for login
              resolve({ error: "Unauthorized" });
              return;
            }

            console.error(error);
          });
      });
    },
    fetchRanchSettings() {
      return new Promise((resolve, reject) => {
        axios.get("/pr.api/settings/").then((response) => {
          const ranch_settings = response.data;
          _.merge(this.user, ranch_settings);
          resolve(ranch_settings);
        });
      });
    },
    getFilters() {
      return new Promise((resolve, reject) => {
        axios.get(`/pr.api/settings/`).then((response) => {
          let filters = null;
          if (response.data && response.data.ranch && response.data.ranch.settings) {
            filters = response.data.ranch.settings.filters;
          }
          this.UPDATE_FILTERS(filters);
          resolve();
        });
      });
    },
    updateFilters(filters) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/pr.api/settings/update/`,
            { settings: { filters: filters } },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.UPDATE_FILTERS(filters);
            resolve();
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        axios.post(`/api/logout/`).then((response) => {
          this.setAuth({});
          this.setUser({});
          resolve();
        });
      });
    },
    setAuth(val) {
      this.auth = val;
    },
    setUser(user) {
      Settings.defaultZone = user.timezone;
      this.user = user;
    },
    UPDATE_FILTERS(filters) {
      if (!filters || filters.length === 0) {
        this.settings.filters = this.default_filters;
      } else {
        // Compare filters to this.default_filters and merge in any new filters found in this.default_filters.
        let newFilters = this.default_filters.filter((def) => !filters.find((fil) => fil.key.includes(def.key)));
        newFilters.forEach((item) => {
          let itemExistsInUsersFilters = filters.find((f) => f.key === item.key) != null;
          if (!itemExistsInUsersFilters) {
            // Merge new filter into existing filters in the correct location, according to location in default_filters.
            filters.splice(this.default_filters.indexOf(item), 0, item);
          }
        });

        // Check if any existing filters should be removed form the user's existing filters.
        let oldFilters = filters.filter((fil) => !this.default_filters.find((def) => def.key.includes(fil.key)));
        oldFilters.forEach((item) => {
          let itemExistsInUsersFilters = filters.find((f) => f.key === item.key) != null;
          if (itemExistsInUsersFilters) {
            // Remove the old filter from the user's existing filters.
            filters.splice(filters.indexOf(item), 1);
          }
        });

        // Finally, rip through the filters and update the filter's name, in case any changes were made to a filter's name, but key remained the same (i.e. - "Branch" changing to "Brand/Tattoo").
        filters.forEach((item) => {
          item.name = this.default_filters.find((f) => f.key == item.key).name;
        });

        this.settings.filters = filters;
      }
    },
  },
});
