<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h2 class="modal-title">Record a Departure</h2>
        <div>
          <ul class="nav nav-tabs" role="tablist" style="display: inline-block; padding-top: 10px">
            <li role="presentation" v-for="(tab, index) in tabs" :key="index" :class="{ active: tab.isActive }">
              <template v-if="tab.show">
                <a href="#" role="tab" data-toggle="tab" style="font-size: 18px; color: #cf1f25; padding-left: 50px; padding-right: 50px" @click="setActiveTab(tab)">
                  {{ tab.name }}
                </a>
              </template>
            </li>
          </ul>
          <div class="tab-content">
            <div id="sale" class="tab-pane" style="padding-right: 30px" :class="{ active: tabs[0].isActive }"></div>
            <div id="death" class="tab-pane" :class="{ active: tabs[1].isActive }"></div>
            <div id="transfer" class="tab-pane" :class="{ active: tabs[2].isActive }"></div>
          </div>
        </div>
        <div>
          <div class="tab-content">
            <template v-if="isDeathTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.$error }">*Departure Date</span>
                <input type="date" class="form-control" v-model="animalCopy.departure_dtz" id="departureDate" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.animalCopy.$errors">
                <span class="error-message" v-for="error in v$.animalCopy.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
            </template>
            <template v-else-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.animalCopy.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.animalCopy.$error }">*Departure Date</span>
                <input type="date" class="form-control" :min="minBirthDate" v-model="animalCopy.departure_dtz" id="departureDate" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.animalCopy.$errors">
                <span class="error-message" v-for="error in v$.animalCopy.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
            </template>
            <template v-else>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.transfer_date.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.transfer_date.$error }">*Departure Date</span>
                <input
                  type="date"
                  class="form-control"
                  :class="{ 'form-control-validation-error': v$.transfer_date.$error }"
                  :min="minBirthDate"
                  :max="this.userStore.user.work_date"
                  v-model="this.transfer_date"
                  id="departureDateTransfer" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.transfer_date.$errors">
                <span class="error-message" v-for="error in v$.transfer_date.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                </span>
              </div>
            </template>
            <template v-if="isDeathTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.death_departure.reason.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.death_departure.reason.$error }">*Reason</span>
                <select v-model="death_departure" v-if="isCalf" class="form-control" :class="{ 'form-control-validation-error': v$.death_departure.reason.$error }">
                  <option selected v-for="(reasons, index) in animalStore.options.pre_death_reasons" :key="index" :value="reasons">
                    {{ reasons.subreason }}
                  </option>
                </select>
                <select v-model="death_departure" class="form-control" v-else>
                  <option selected v-for="(reasons, index) in animalStore.options.post_death_reasons" :key="index" :value="reasons">
                    {{ reasons.subreason }}
                  </option>
                </select>
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.death_departure.reason.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.death_departure.reason.required.$message }}
                </span>
              </div>
            </template>
            <!--Sale Reasons-->
            <template v-else-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.sale_departure.reason.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.sale_departure.reason.$error }">*Reason</span>
                <select v-model="sale_departure" class="form-control" :class="{ 'form-control-validation-error': v$.sale_departure.reason.$error }">
                  <option selected v-for="(reasons, index) in animalStore.options.sale_reasons" :key="index" :value="reasons">
                    {{ reasons.subreason }}
                  </option>
                </select>
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.sale_departure.reason.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.sale_departure.reason.required.$message }}
                </span>
              </div>
            </template>
            <!--Head count for sales and deaths-->
            <div class="input-group input-group-lg" v-if="!isTransferTab">
              <span class="input-group-addon half">Head Count</span>
              <input type="text" disabled class="form-control" value="1" />
            </div>
            <!--Head count for transfer with inline error for digital tag-->
            <template v-else>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.duplicate_digital_tag.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.duplicate_digital_tag.$error }">Head Count</span>
                <input type="text" disabled class="form-control" value="1" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.duplicate_digital_tag.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.duplicate_digital_tag.requiredIf.$message }}
                </span>
              </div>
            </template>
            <!--Death Tab-->
            <div class="input-group input-group-lg" v-if="isDeathTab">
              <span class="input-group-addon half">Notes</span>
              <textarea class="form-control" v-model="animalCopy.departure_note" style="max-width: 100%; min-height: 100px" id="herdSalesNotes"></textarea>
            </div>
            <!--Sale Tab-->
            <template v-if="isSaleTab">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.destination.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.destination.$error }">*Destination</span>
                <textarea class="form-control" v-model="ranch_sales.destination" style="max-width: 100%" id="ranchSalesDestination"></textarea>
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.ranch_sales.destination.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.destination.required.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.weight.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.weight.$error }">*Total Weight</span>
                <input type="number" class="form-control" v-model="ranch_sales.weight" style="width: 80%; border-radius: 8px" />
                <p>lbs</p>
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.ranch_sales.weight.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.weight.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.sale_cents.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.sale_cents.$error }">*Total Sale Price</span>
                <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.ranch_sales.sale_cents.$error }">$</span>
                <input type="number" class="form-control" v-model="sale_cents" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.ranch_sales.sale_cents.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.sale_cents.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.ranch_sales.sale_expense_cents.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.ranch_sales.sale_expense_cents.$error }">
                  *Sales Expense
                </span>
                <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.ranch_sales.sale_expense_cents.$error }">$</span>
                <input type="number" class="form-control" v-model="sale_expense_cents" />
              </div>
              <div class="validation-alert inline-error-individual-departure" v-if="v$.ranch_sales.sale_expense_cents.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.ranch_sales.sale_expense_cents.requiredIf.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Notes</span>
                <textarea class="form-control" v-model="ranch_sales.note" style="max-width: 100%; min-height: 100px" id="ranchSalesNotes"></textarea>
              </div>
            </template>
          </div>
          <!--Transfer Tab-->
          <template v-if="isTransferTab">
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.transfer.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.transfer.$error }">*Group</span>
              <select v-model="transfer" class="form-control" :class="{ 'form-control-validation-error': v$.transfer.$error }">
                <option selected v-for="(group, index) in this.animalStore.groups" :key="index" :value="group">
                  {{ group.name }}
                </option>
              </select>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.transfer.$errors">
              <span class="error-message" v-for="error in v$.transfer.$errors" :key="error.$uid">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ error.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.cents_per_head.$error }">
              <span class="input-group-addon input-group-lg half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.cents_per_head.$error }">
                Purchase Cost Total
              </span>
              <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.group_event_values.cents_per_head.$error }">$</span>
              <input type="number" class="form-control" v-model="group_event_values.cents_per_head" />
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.group_event_values.cents_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.cents_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.pounds_per_head.$error }">
              <span class="input-group-addon input-group-lg half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.pounds_per_head.$error }">
                Purchase Weight Total
              </span>
              <input type="number" class="form-control" v-model="group_event_values.pounds_per_head" style="width: 70%; border-radius: 8px" />
              <p>lbs</p>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.group_event_values.pounds_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.pounds_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">
              <span
                class="input-group-addon input-group-lg half"
                :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">
                Trucking Cost
              </span>
              <span class="input-group-addon" :class="{ 'input-group-addon-validation-error': v$.group_event_values.trucking_cents_per_head.$error }">$</span>
              <input type="number" class="form-control" v-model="group_event_values.trucking_cents_per_head" />
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.group_event_values.trucking_cents_per_head.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.trucking_cents_per_head.minValue.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.cattle_condition.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.cattle_condition.$error }">
                *Cattle Condition
              </span>
              <select
                v-model="group_event_values.cattle_condition"
                class="form-control"
                :class="{ 'form-control-validation-error': v$.group_event_values.cattle_condition.$error }">
                <option value="green">Green</option>
                <option value="medium">Medium Flesh</option>
                <option value="heavy">Heavy Flesh</option>
              </select>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.group_event_values.cattle_condition.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.cattle_condition.required.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.breeds.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.breeds.$error }">Breeds</span>
              <select disabled class="form-control" :class="{ 'form-control-validation-error': v$.breeds.$error }">
                <template v-if="this.breeds.size > 0">
                  <option selected>{{ this.breeds.size }} Breeds</option>
                </template>
                <template v-else>
                  <option selected>No Breed</option>
                </template>
              </select>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.breeds.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.breeds.requiredIf.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.invalid_gender.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.invalid_gender.$error }">Gender</span>
              <select disabled class="form-control" :class="{ 'form-control-validation-error': v$.invalid_gender.$error }">
                <template v-if="!this.invalid_gender">
                  <option selected>1 Gender</option>
                </template>
                <template v-else>
                  <option selected>No Gender</option>
                </template>
              </select>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.invalid_gender.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.invalid_gender.requiredIf.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.group_event_values.dest_pen_id.$error }">
              <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.group_event_values.dest_pen_id.$error }">*Pen</span>
              <select
                v-model="group_event_values.dest_pen_id"
                class="form-control"
                :class="{ 'form-control-validation-error': v$.group_event_values.dest_pen_id.$error }">
                <option v-for="(pen, index) in animalStore.pens" :key="index" :value="pen._id">{{ pen.name }}</option>
              </select>
            </div>
            <div class="validation-alert inline-error-individual-departure" v-if="v$.group_event_values.dest_pen_id.$error">
              <span class="error-message">
                <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                {{ v$.group_event_values.dest_pen_id.required.$message }}
              </span>
            </div>
            <div class="input-group input-group-lg">
              <span class="input-group-addon half">Notes</span>
              <textarea v-model="group_event_values.notes" class="form-control" style="max-width: 100%; min-height: 100px" id="transferNotes"></textarea>
            </div>
          </template>
          <div class="text-center padding-x-sm" style="font-style: italic">* Indicates Required Field</div>
        </div>
        <div class="padding-x-sm">
          <div class="row padding-x-xs">
            <button
              v-if="isTransferTab"
              type="button"
              id="modal-select-button"
              class="btn btn-success padding-y-xl"
              @click="departureCheck"
              :disabled="disableTransferButton"
              style="border-radius: 30px; font-size: 18px">
              Create and Transfer
            </button>
            <button
              v-else
              type="button"
              id="modal-select-button"
              class="btn btn-success padding-y-xl"
              @click="departureCheck"
              style="border-radius: 30px; font-size: 18px">
              Save
            </button>
          </div>
          <div class="row padding-x-xs">
            <button type="button" class="btn btn-transparent padding-y-sm" data-dismiss="modal" style="border: none; font-size: 18px" @click="cancel">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ObjectId } from "@assets/js/helpers.js";
import { useUserStore } from "@stores/user";
import Animal from "@compositions/animal.vue";
import { DateTime } from "luxon";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf, minValue } from "@vuelidate/validators/dist/raw.esm";
import { useAnimalStore } from "@stores/animal";

export default {
  props: {
    animal: { type: Object, required: true },
  },
  data() {
    return {
      tabs: [
        {
          name: "Sale",
          id: 0,
          isActive: false,
          show: true,
        },
        {
          name: "Death/Other",
          id: 1,
          isActive: true,
          show: true,
        },
        {
          name: "Transfer",
          id: 2,
          isActive: false,
          show: this.userStore.user.can_pb,
        },
      ],
      active_tab: {},
      sale_cents: 0,
      sale_expense_cents: 0,
      animalCopy: this.animal,
      ranch_sales: {},
      death_departure: {},
      sale_departure: {},
      transfer: {},
      group_event_values: {},
      show_error: false,
      breeds: null,
      transfer_date: null,
      disableTransferButton: false,
      animal_digital_tag: null,
      animal_visual_tag: null,
      duplicate_digital_tag: false,
      invalid_gender: false,
    };
  },
  setup() {
    const {
      formatCentsToDollars,
      formatDollarsToCents,
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      checkDigitalTagsOnTransfer,
      sanitizeVisualTagForValidation,
    } = Animal();
    const animalStore = useAnimalStore();

    const userStore = useUserStore();
    const v$ = useVuelidate({ $lazy: true, $scope: false });

    return {
      formatCentsToDollars,
      formatDollarsToCents,
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      checkDigitalTagsOnTransfer,
      sanitizeVisualTagForValidation,

      animalStore,
      userStore,
      v$,
    };
  },
  mounted() {
    window.$("#recordDepartureModal").on("hidden.bs.modal", (e) => {
      this.cancel();
    });

    this.breeds = new Set();
    if (this.animalCopy.breeds) {
      for (const breed of this.animalCopy.breeds) {
        if (this.animalStore.all_breeds.includes(breed.breed) && this.animalStore.options.pb_breeds.includes(breed.breed)) {
          this.breeds.add(breed.breed);
        } else if (this.animalStore.all_breeds.includes(breed.breed) && !this.animalStore.options.pb_breeds.includes(breed.breed)) {
          this.breeds.add("other");
        }
      }
    }
    if (!this.animalCopy.gender || this.animalCopy.gender === "ungendered" || this.animalCopy.gender === "---") {
      this.invalid_gender = true;
    }
    this.active_tab = this.tabs[1];
    this.transfer_date = this.userStore.user.work_date;
    this.animal_digital_tag = this.getAnimalPrimaryDigitalLabel(this.animalCopy);
    if (!this.animal_digital_tag) {
      this.animal_visual_tag = this.animalCopy.visual
        ? this.sanitizeVisualTagForValidation(this.animalCopy.visual)
        : this.sanitizeVisualTagForValidation(this.getAnimalPrimaryVisualLabel(this.animalCopy));
    }
  },
  computed: {
    minBirthDate() {
      return this.animalCopy.birth_date;
    },
    isCalf() {
      return this.animalCopy.role === "calf";
    },
    isDeathTab() {
      return this.active_tab.name === "Death/Other";
    },
    isSaleTab() {
      return this.active_tab.name === "Sale";
    },
    isTransferTab() {
      return this.active_tab.name === "Transfer";
    },
    validationErrors() {
      if (this.isTransferTab) {
        return [
          this.v$.transfer_date.$error,
          this.v$.transfer.$error,
          this.v$.group_event_values.$error,
          this.v$.breeds.$error,
          this.v$.invalid_gender.$error,
          this.v$.duplicate_digital_tag.$error,
        ];
      } else if (this.isDeathTab) {
        return [this.v$.death_departure.$error, this.v$.animalCopy.$error];
      } else if (this.isSaleTab) {
        return [this.v$.sale_departure.$error, this.v$.animalCopy.$error, this.v$.ranch_sales.$error];
      }
      return false;
    },
    fieldsToBeValidated() {
      if (this.isTransferTab) {
        return [this.v$.transfer_date, this.v$.transfer, this.v$.group_event_values, this.v$.breeds, this.v$.invalid_gender, this.v$.duplicate_digital_tag];
      } else if (this.isDeathTab) {
        return [this.v$.death_departure, this.v$.animalCopy];
      } else if (this.isSaleTab) {
        return [this.v$.sale_departure, this.v$.animalCopy, this.v$.ranch_sales];
      }
      return false;
    },
  },
  methods: {
    departureCheck() {
      this.disableTransferButton = true;

      if (this.animalCopy.departure_dtz) {
        this.animalCopy.departure_dtz = DateTime.fromISO(this.animalCopy.departure_dtz).toFormat("yyyy-LL-dd");
      } else {
        this.animalCopy.departure_dtz = null;
      }

      //Makes sure the animal object and the death/transfer/sell object have all the required/correct fields.
      if (this.isTransferTab) {
        if (this.transfer._id) {
          this.animalStore.getVisualTagsFromPBAnimals(this.transfer._id).then(() => {
            this.checkDigitalTagsOnTransfer(this.animalStore.pb_eid_values, this.animal_digital_tag, this.animal).then((result) => {
              window.$(".loader").fadeOut();
              this.animalCopy.departure_reason = "sold";
              this.animalCopy.departure_subreason = "Transfer to Performance Beef";
              this.animalCopy.departure_dtz = this.transfer_date;

              this.fieldsToBeValidated.forEach((field) => field.$touch());
              if (this.validationErrors.includes(true)) {
                this.$nextTick(() =>
                  document.getElementsByClassName("inline-error-individual-departure")[0].scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                  })
                );
                this.disableTransferButton = false;
                return false;
              } else {
                this.recordTransfer();
              }

              this.duplicate_digital_tag = result;
            });
          });
        } else {
          //if no group is selected we can target that error directly
          this.v$.transfer.$touch();
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-individual-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        }
      } else if (this.isDeathTab) {
        this.animalCopy.departure_subreason = this.death_departure.subreason;
        this.animalCopy.departure_reason = this.death_departure.reason;
        this.fieldsToBeValidated.forEach((field) => field.$touch());
        if (this.validationErrors.includes(true)) {
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-individual-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        } else {
          this.recordDeath();
        }
      } else if (this.active_tab.name === "Sale") {
        this.animalCopy.departure_subreason = this.sale_departure.subreason;
        this.animalCopy.departure_reason = this.sale_departure.reason;
        this.ranch_sales.sale_cents = this.sale_cents;
        this.ranch_sales.sale_expense_cents = this.sale_expense_cents;
        this.fieldsToBeValidated.forEach((field) => field.$touch());
        if (this.validationErrors.includes(true)) {
          this.$nextTick(() =>
            document.getElementsByClassName("inline-error-individual-departure")[0].scrollIntoView({
              block: "start",
              behavior: "smooth",
            })
          );
          this.disableTransferButton = false;
          return false;
        } else {
          this.recordSale();
        }
      }
    },
    setActiveTab(tab) {
      this.show_error = false;
      let self = this;
      tab.isActive = true;
      if (tab.name !== this.active_tab.name) {
        this.death_departure = {};
        this.sale_departure = {};
      }
      this.active_tab = tab;
      this.tabs.forEach(function (tab) {
        if (tab.id !== self.active_tab.id) {
          tab.isActive = false;
        }
      });
      if (this.isTransferTab) {
        window.$(".loader").fadeIn();
        //get groups and pens only if the user is on the transfer tab.
        this.animalStore.getGroups().then(this.animalStore.getPens()).then(window.$(".loader").fadeOut());
      }
    },
    isEmpty(value) {
      return value == null || value.length === 0;
    },
    recordDeath() {
      this.animalStore.editAnimal(this.animalCopy).then(() => {
        window.$("#recordDepartureModal").modal("hide");
        this.$router.push("/");
        window.$(".loader").fadeOut();
      });
    },
    recordSale() {
      this.ranch_sales._id = ObjectId();
      this.ranch_sales.feedyard_id = this.userStore.auth.feedyard_id;
      this.ranch_sales.departure_date = this.animalCopy.departure_dtz;
      this.ranch_sales.animal_ids = [this.animalCopy._id];
      this.ranch_sales.sale_cents = this.formatDollarsToCents(this.ranch_sales.sale_cents);
      this.ranch_sales.sale_expense_cents = this.formatDollarsToCents(this.ranch_sales.sale_expense_cents);
      this.animalCopy.departure_note = this.ranch_sales.note;
      this.animalStore.addRanchSales(this.ranch_sales).then(() => {
        this.animalStore.editAnimal(this.animalCopy);
        window.$("#recordDepartureModal").modal("hide");
        this.$router.push("/");
        window.$(".loader").fadeOut();
      });
    },
    recordTransfer() {
      window.$(".loader").fadeIn();

      this.group_event_values.head_diff = 1;
      this.group_event_values._id = ObjectId();
      this.group_event_values.work_date = this.animalCopy.departure_dtz;
      this.group_event_values.type = "add";

      this.group_event_values.pounds_per_head = this.group_event_values.pounds_per_head ? this.group_event_values.pounds_per_head : 0;
      this.group_event_values.cents_per_head = this.formatDollarsToCents(this.group_event_values.cents_per_head);
      this.group_event_values.trucking_cents_per_head = this.formatDollarsToCents(this.group_event_values.trucking_cents_per_head);
      this.group_event_values.breeds = Array.from(this.breeds);
      this.group_event_values.genders = this.animalCopy.gender.split();

      this.animalCopy.departure_note = this.group_event_values.notes ? this.group_event_values.notes : null;

      //Build the PB animal
      let pb_animal = {};
      pb_animal.events = [];
      let primary_tag_value = null;
      let primary_visual_value = null;
      pb_animal._id = ObjectId();
      pb_animal.group_id = this.transfer._id;
      pb_animal.gender = this.animalCopy.gender;
      pb_animal.was_id = this.animalCopy._id;
      //loop through the ranch animal breeds and assign a new mongo ID for each breed
      if (this.breeds.size > 0) {
        pb_animal.breeds = [];
        const breeds = JSON.parse(JSON.stringify(this.animalCopy.breeds));
        for (let breedObj of breeds) {
          if (this.animalStore.all_breeds.includes(breedObj.breed)) {
            pb_animal.breeds.push({
              _id: ObjectId(),
              fraction: (breedObj.fraction /= 100),
              breed: breedObj.breed,
            });
          }
        }
      }
      //loop through the ranch animal tags, assign new ID's and set the primary visual and digital tags id.
      pb_animal.tags = JSON.parse(JSON.stringify(this.animalCopy.tags));
      for (let index in pb_animal.tags) {
        if (this.animalCopy.primary_tag_id === pb_animal.tags[index]._id) {
          primary_tag_value = pb_animal.tags[index].digital_label;
        }
        if (this.animalCopy.primary_visual_id === pb_animal.tags[index]._id) {
          primary_visual_value = pb_animal.tags[index].visual_label;
        }
        pb_animal.tags[index]._id = ObjectId();
      }
      pb_animal.primary_tag_id = pb_animal.tags.find((x) => x.digital_label === primary_tag_value)._id;
      pb_animal.primary_visual_id = pb_animal.tags.find((x) => x.visual_label === primary_visual_value)._id;
      pb_animal.feedyard_id = this.animalCopy.feedyard_id;
      pb_animal.created_dtz = DateTime.fromISO(this.animalCopy.departure_dtz).set({ hour: 12 }).toUTC().toISO();

      pb_animal.events.push({
        _id: ObjectId(),
        created_dtz: pb_animal.created_dtz,
        type: null,
        note: this.animalCopy.departure_note ? this.animalCopy.departure_note : null,
        pen_id: this.group_event_values.dest_pen_id,
      });

      this.animalStore.putGroupEvent(this.transfer._id, this.group_event_values).then((response) => {
        this.animalStore.addPBAnimal(pb_animal).then((response) => {
          this.animalStore.editAnimal(this.animalCopy).then(() => {
            window.$("#recordDepartureModal").modal("hide");
            this.$router.push("/");
            window.$(".loader").fadeOut();
          });
        });
      });
    },
    cancel() {
      this.death_departure = {};
      this.sale_departure = {};
      this.animalCopy.departure_dtz = null;
      this.animalCopy.departure_reason = null;
      this.animalCopy.departure_subreason = null;
      this.animalCopy.departure_note = null;
      this.sale_cents = 0;
      this.sale_expense_cents = 0;
      this.ranch_sales = {};
      this.transfer = {};
      this.group_event_values = {};
      this.show_error = false;
      this.disableTransferButton = false;
      this.v$.$reset();
      window.$(".loader").fadeOut();
    },
  },
  validations() {
    return {
      //Transfers
      transfer_date: {
        required: helpers.withMessage("Date is required.", required),
        requiredIfDepartureDateIsGreaterThanWorkDate: helpers.withMessage(
          "Departure Date can't be greater than " + this.userStore.user.work_date + ".",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz > this.userStore.user.work_date) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfBirthDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's birthdate.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.birth_date > this.animalCopy.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfWeanDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's wean date.",
          requiredIf(() => {
            if (this.animalCopy.wean_date > this.animalCopy.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
        requiredIfPurchaseDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's purchase date.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.purchase_date > this.animalCopy.departure_dtz) {
              this.v$.transfer_date.touch();
              return true;
            }
          })
        ),
      },
      transfer: {
        name: {
          required: helpers.withMessage("Group is required.", required),
          //Visual tag error is based on the group selected so I placed it here.
          requiredIf: helpers.withMessage(
            "Visual tag(s) already present in the selected group.",
            requiredIf(() => {
              if (this.animal_visual_tag && this.animalStore.visual_pb_values.has(this.animal_visual_tag)) {
                this.v$.duplicate_visual_tag.touch();
                return true;
              }
            })
          ),
        },
      },
      group_event_values: {
        cents_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        pounds_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        trucking_cents_per_head: { minValue: helpers.withMessage("Value must be equal or greater than 0.", minValue(0)) },
        cattle_condition: { required: helpers.withMessage("Cattle Condition is required.", required) },
        dest_pen_id: { required: helpers.withMessage("Pen is required.", required) },
      },
      breeds: {
        requiredIf: helpers.withMessage(
          "Breed is required.",
          requiredIf(() => {
            return !this.breeds.size;
          })
        ),
      },
      invalid_gender: {
        requiredIf: helpers.withMessage(
          "Gender is required.",
          requiredIf(() => {
            if (this.invalid_gender) {
              this.v$.invalid_gender.touch();
              return true;
            }
          })
        ),
      },
      duplicate_digital_tag: {
        requiredIf: helpers.withMessage(
          "Digital tag already present in Performance Beef.",
          requiredIf(() => {
            if (this.animal_digital_tag && this.duplicate_digital_tag) {
              this.v$.duplicate_digital_tag.touch();
              return true;
            }
          })
        ),
      },
      //Deaths
      death_departure: {
        reason: { required: helpers.withMessage("Departure reason is required.", required) },
      },
      //Dates for the sale and death/other departure.
      animalCopy: {
        departure_dtz: { required: helpers.withMessage("Departure Date is required.", required) },
        requiredIfBirthDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's birthdate.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.birth_date > this.animalCopy.departure_dtz) {
              this.v$.animalCopy.touch();
              return true;
            }
          })
        ),
        requiredIfWeanDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's wean date.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.wean_date > this.animalCopy.departure_dtz) {
              this.v$.animalCopy.touch();
              return true;
            }
          })
        ),
        requiredIfDepartureDateIsLesserThanAppliedCostDate: helpers.withMessage(
          "This animal has an event/cost recorded after the departure date. Please remove/edit the associated cost or change the departure date.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.applied_costs.length > 0) {
              let max_applied_cost_date = DateTime.fromISO(this.animalCopy.departure_dtz);
              let luxon_departure_dtz = DateTime.fromISO(this.animalCopy.departure_dtz);
              for (let cost of this.animalCopy.applied_costs) {
                cost = DateTime.fromISO(cost.application_dtz);
                max_applied_cost_date = DateTime.max(max_applied_cost_date, cost);
              }
              if (luxon_departure_dtz < max_applied_cost_date) {
                this.v$.animalCopy.touch();
                return true;
              }
            }
          })
        ),
        requiredIfPurchaseDateIsGreaterThanDepartureDate: helpers.withMessage(
          "Departure Date should not be before animal's purchase date.",
          requiredIf(() => {
            if (this.animalCopy.departure_dtz && this.animalCopy.purchase_date > this.animalCopy.departure_dtz) {
              this.v$.animalCopy.touch();
              return true;
            }
          })
        ),
      },
      //Sales
      sale_departure: {
        reason: { required: helpers.withMessage("Departure reason is required.", required) },
      },
      ranch_sales: {
        destination: { required: helpers.withMessage("Destination is required.", required) },
        weight: {
          requiredIf: helpers.withMessage(
            "Total Weight must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.weight) || this.ranch_sales.weight < 0) {
                this.v$.ranch_sales.weight.touch();
                return true;
              }
            })
          ),
        },
        sale_cents: {
          requiredIf: helpers.withMessage(
            "Total Sale Price must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.sale_cents) || this.ranch_sales.sale_cents < 0) {
                this.v$.ranch_sales.sale_cents.touch();
                return true;
              }
            })
          ),
        },
        sale_expense_cents: {
          requiredIf: helpers.withMessage(
            "Sales Expense must be equal to or greater than 0.",
            requiredIf(() => {
              if (this.isEmpty(this.ranch_sales.sale_expense_cents) || this.ranch_sales.sale_expense_cents < 0) {
                this.v$.ranch_sales.sale_expense_cents.touch();
                return true;
              }
            })
          ),
        },
      },
    };
  },
};
</script>
<style>
.modal-body {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
p {
  padding-top: 10px;
  font-size: 16px;
}
button:disabled {
  color: #666;
}
</style>
