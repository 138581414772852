import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import axios from "axios";
import { useUserStore } from "@stores/user";
import { AnimalToApi, ApiToAnimal } from "./helpers/animal.js";
import axiosRetry from "axios-retry";
axiosRetry(axios, {
  retries: 5,
  //By default, it retries if it is a network error or a 5xx error on an idempotent request (
  //GET, HEAD, OPTIONS, PUT or DELETE).
  retryDelay: () => {
    return 2000;
  },
});

export const useAnimalStore = defineStore("animal", {
  state: () => ({
    userStore: useUserStore(),
    animal_form_error_messages: {
      birth_weight_negative_value: "Birth weight must be greater than or equal to 0.",
      required_if_animal_origin_is_purchased_but_purchase_date_is_empty: "Purchased origin requires a valid purchase date.",
      required_birthdate: "Birthdate is required.",
      required_if_birthdate_is_greater_than: "Birthdate should not be greater than ",
      required_if_birthdate_is_greater_than_departure_date: "Birthdate should not be greater than departure date.",
      required_if_birthdate_is_greater_than_dams_birthdate: "Birthdate should not be before the dam's birthdate.",
      required_if_birthdate_is_greater_than_purchase_date: "Purchase Date must be greater than or equal to birthdate.",
      required_if_birthdate_is_greater_than_sire_birthdate: "Birthdate should not be before the sire(s) birthdate.",
      required_if_birthdate_is_greater_than_wean_date: "Weaned Date must be greater than or equal to birthdate.",
      required_if_breed_error: "Breed composition must not exceed 100%.",
      required_if_calf_role_with_no_gender_is_being_weaned: "Calves must have a gender to be weaned.",
      required_if_duplicate_digital_tag_exists: "Duplicated EID found. Please edit the animal EID number and try saving again.",
      required_if_EID_is_in_the_wrong_format: "EID's must be 15 numeric digits and can have a - every three digits. EID's cannot start with 0.",
      required_if_gender_is_cow_but_role_is_not_dam_or_cull: "The cow gender is only available for the dam or cull roles.",
      required_if_no_dam_but_body_condition: "Dam ID is required when Dam BCS is provided.",
      required_if_no_visual_tag: "Visual ID is required. Please edit the animal tag.",
      required_if_purchase_date_is_greater_than_departure_date: "Purchase date should not be greater than departure date.",
      required_if_role_is_dam_but_gender_is_not_cow: "For the Dam role, the gender must be Cow.",
      required_if_role_is_not_calf_or_cull_or_null_but_gender_is_null: "This role requires a valid gender.",
      required_if_role_is_null_but_gender_is_not: "No role is selected, please select a valid role or set gender to ---.",
      required_if_role_is_replace_but_gender_is_not_heifer: "For the Replacement role, the gender must be Heifer.",
      required_if_role_is_sire_but_gender_is_not_bull: "For the Sire role, the gender must be Bull.",
      required_if_wean_date_is_lesser_than_birth_date: "Weaned Date must be more than the birth date.",
      required_if_wean_date_is_greater_than_departure_date: "Weaned Date must be less than or equal to the departure date.",
      required_if_wean_date_is_missing_and_wean_pounds_is_present: "Weaned Date is required when Weaned Weight is provided.",
      required_if_visual_tag_and_birthdate_already_exist: "An animal with a matching Visual ID and birthdate is already present on the ranch.",
      required_if_visual_tag_fails_regex_test: "Invalid character(s) detected, please edit the visual tag.",
      wean_date_negative_value: "Weaned weight must be greater than or equal to 0.",
    },
    options: {
      pb_breeds: [
        "angus",
        "brahman",
        "braunvieh",
        "charolais",
        "crossbred",
        "galloway",
        "gelbvieh",
        "hereford",
        "holstein",
        "limousin",
        "red_angus",
        "simmental",
        "wagyu",
        "other",
      ],
      genders: ["bull", "steer", "cow", "heifer"],
      gender_labels: {
        steer: "Steer",
        heifer: "Heifer",
        bull: "Bull",
        cow: "Cow",
      },
      male_genders: ["bull", "steer"],
      female_genders: ["cow", "heifer"],
      calf_genders: ["heifer", "bull", "steer"],
      weaned_genders: ["heifer", "bull", "steer"],
      pre_death_reasons: [
        {
          reason: "died",
          subreason: "Stillborn, Carried full term",
          category: "pre",
        },
        { reason: "died", subreason: "Died - Respiratory", category: "pre" },
        { reason: "died", subreason: "Died - Digestive", category: "pre" },
        { reason: "died", subreason: "Died - Injury", category: "pre" },
        { reason: "died", subreason: "Died - Weather", category: "pre" },
        {
          reason: "died",
          subreason: "Died - Calving difficulty",
          category: "pre",
        },
        { reason: "died", subreason: "Died - Other", category: "pre" },
      ],
      post_death_reasons: [
        { reason: "died", subreason: "Died - Calving", category: "post" },
        { reason: "died", subreason: "Died - Disease", category: "post" },
        { reason: "died", subreason: "Died - Weather", category: "post" },
        { reason: "died", subreason: "Died - Injury", category: "post" },
        { reason: "died", subreason: "Died - Old", category: "post" },
        { reason: "died", subreason: "Died - Other", category: "post" },
        { reason: "died", subreason: "Lost", category: "post" },
      ],
      all_death_reasons: [
        {
          reason: "died",
          subreason: "Stillborn, Carried full term",
          category: "pre",
        },
        { reason: "died", subreason: "Died - Respiratory", category: "pre" },
        { reason: "died", subreason: "Died - Digestive", category: "pre" },
        { reason: "died", subreason: "Died - Injury", category: "pre" },
        { reason: "died", subreason: "Died - Weather", category: "pre" },
        {
          reason: "died",
          subreason: "Died - Calving difficulty",
          category: "pre",
        },
        { reason: "died", subreason: "Died - Other", category: "pre" },
        { reason: "died", subreason: "Died - Calving", category: "post" },
        { reason: "died", subreason: "Died - Disease", category: "post" },
        { reason: "died", subreason: "Died - Old", category: "post" },
        { reason: "died", subreason: "Lost", category: "post" },
      ],
      sale_reasons: [
        { reason: "sold", subreason: "Calf sold" },
        {
          reason: "sold",
          subreason: "Aborted / calf died",
        },
        { reason: "sold", subreason: "Age" },
        { reason: "sold", subreason: "Disposition" },
        { reason: "sold", subreason: "Open" },
        { reason: "sold", subreason: "Injury" },
        {
          reason: "sold",
          subreason: "Production/performance",
        },
        { reason: "sold", subreason: "Disease" },
        {
          reason: "sold",
          subreason: "Soundness (feet, mouth, or udder)",
        },
      ],
      birth_assistances: ["unassisted", "easy", "difficult", "caesarean", "breech"],
      birth_assistance_labels: {
        unassisted: "No Assistance",
        easy: "Assisted, Easy",
        difficult: "Assisted, Difficult / mechanical",
        caesarean: "Caesarean",
        breech: "Abnormal presentation",
      },
      vigors: ["healthy", "slow", "nursing_assistance", "died", "stillborn"],
      vigor_labels: {
        healthy: "Nursed immediately, healthy",
        slow: "Nursed on own, but slowly",
        nursing_assistance: "Required assistance",
        died: "Died shortly after birth",
        stillborn: "Dead on arrival",
      },
      body_conditions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      body_condition_labels: {
        1: "1 - Emaciated",
        2: "2 - Poor",
        3: "3 - Thin",
        4: "4 - Borderline Thin",
        5: "5 - Moderate",
        6: "6 - Moderate Fleshy",
        7: "7 - Fleshy",
        8: "8 - Fat",
        9: "9 - Obese",
      },
      management_codes: ["dam_only", "dam_with_creep", "without_dam_bucket_fed", "twin_or_foster_dam"],
      management_code_labels: {
        dam_only: "Dam only",
        dam_with_creep: "Dam with Creep",
        without_dam_bucket_fed: "Without Dam, Bucket Fed",
        twin_or_foster_dam: "Twin or Foster Dam",
      },
      roles: ["calf", "cull", "weaned", "dam", "replace", "natural_sire", "ai_sire", "natural_ai"],
      role_labels: {
        calf: "Calf",
        cull: "Cull",
        weaned: "Weaned",
        dam: "Dam",
        replace: "Replacement",
        natural_sire: "Natural Service",
        ai_sire: "AI",
        natural_ai: "AI + Natural",
      },
      calf_role: ["calf"],
      weaned_role: ["weaned"],
      female_roles: ["dam", "replace"],
      male_roles: ["natural_sire", "ai_sire", "natural_ai"],
      coat_colors: ["black", "red", "black_white_face", "red_white_face", "gray", "yellow", "white", "roan", "brown", "other"],
      incompatible_roles_for_applied_costs: {
        ai: ["natural_sire", "ai_sire", "natural_ai"],
        bull_breeding_exam: ["replace", "dam", "calf", "ai_sire", "first_calf_heifer"],
        castration: ["natural_sire", "ai_sire", "natural_ai", "dam", "replace", "first_calf_heifer"],
        in_estrus: ["natural_sire", "ai_sire", "natural_ai"],
        morphology: ["ai", "dam", "replace", "calf", "first_calf_heifer"],
        motility: ["ai", "dam", "replace", "calf", "first_calf_heifer"],
        pelvic_area: ["natural_sire", "ai_sire", "natural_ai", "calf"],
        preg_check: ["natural_sire", "ai_sire", "natural_ai"],
        reproductive_tract_score: ["natural_sire", "ai_sire", "natural_ai", "calf"],
        scrotal_circumference: ["ai", "dam", "replace", "first_calf_heifer"],
      },
      incompatible_genders_for_applied_costs: {
        ai: ["bull", "steer"],
        bull_breeding_exam: ["cow", "heifer"],
        castration: ["cow", "heifer"],
        in_estrus: ["bull", "steer"],
        morphology: ["cow", "heifer"],
        motility: ["cow", "heifer"],
        pelvic_area: ["bull", "steer"],
        preg_check: ["bull", "steer"],
        reproductive_tract_score: ["bull", "steer"],
        scrotal_circumference: ["cow", "heifer"],
      },
      applied_costs_labels: {
        castration: "Castration",
        preg_check: "Pregnancy Check",
        pelvic_area: "Pelvic Area Measurement",
        reproductive_tract_score: "Reproductive Tract Score",
        bull_breeding_exam: "Bull Breeding Exam",
        scrotal_circumference: "Scrotal Circumference",
        motility: "Motility",
        morphology: "Morphology",
        in_estrus: "In Estrus",
      },
      coat_color_labels: {
        black: {
          label: "BLK",
          extended: null,
        },
        red: {
          label: "RED",
          extended: null,
        },
        black_white_face: {
          label: "BWF",
          extended: "(black with white face)",
        },
        red_white_face: {
          label: "RWF",
          extended: "(red with white face)",
        },
        gray: {
          label: "GRAY",
          extended: null,
        },
        yellow: {
          label: "YELLOW",
          extended: null,
        },
        white: {
          label: "WHITE",
          extended: null,
        },
        roan: {
          label: "ROAN",
          extended: null,
        },
        brown: {
          label: "BR",
          extended: null,
        },
        other: {
          label: "Other",
          extended: null,
        },
      },
      horn_statuses: ["polled", "horned", "scurred", "dehorned"],
      horn_status_labels: {
        polled: "Polled",
        horned: "Horned",
        scurred: "Scurred",
        dehorned: "Dehorned",
      },
    },
    map_options: {
      management_codes: {
        1: "Dam only",
        2: "Dam with Creep",
        3: "Without Dam, Bucket Fed",
        4: "Twin or Foster Dam",
      },
      calving_eases: {
        1: "unassisted",
        2: "easy",
        3: "difficult",
        4: "caesarean",
        5: "breech",
      },
      vigors: {
        1: "healthy",
        2: "slow",
        3: "nursing_assistance",
        4: "died",
        5: "stillborn",
      },
      departure_reasons: {
        1: {
          reason: "died",
          subreason: "Stillborn, Carried full term",
          category: "pre",
        },
        2: { reason: "died", subreason: "Died - Respiratory", category: "pre" },
        3: { reason: "died", subreason: "Died - Digestive", category: "pre" },
        4: { reason: "died", subreason: "Died - Injury", category: "pre" },
        5: { reason: "died", subreason: "Died - Weather", category: "pre" },
        6: {
          reason: "died",
          subreason: "Died - Calving difficulty",
          category: "pre",
        },
        7: { reason: "died", subreason: "Died - Other", category: "pre" },
        8: { reason: "sold", subreason: "Calf sold", category: "pre" },
        9: {
          reason: "sold",
          subreason: "Culled - Aborted / calf died",
          category: "post",
        },
        10: { reason: "sold", subreason: "Culled - Age", category: "post" },
        11: {
          reason: "sold",
          subreason: "Culled - Disposition",
          category: "post",
        },
        12: { reason: "sold", subreason: "Culled - Open", category: "post" },
        13: { reason: "sold", subreason: "Culled - Injury", category: "post" },
        14: {
          reason: "sold",
          subreason: "Culled - Production/performance",
          category: "post",
        },
        15: { reason: "sold", subreason: "Culled - Disease", category: "post" },
        16: {
          reason: "sold",
          subreason: "Culled - Soundness (feet, mouth, or udder)",
          category: "post",
        },
        17: { reason: "died", subreason: "Died - Calving", category: "post" },
        18: { reason: "died", subreason: "Died - Disease", category: "post" },
        19: { reason: "died", subreason: "Died - Weather", category: "post" },
        20: { reason: "died", subreason: "Died - Injury", category: "post" },
        21: { reason: "died", subreason: "Died - Old", category: "post" },
        22: { reason: "died", subreason: "Died - Other", category: "post" },
        23: { reason: "died", subreason: "Lost", category: "post" },
      },
    },
    options_map: {
      management_codes: {
        dam_only: 1,
        dam_with_creep: 2,
        without_dam_bucket_fed: 3,
        twin_or_foster_dam: 4,
      },
      calving_eases: {
        unassisted: 1,
        easy: 2,
        difficult: 3,
        caesarean: 4,
        breech: 5,
      },
      vigors: {
        healthy: 1,
        slow: 2,
        nursing_assistance: 3,
        died: 4,
        stillborn: 5,
      },
      departure_subreasons: {
        "Stillborn, Carried full term": 1,
        "Died - Respiratory": 2,
        "Died - Digestive": 3,
        "Died - Injury": 4,
        "Died - Weather": 5,
        "Died - Calving difficulty": 6,
        "Died - Other": 7,
        "Calf sold": 8,
        "Aborted / calf died": 9,
        Age: 10,
        Disposition: 11,
        Open: 12,
        Injury: 13,
        "Production/performance": 14,
        Disease: 15,
        "Soundness (feet, mouth, or udder)": 16,
        "Died - Calving": 17,
        "Died - Disease": 18,
        "Died - Old": 19,
        Lost: 23,
        "Transfer to Performance Beef": 24,
      },
    },
    animals: useStorage("animals", [], sessionStorage),
    pb_animals_digital_tags: ("pb_animals_digital_tags", new Map(), sessionStorage),
    pb_animals_visual_tags: ("pb_animals_visual_tags", new Set(), sessionStorage),
    ranch_sales: useStorage("ranch_sales", []),
    groups: ("groups", []),
    pens: ("pens", []),
    all_breeds: useStorage("all_breeds", [], sessionStorage),
    breed_labels: useStorage("breed_labels", {}, sessionStorage),
    bulk_edit_animals: useStorage("bulk_edit_animals", [], sessionStorage),
    artificial_inseminations: useStorage("artificial_inseminations", new Map(), sessionStorage),
    diagnoses: [],
    map_diagnoses: new Map(),
  }),
  getters: {
    visual_and_birthdate_values: function (state) {
      let map = new Map();
      let animals = state.animals.filter(
        (x) =>
          x.tags.find((y) => y._id === x.primary_visual_id && y.visual_label && y.visual_label !== "") &&
          x.tags.find((y) => (y._id === x.primary_tag_id && !y.digital_label) || y.digital_label === "")
      );
      for (let animal of animals) {
        let visual_label = animal.tags.find((y) => y._id === animal.primary_visual_id).visual_label;
        if (visual_label) {
          //For comparison, all primary tags have their chars set to lower case, and white spaces trimmed/removed
          visual_label = visual_label.replace(/\s+/gm, " ").trim().toLowerCase();
        }
        let birth_date_and_id_map = new Map();
        birth_date_and_id_map.set(animal.birth_date, [animal._id]);

        if (!map.get(visual_label)) {
          map.set(visual_label, birth_date_and_id_map);
        } else {
          if (map.get(visual_label).has(animal.birth_date)) {
            map.get(visual_label).get(animal.birth_date).push(animal._id);
          } else {
            map.get(visual_label).set(animal.birth_date, [animal._id]);
          }
        }
      }
      return map;
    },
    ranch_eid_values: function (state) {
      const regex = new RegExp("^(\\-)|[^\\d\\n]", "gm");
      const result = ``;
      let map = new Map();
      let animals = state.animals.filter((x) => x.tags.find((y) => y._id === x.primary_tag_id && y.digital_label && y.digital_label !== ""));

      for (let animal of animals) {
        //Because animals can be created in multiple platforms, we can't guarantee the uniqueness of the EID key(client side validation).
        //In case of conflict we push the extra id and check for it's length later.
        let digital_label = animal.tags.find((y) => y._id === animal.primary_tag_id).digital_label.replace(regex, result);
        if (!map.get(digital_label)) {
          if (animal.was_id) {
            map.set(digital_label, [animal.was_id]);
          } else {
            map.set(digital_label, [animal._id]);
          }
        } else {
          if (animal.was_id) {
            map.get(digital_label).push(animal.was_id);
          } else {
            map.get(digital_label).push(animal._id);
          }
        }
      }
      return map;
    },
    pb_eid_values: (state) => state.pb_animals_digital_tags,
    visual_pb_values: (state) => state.pb_animals_visual_tags,
    sires: (state) => state.animals.filter((x) => state.options.male_genders.includes(x.gender) && state.options.male_roles.includes(x.role)),
    aiSires: (state) => state.animals.filter((x) => state.options.male_genders.includes(x.gender) && x.role === "ai_sire"),
    replacement_and_dams: (state) => state.animals.filter((x) => state.options.female_genders.includes(x.gender) && state.options.female_roles.includes(x.role)),
    culledAnimals: (state) => state.animals.filter((x) => x.role === "cull"),
    weanedCalves: (state) => state.animals.filter((x) => state.options.weaned_genders.includes(x.gender) && x.role === "weaned"),
    unweanedCalves: (state) =>
      state.animals.filter((x) => (state.options.calf_genders.includes(x.gender) || x.gender === null) && x.role === "calf" && !x.wean_date && !x.wean_pounds),
    avgBirthWeight: (state) => {
      let raisedAnimals = state.animals;
      let weight = 0;
      let totalAnimals = 0;
      for (const counter in raisedAnimals) {
        if (raisedAnimals[counter].birth_weight > 0) {
          weight += raisedAnimals[counter].birth_weight;
          totalAnimals++;
        }
      }

      if (totalAnimals === 0) {
        return 0;
      }

      return (weight / totalAnimals).toFixed(2);
    },
    avgWeaningWeight: (state) => {
      //For now wean and birth weight values are based on all the animals on the herd, later we might need to break those values based on
      //the animal purchased date value.
      let raisedAnimals = state.animals;
      let weight = 0;
      let totalAnimals = 0;
      for (const counter in raisedAnimals) {
        if (raisedAnimals[counter].wean_pounds > 0) {
          weight += raisedAnimals[counter].wean_pounds;
          totalAnimals++;
        }
      }

      if (totalAnimals === 0) {
        return 0;
      }
      return (weight / totalAnimals).toFixed(2);
    },
    avgDeath: (state) => {
      let raisedAnimals = state.animals.filter((x) => (state.options.calf_genders.includes(x.gender) || x.gender === null) && x.role === "calf" && !x.purchase_date);
      let totalDeadAnimals = 0;
      for (const counter in raisedAnimals) {
        if (raisedAnimals[counter].departure_reason == "died") {
          totalDeadAnimals++;
        }
      }
      if (totalDeadAnimals === 0) {
        return 0;
      }
      return (totalDeadAnimals / raisedAnimals.length) * 100;
    },
  },
  actions: {
    getRanchAnimalByIdAndSanitize(animalId) {
      if (animalId) {
        return new Promise((resolve, reject) => {
          axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${animalId}`).then((response) => {
            let apiAnimal = ApiToAnimal(response.data, { animals: this.animals });

            resolve(apiAnimal);
          });
        });
      }
    },
    // Used only for tag comparisons
    getAnimalById(animalId) {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${animalId}`).then((response) => {
          resolve(response.data);
        });
      });
    },
    getAnimals() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/?birth_date=ne:null&group_id=null&sort=_id`).then((response) => {
          let oldState = JSON.parse(JSON.stringify({ animals: this.animals }));
          this.animals = [];
          let animals = response.data;
          for (let animal of animals) {
            let apiAnimal = ApiToAnimal(animal, { animals: oldState.animals });
            if (apiAnimal) {
              this.animals.push(apiAnimal);
            }
          }
          resolve(response.data);
        });
      });
    },
    getBreeds() {
      return new Promise((resolve, reject) => {
        axios.get(`v2/translations/?locale={en}`).then((response) => {
          let obj = response.data;
          let entries = Object.entries(obj);
          this.all_breeds = [];
          this.breed_labels = {};
          for (let i = 0; i < entries.length; i++) {
            if (entries[i][0] === ":breed") {
              for (let [key, value] of Object.entries(entries[i][1])) {
                this.all_breeds.push(key);
                this.breed_labels[key] = value.translation;
              }
            }
          }
          resolve(response.data);
        });
      });
    },
    getGroups() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/groups/?status=active&sort_order=ne:0&sort=asc:sort_order`).then((response) => {
          this.groups = [];
          this.groups = response.data;
          resolve(response.data);
        });
      });
    },
    putGroupEvent(transfer_id, group_event_values) {
      return new Promise((resolve, reject) => {
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/groups/${transfer_id}/events/${group_event_values._id}`, group_event_values).then((response) => {
          resolve(response);
        });
      });
    },
    getPens() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/pens/?uses=pen&status=ne:archived&sort=asc:sort_order`).then((response) => {
          this.pens = [];
          this.pens = response.data;
          resolve(response.data);
        });
      });
    },
    getDigitalTagsFromPBAnimals() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/?group_id=ne:null&primary_tag_id=ne:null`).then((response) => {
          this.pb_animals_digital_tags = new Map();
          let animals = response.data;

          const regex = new RegExp("^(\\-)|[^\\d\\n]", "gm");
          const result = ``;

          let animals_with_digital_tags = animals.filter((x) => x.tags.find((y) => y._id === x.primary_tag_id && y.digital_label && y.digital_label !== ""));

          for (let animal of animals_with_digital_tags) {
            //for beef animals, we will need to track if they have a was_id to reference ranch animals later, we save both the was_id and _id.
            this.pb_animals_digital_tags.set(animal.tags.find((y) => y._id === animal.primary_tag_id).digital_label.replace(regex, result), [
              animal.was_id,
              animal._id,
            ]);
          }
          resolve(response.data);
        });
      });
    },
    getVisualTagsFromPBAnimals(transfer_id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/?group_id=${transfer_id}`).then((response) => {
          this.pb_animals_visual_tags = new Set();
          let animals = response.data;

          let animals_with_valid_visual_tags = animals.filter((x) =>
            x.tags.find(
              (y) =>
                (y._id === x.primary_visual_id && y.visual_label && y.visual_label !== "" && y._id === x.primary_tag_id && !y.digital_label) ||
                y.digital_label === ""
            )
          );

          //tags from pb are also set to lower case and white spaces are trimmed/replaced
          for (let animal of animals_with_valid_visual_tags) {
            this.pb_animals_visual_tags.add(
              animal.tags
                .find((y) => y._id === animal.primary_visual_id)
                .visual_label.replace(/\s+/gm, " ")
                .trim()
                .toLowerCase()
            );
          }
          resolve(response.data);
        });
      });
    },
    addAnimal(animal) {
      return new Promise((resolve, reject) => {
        let sanitizedAnimal = AnimalToApi(animal, {
          feedyard_id: this.userStore.auth.feedyard_id,
          work_date: this.userStore.user.work_date,
          artificial_inseminations: this.artificial_inseminations,
        });

        console.log(sanitizedAnimal);
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${sanitizedAnimal._id}`, sanitizedAnimal).then((response) => {
          let apiAnimal = ApiToAnimal(sanitizedAnimal, { animals: this.animals });

          this.animals.push(apiAnimal);
          resolve(apiAnimal);
        });
      });
    },
    async addPBAnimal(animal) {
      return new Promise((resolve, reject) => {
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${animal._id}`, animal).then((response) => {
          resolve(response);
        });
      });
    },
    deleteAnimal(animal) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${animal._id}`).then((response) => {
          this.animals.splice(
            this.animals.findIndex((x) => x._id == animal._id),
            1
          );
          resolve(response);
        });
      });
    },
    editAnimal(animal) {
      let sanitizedAnimal = AnimalToApi(animal, {
        feedyard_id: this.userStore.auth.feedyard_id,
        work_date: this.userStore.user.work_date,
        artificial_inseminations: this.artificial_inseminations,
      });
      console.log(sanitizedAnimal);
      return new Promise((resolve, reject) => {
        axios.patch(`/v2/feedyards/${this.userStore.auth.feedyard_id}/animals/${animal._id}`, sanitizedAnimal).then((response) => {
          let apiAnimal = ApiToAnimal(sanitizedAnimal, { animals: this.animals });

          this.UPDATE_ANIMAL(apiAnimal);
          resolve(apiAnimal);
        });
      });
    },
    getArtificialInseminations() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/inventory/?category=artificial_insemination`).then((response) => {
          this.artificial_inseminations = new Map();
          let artificial_inseminations = response.data;
          for (const ai of artificial_inseminations) {
            this.artificial_inseminations.set(ai._id, ai);
          }
          resolve(response.data);
        });
      });
    },
    addRanchSales(ranch_sale) {
      return new Promise((resolve, reject) => {
        axios.put(`/v2/feedyards/${this.userStore.auth.feedyard_id}/ranch_sales/${ranch_sale._id}`, ranch_sale).then((response) => {
          resolve(response.data);
        });
      });
    },
    getRanchSales() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/feedyards/${this.userStore.auth.feedyard_id}/ranch_sales/?_id=ne:null`).then((response) => {
          this.ranch_sales = [];
          this.ranch_sales.push(response.data);
          resolve(response);
        });
      });
    },
    getAllDiagnoses() {
      return new Promise((resolve, reject) => {
        axios.get(`/v2/diagnoses/?sort=asc:name`).then((response) => {
          this.diagnoses = response.data;
          for (const diagnosis of this.diagnoses) {
            this.map_diagnoses.set(diagnosis._id, diagnosis);
          }
        });
      });
    },

    // UI Session variable
    setBulkEditAnimals(animals) {
      return new Promise((resolve, reject) => {
        this.bulk_edit_animals = animals;
        resolve(this.bulk_edit_animals);
      });
    },

    UPDATE_ANIMAL(animal) {
      let index = this.animals.findIndex((x) => x._id == animal._id);
      this.animals[index] = animal;
    },
  },
});
