<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center calf-modal">
        <h2 class="modal-title" id="addCalfModalLabel">Add a New Calf</h2>
        <div>
          <div class="tab-content">
            <div class="input-group input-group-lg">
              <span class="input-group-addon half">Role</span>
              <select v-model="calf.role" class="form-control">
                <option disabled selected :value="calf.role">Calf</option>
              </select>
            </div>
            <br />
            <hr class="roleDivider" />
            <div class="row">
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.calf.birth_date.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.calf.birth_date.$error }">*Birthdate</span>
                <input type="date" :max="this.userStore.user.work_date" class="form-control" v-model="calf.birth_date" />
              </div>
              <div class="validation-alert inline-error-calf" v-if="v$.calf.birth_date.$errors">
                <span class="error-message" v-for="error in v$.calf.birth_date.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.calf.visual_tag.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.calf.visual_tag.$error }">*Visual ID</span>
                <input type="text" class="form-control" v-model="calf.tags.find((x) => x._id === calf.primary_visual_id).visual_label" />
              </div>
              <div class="validation-alert inline-error-calf" v-if="v$.calf.visual_tag.$errors">
                <span class="error-message" v-for="error in v$.calf.visual_tag.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.calf.digital_tag.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.calf.digital_tag.$error }">EID</span>
                <input type="text" class="form-control" v-model="calf.tags.find((x) => x._id === calf.primary_tag_id).digital_label" />
              </div>
              <div class="validation-alert inline-error-calf" v-if="v$.calf.digital_tag.$errors">
                <span class="error-message" v-for="error in v$.calf.digital_tag.$errors" :key="error.$uid">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ error.$message }}
                  <br />
                </span>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Brand/Tattoo</span>
                <input type="text" class="form-control" v-model="calf.brand" />
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Gender</span>
                <select v-model="calf.gender" class="form-control">
                  <option v-for="(gender, _index) in animalStore.options.calf_genders" :key="_index" :value="gender">
                    {{ animalStore.options.gender_labels[gender] }}
                  </option>
                  <option :value="null">---</option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Herd</span>
                <select v-model="calf.herd_id" class="form-control">
                  <option value="">---</option>
                  <option v-for="(herd, _index) in herdStore.herds" :key="_index" :value="herd._id">
                    {{ herd.name }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Pasture</span>
                <select v-model="calf.pasture_id" class="form-control">
                  <option value="">---</option>
                  <option v-for="(pasture, _index) in pastureStore.pastures" :key="_index" :value="pasture._id">
                    {{ pasture.name }}
                  </option>
                </select>
              </div>
              <div class="row margin-sm">
                <div class="col-xs-10 text-center">
                  <span style="font-size: 20px">Sire ID</span>
                </div>
                <div class="col-xs-10">
                  <template v-for="(sire, _index) in getSires" :key="_index">
                    <span>
                      <b>{{ sire }}</b>
                    </span>
                    <br />
                  </template>
                </div>
                <div class="col-xs-4">
                  <button
                    id="setCalfSire"
                    class="btn btn-outline btn-sm btn-round padding-y-md"
                    data-toggle="modal"
                    data-target="#changeCalfSireModal"
                    type="button">
                    <small>Edit</small>
                  </button>
                </div>
              </div>
              <div class="row margin-sm">
                <div class="col-xs-10 text-center">
                  <span style="font-size: 20px">Dam ID</span>
                </div>
                <div class="col-xs-10">
                  <span>
                    <b>{{ getAnimalPrimaryVisualLabel(this.animalCopy) }}</b>
                  </span>
                </div>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Dam BCS</span>
                <select v-model="calf.dam_body_condition" class="form-control">
                  <option value="">---</option>
                  <option v-for="(body_condition, _index) in animalStore.options.body_conditions" :key="_index" :value="body_condition">
                    {{ animalStore.options.body_condition_labels[body_condition] }}
                  </option>
                </select>
              </div>
              <div class="row margin-sm">
                <div class="col-xs-10 text-center">
                  <span style="font-size: 20px">Breed</span>
                </div>
                <div class="col-xs-10" style="padding: 5px">
                  <template v-for="(breed, _index) in getCalfBreeds" :key="_index">
                    <span>
                      <b>{{ breed }}</b>
                    </span>
                    <br />
                  </template>
                </div>
                <div class="col-xs-4">
                  <button
                    id="setCalfBreed"
                    class="btn btn-outline btn-sm btn-round padding-y-md"
                    data-toggle="modal"
                    data-target="#changeCalfBreedsModal"
                    type="button">
                    <small>Edit</small>
                  </button>
                  <span style="font-size: 10px">(Automatic Breed)</span>
                </div>
                <div class="ios-switch-wrapper one-sided-switch-wrapper filter-switch-wrapper" style="padding-top: 40px; margin-right: 20px">
                  <input
                    type="checkbox"
                    checked
                    name="breedSwitch"
                    id="breedSwitch"
                    class="ios-switch ios-toggle green-checkbox"
                    v-model="allowBreedEdit"
                    :disabled="disabled == 1" />
                  <label for="breedSwitch" class="ios-switch checkbox-label small-width" data-off="No" data-on="Yes" style="float: right"></label>
                </div>
              </div>
              <p v-if="this.multipleSiresWithDifferentBreed" style="color: red">
                Automatic Breed is disabled for sires with different breeds. Please edit the breed manually or remove a sire.
              </p>
              <div class="validation-alert inline-error-calf" v-if="v$.calf.breeds.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.calf.breeds.requiredIfBreedError.$message }}
                  <br />
                </span>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Color</span>
                <select v-model="calf.coat_color" class="form-control">
                  <option :value="null">---</option>
                  <option v-for="(coat_color, index) in animalStore.options.coat_colors" :key="index" :value="coat_color">
                    {{ animalStore.options.coat_color_labels[coat_color].label }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Horned Status</span>
                <select v-model="calf.horned_status" class="form-control">
                  <option :value="null">---</option>
                  <option v-for="(horn_status, index) in animalStore.options.horn_statuses" :key="index" :value="horn_status">
                    {{ animalStore.options.horn_status_labels[horn_status] }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg" :class="{ 'input-group-lg-validation-error': v$.calf.birth_weight.$error }">
                <span class="input-group-addon half" :class="{ 'input-group-lg-half-validation-error': v$.calf.birth_weight.$error }">Birth Weight</span>
                <input type="number" class="form-control" v-model="calf.birth_weight" />
              </div>
              <div class="validation-alert inline-error-calf" v-if="v$.calf.birth_weight.$error">
                <span class="error-message">
                  <img class="edit-img" src="/home/images/Exclamation_Circle_Red.svg" />
                  {{ v$.calf.birth_weight.minValue.$message }}
                </span>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Birth Pasture</span>
                <select v-model="calf.birth_pasture_id" class="form-control">
                  <option value="">---</option>
                  <option v-for="(pasture, _index) in pastureStore.pastures" :key="_index" :value="pasture._id">
                    {{ pasture.name }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Management Code</span>
                <select v-model="calf.management_code" class="form-control">
                  <option value="">---</option>
                  <option v-for="(management_code, _index) in animalStore.options.management_codes" :key="_index" :value="management_code">
                    {{ animalStore.options.management_code_labels[management_code] }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Calving Ease</span>
                <select v-model="calf.birth_assistance" class="form-control">
                  <option value="">---</option>
                  <option v-for="(birth_assistance, _index) in animalStore.options.birth_assistances" :key="_index" :value="birth_assistance">
                    {{ animalStore.options.birth_assistance_labels[birth_assistance] }}
                  </option>
                </select>
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon half">Vigor</span>
                <select v-model="calf.birth_vigor" class="form-control">
                  <option value="">---</option>
                  <option v-for="(vigor, _index) in animalStore.options.vigors" :key="_index" :value="vigor">
                    {{ animalStore.options.vigor_labels[vigor] }}
                  </option>
                </select>
              </div>
              <div>
                <label for="animalNotes">Notes</label>
                <textarea class="form-control" v-model="calf.note" style="max-width: 100%" id="animalNotes"></textarea>
              </div>
            </div>
          </div>
          <div class="text-center padding-x-sm" style="font-style: italic">* Indicates Required Field</div>
        </div>
        <div class="padding-x-sm">
          <div class="row padding-x-xs">
            <button type="button" id="modal-select-button" class="btn btn-success padding-y-sm" @click="addCalf" style="border-radius: 30px">
              <small>Create and Add Calf</small>
            </button>
          </div>
          <div class="row padding-x-xs">
            <button type="button" class="btn btn-transparent padding-y-sm" data-dismiss="modal" style="border: none" @click="cancel">
              <small>Cancel</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="health-page-container modal modal-center fade in" id="changeCalfSireModal" role="dialog" aria-labelledby="changeCalfSireModal">
    <sire-modal :animal="calf" :key="calf"></sire-modal>
  </div>

  <div class="health-page-container modal modal-center fade in" id="changeCalfBreedsModal" role="dialog" aria-labelledby="changeCalfBreedsModal">
    <breeds-modal :animal="calf" :key="calf"></breeds-modal>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { ObjectId } from "@assets/js/helpers.js";

import Animal from "@compositions/animal.vue";
import { useUserStore } from "@stores/user";
import { useAnimalStore } from "@stores/animal";
import { useHerdStore } from "@stores/herd";
import { usePastureStore } from "@stores/pasture";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf, minValue } from "@vuelidate/validators/dist/raw.esm";

export default {
  emits: ["updateCalfList"],
  data() {
    return {
      animalCopy: this.animal,
      allowBreedEdit: true,
      calf: {
        tags: [{ visual_label: "", digital_label: "" }],
        birth_date: DateTime.now().toFormat("yyyy-LL-dd"),
        sire_ids: [],
        dam_id: this.animal._id,
        breeds: null,
        role: "calf",
        gender: null,
      },
      damBreedMap: new Map(),
      disabled: 0,
      duplicate_digital_tag: false,
      duplicate_visual_tag: false,
      sire_birthdates: [],
      multipleSiresWithDifferentBreed: false,
      sireBreedMap: new Map(),
    };
  },
  props: {
    animal: { type: Object, required: true },
  },
  setup(props) {
    const {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      updateHeiferToCow,
      updateDamBCS,
      validateBreedFraction,
      hasDuplicateEID,
      hasDuplicateVisualIDAndBirthdate,
    } = Animal();

    const v$ = useVuelidate({ $lazy: true, $scope: false });

    const animalStore = useAnimalStore();
    const userStore = useUserStore();

    const herdStore = useHerdStore();

    const pastureStore = usePastureStore();

    return {
      getAnimalPrimaryDigitalLabel,
      getAnimalPrimaryVisualLabel,
      updateHeiferToCow,
      updateDamBCS,
      validateBreedFraction,
      hasDuplicateEID,
      hasDuplicateVisualIDAndBirthdate,

      animalStore,
      herdStore,
      pastureStore,
      userStore,
      v$,
    };
  },
  updated() {
    // If animal is a cow/heifer we map the breed(s) for the calf
    if (this.animalCopy.gender && this.animalStore.options.female_genders.includes(this.animalCopy.gender)) {
      this.damBreedMap = this.getDamBreedMap();
    }
  },
  computed: {
    calfSireID() {
      return this.calf.sire_ids;
    },
    getSires() {
      let sires = [];
      let assignedNaturalSire = [];

      if (this.calf.sire_ids) {
        assignedNaturalSire = this.animalStore.sires.filter((x) => this.calf.sire_ids.includes(x._id));
      }

      if (assignedNaturalSire.length) {
        for (let sire of assignedNaturalSire) {
          sires.push(this.getAnimalPrimaryVisualLabel(sire));
        }
        this.sireBreedMap.clear();
        this.getSireBreedsAndBirthdates(assignedNaturalSire);
      }

      if (!sires.length) {
        sires.push("---");
      }

      return sires;
    },
    getCalfBreeds() {
      let calfBreeds = new Map();

      if (this.allowBreedEdit) {
        // Sets dam breeds (previously mapped and the fraction divided by 2) into the calfBreeds map
        if (this.damBreedMap) {
          for (let [key, value] of this.damBreedMap.entries()) {
            calfBreeds.set(key, value);
          }
        }

        // if the animal has a sire, get the sire breeds and map it into the calfBreeds map.
        if (this.sireBreedMap.size) {
          for (let [sireKey, sireValue] of this.sireBreedMap.entries()) {
            // If there's a matching key(breed name), it will update the value and add the sire % with the dam %.
            if (calfBreeds.get(sireKey)) {
              calfBreeds.set(sireKey, calfBreeds.get(sireKey) + sireValue);
            } else {
              // if no match is found, add the sire breeds into the calfBreeds map.
              calfBreeds.set(sireKey, sireValue);
            }
          }
        }
        // If no sire is selected, we still need to fill the missing 50% of its breed composition.
        if (!this.sireBreedMap.size) {
          // If the dam didn't have a breed, or part of it's breed was unknown, that value should already be present in the calf map from the dam breed mapping above.
          // If a key is found, add the 50% of the sire value to the existing " " key.
          if (calfBreeds.get(" ")) {
            calfBreeds.set(" ", calfBreeds.get(" ") + 50);
          } else {
            // if there is no " " key, only fill the sire 50% side with " ".
            calfBreeds.set(" ", 50);
          }
        }

        // Round the fractions on the calf to 1 decimal place.
        let totalFraction = 0;
        for (let [key, value] of calfBreeds.entries()) {
          value = Math.round(value * 10) / 10;
          totalFraction += value;
          if (totalFraction.toFixed(1) == 100.1) {
            value -= 0.1;
            value = Math.round(value * 10) / 10;
          }
          calfBreeds.set(key, value);
        }

        // sorts the calfBreed map by descending value, so we can show the highest breed composition first.
        let sortedCalfBreeds = new Map([...calfBreeds.entries()].sort((a, b) => b[1] - a[1]));

        // adds the breed(s) above into the actual calf animal object
        this.setCalfBreeds(sortedCalfBreeds);
      }

      // What will be displayed to the user
      let breeds = [];
      let count = 0;
      let totalFraction = 100;

      if (!this.calf.breeds || !this.calf.breeds.length) {
        breeds.push("---");
        return breeds;
      }

      breedObj: for (const breedObj of this.calf.breeds) {
        if (count == 2) {
          breeds.push(" More " + totalFraction.toFixed(1) + "%");
          break breedObj;
        } else if ((breedObj.breed === " " && breedObj.fraction) || !breedObj.breed) {
          breeds.push("Unknown" + " " + (breedObj.fraction > 0 ? breedObj.fraction : "0") + "%");
          totalFraction -= breedObj.fraction;
          count++;
        } else {
          breeds.push(this.animalStore.breed_labels[breedObj.breed] + " " + (breedObj.fraction > 0 ? breedObj.fraction : "0") + "%");
          totalFraction -= breedObj.fraction;
          count++;
        }
      }

      if ((breeds.length === 1 && breeds[0] === " ") || !breeds.length || !breeds) {
        breeds = [];
        breeds.push("---");
        return breeds;
      }

      return breeds;
    },
    isHeifer() {
      return this.animalCopy.gender === "heifer";
    },
    siresWithDifferentBreed() {
      return this.multipleSiresWithDifferentBreed;
    },
  },
  methods: {
    async addCalf() {
      this.duplicate_digital_tag = false;
      this.duplicate_visual_tag = false;
      //creates the primary visual/digital tag based on the tag the calf was created with
      this.calf.tags[0]._id = ObjectId();
      this.calf.primary_visual_id = this.calf.tags[0]._id;
      this.calf.primary_tag_id = this.calf.tags[0]._id;

      //format and set dates
      if (this.calf.birth_date) {
        this.calf.birth_date = DateTime.fromISO(this.calf.birth_date).toFormat("yyyy-LL-dd");
      } else {
        this.calf.birth_date = null;
      }

      this.calf.wean_date = null;
      this.calf.purchase_date = null;

      //check if the animal has a digital tag, if yes, we do an EID check, otherwise we only check for visual and birthdate.
      let digital_tag = this.calf.tags.find((x) => x._id === this.calf.primary_tag_id).digital_label;
      if (digital_tag && digital_tag !== "") {
        if (await this.hasDuplicateEID(this.animalStore.ranch_eid_values, this.animalStore.pb_eid_values, digital_tag, this.calf)) {
          window.$(".modal-body").animate({ scrollTop: "0px" }, 800);
          this.duplicate_digital_tag = true;
          this.duplicate_visual_tag = false;
        }
      } else {
        if (await this.hasDuplicateVisualIDAndBirthdate(this.calf, this.animalStore.visual_and_birthdate_values)) {
          window.$(".modal-body").animate({ scrollTop: "0px" }, 800);
          this.duplicate_visual_tag = true;
          this.duplicate_digital_tag = false;
        }
      }

      const result = await this.v$.$validate();
      if (!result) {
        this.$nextTick(() =>
          document.getElementsByClassName("inline-error-calf")[0].scrollIntoView({
            block: "start",
            behavior: "smooth",
          })
        );
        return;
      }

      window.$(".loader").fadeIn();

      let dam_bcs = this.calf.dam_body_condition;

      //checks for dead vigors, if present, departure dtz and reason are created on the calf
      if (this.calf.birth_vigor === "died" || this.calf.birth_vigor === "stillborn") {
        this.calf.departure_dtz = this.calf.birth_date;
        this.calf.departure_reason = "died";
        this.calf.departure_subreason = null;
        this.calf.departure_note = this.calf.note;
        this.calf.note = null;
      }

      if (this.calf.tags.find((x) => x._id === this.calf.primary_tag_id).digital_label) {
        const regex = new RegExp("^(\\-)|[^\\d\\n]", "gm");
        const result = ``;
        this.calf.tags.find((x) => x._id === this.calf.primary_tag_id).digital_label = this.calf.tags
          .find((x) => x._id === this.calf.primary_tag_id)
          .digital_label.replace(regex, result);
      }

      this.animalStore.addAnimal(this.calf).then((addedAnimal) => {
        if (this.isHeifer) {
          this.updateHeiferToCow(this.animalCopy);
        }

        if (dam_bcs) {
          this.animalCopy.body_condition = dam_bcs;
          this.updateDamBCS(this.animalCopy, addedAnimal);
          this.animalCopy.body_condition = null;
        }

        window.$(".loader").fadeOut();
        this.calf = {
          tags: [{ visual_label: "", digital_label: "" }],
          birth_date: DateTime.now().toFormat("yyyy-LL-dd"),
          sire_ids: [""],
          dam_id: this.animal._id,
          breeds: null,
          dam_body_condition: null,
          role: "calf",
          gender: null,
        };
        window.$(".calf-modal").scrollTop(0);
        window.$("#addNewCalfModal").modal("hide");
        this.$emit("updateCalfList");
        this.v$.$reset();
      });
    },
    cancel() {
      window.$(".loader").fadeOut();
      this.calf = {
        tags: [{ visual_label: "", digital_label: "" }],
        birth_date: DateTime.now().toFormat("yyyy-LL-dd"),
        sire_ids: [],
        dam_id: this.animal._id,
        breeds: null,
        role: "calf",
        gender: null,
      };
      this.allowBreedEdit = true;
      this.sire_birthdates = [];
      this.v$.$reset();
      window.$(".calf-modal").scrollTop(0);
    },
    getDamBreedMap() {
      if (!this.animalCopy.breeds || !this.animalCopy.breeds.length) {
        // If the dam animal object doesn't have a breed, an empty breed will be added to calculate the calf's breed on getCalfBreed
        this.animalCopy.breeds = [];
        this.animalCopy.breeds.push({
          _id: ObjectId(),
          fraction: 100,
          breed: " ",
        });
      }
      if (this.animalCopy.breeds) {
        for (const breedObj of this.animalCopy.breeds) {
          if (breedObj.breed === " " && breedObj.fraction) {
            // If the dam doesn't have a breed, or if part of the breed is " ", the value is mapped as  .
            this.damBreedMap.set(" ", breedObj.fraction / 2);
          }
          if (breedObj.breed !== " ") {
            this.damBreedMap.set(breedObj.breed, breedObj.fraction / 2);
          }
        }
      }
      return this.damBreedMap;
    },
    getSireBreedsAndBirthdates(sires) {
      let sireMap = new Map();
      this.sire_birthdates = [];
      if (sires) {
        for (let sire of sires) {
          this.sire_birthdates.push(sire.birth_date);
          if (!sire.breeds || !sire.breeds.length) {
            // If the sire animal object doesn't have a breed, an empty breed will be added to calculate the calf's breed on getCalfBreed
            sire.breeds.push({
              _id: ObjectId(),
              fraction: 100,
              breed: " ",
            });
          }
          if (sire && sire.breeds) {
            for (const breedObj of sire.breeds) {
              if (breedObj.breed === " " && breedObj.fraction) {
                // If the sire doesn't have a breed, or if part of the breed is " ", the value is mapped as " ".
                sireMap.set(" ", breedObj.fraction / 2);
              }
              if (breedObj.breed !== " ") {
                sireMap.set(breedObj.breed, breedObj.fraction / 2);
              }
            }
          }
          if (!this.sireBreedMap.size) {
            this.sireBreedMap = new Map(sireMap);
          }
          this.multipleSiresWithDifferentBreed = false;
          if (this.sireBreedMap.size) {
            for (let [key, value] of sireMap.entries()) {
              if (!this.sireBreedMap.has(key)) {
                this.multipleSiresWithDifferentBreed = true;
                return;
              }
              if (this.sireBreedMap.get(key) !== value) {
                this.multipleSiresWithDifferentBreed = true;
                return;
              } else {
                this.multipleSiresWithDifferentBreed = false;
              }
            }
          }
        }
      }
      return sireMap;
    },
    setCalfBreeds(sortedCalfBreeds) {
      this.calf.breeds = [];
      for (let [key, value] of sortedCalfBreeds.entries()) {
        this.calf.breeds.push({
          _id: ObjectId(),
          fraction: value,
          breed: key,
        });
      }
    },
  },
  validations() {
    return {
      calf: {
        birth_date: {
          required: helpers.withMessage("Birthdate is required.", required),
          requiredIfBirthDateIsGreaterThanWorkDate: helpers.withMessage(
            "Birthdate should not be later than " + this.userStore.user.work_date + ".",
            requiredIf(() => {
              if (this.calf.birth_date && this.calf.birth_date > this.userStore.user.work_date) {
                this.v$.calf.birth_date.touch();
                return true;
              }
            })
          ),
          requiredIfBirthDateIsGreaterThanDamsBirthdate: helpers.withMessage(
            "Birthdate should not be before the dam's birthdate.",
            requiredIf(() => {
              if (this.calf.birth_date && this.calf.birth_date < this.animalCopy.birth_date) {
                this.v$.calf.birth_date.touch();
                return true;
              }
            })
          ),
          requiredIfBirthDateIsGreaterThanSiresBirthdate: helpers.withMessage(
            "Birthdate should not be before the sire(s) birthdate.",
            requiredIf(() => {
              if (this.calf.birth_date && this.calf.sire_ids) {
                //gets the max date based on the sire's birthdate to compare against the calf.
                let max_sire_birth_date = DateTime.fromISO(this.calf.birth_date);
                let luxon_calf_birth_date = DateTime.fromISO(this.calf.birth_date);
                for (let birthdate of this.sire_birthdates) {
                  birthdate = DateTime.fromISO(birthdate);
                  max_sire_birth_date = DateTime.max(max_sire_birth_date, birthdate);
                }
                if (luxon_calf_birth_date < max_sire_birth_date) {
                  this.v$.calf.birth_date.touch();
                  return true;
                }
              }
            })
          ),
        },
        visual_tag: {
          requiredIfNoVisualTag: helpers.withMessage(
            "Visual ID is required.",
            requiredIf(() => {
              if (this.calf.primary_visual_id && !this.calf.tags.find((x) => x._id === this.calf.primary_visual_id).visual_label) {
                this.v$.calf.visual_tag.touch();
                return true;
              }
            })
          ),
          requiredIfVisualTagAndBirthdayAlreadyExist: helpers.withMessage(
            "An animal with a matching Visual ID and birthdate is already present on the ranch.",
            requiredIf(() => {
              if (this.duplicate_visual_tag) {
                this.v$.calf.visual_tag.touch();
                return this.duplicate_visual_tag;
              }
            })
          ),
        },
        digital_tag: {
          requiredIfEIDIsInTheWrongFormat: helpers.withMessage(
            "EID's must be 15 numeric digits and can have a - every three digits. EID's cannot start with 0.",
            requiredIf(() => {
              if (this.calf.primary_tag_id && this.calf.tags.find((x) => x._id === this.calf.primary_tag_id).digital_label) {
                let regExp = new RegExp("^[1-9]{1}\\d{2}-\\d{3}-\\d{3}-\\d{3}-\\d{3}$|^[1-9]\\d{14}$", "m");
                let isEIDValid = regExp.test(this.calf.tags.find((x) => x._id === this.calf.primary_tag_id).digital_label);
                if (!isEIDValid) {
                  this.v$.calf.digital_tag.touch();
                  return true;
                }
              }
            })
          ),
          requiredIfDuplicateDigitalTagExists: helpers.withMessage(
            "Duplicated EID found. Please edit the animal EID number and try saving again.",
            requiredIf(() => {
              if (this.duplicate_digital_tag) {
                this.v$.calf.duplicate_digital_tag.touch();
                return this.calf.duplicate_digital_tag;
              }
            })
          ),
        },
        birth_weight: { minValue: helpers.withMessage("Birth weight must be greater than or equal to 0.", minValue(0)) },
        breeds: {
          requiredIfBreedError: helpers.withMessage(
            "Breed composition must not exceed 100%.",
            requiredIf(() => {
              if (!this.validateBreedFraction(this.calf.breeds)) {
                this.v$.calf.breeds.touch();
                return true;
              }
            })
          ),
        },
      },
    };
  },
  watch: {
    // watches for changes on the calf sire_ids. If a change is watched, sireBreedMap will be updated.
    async calfSireID() {
      this.sireBreedMap = await this.getSireBreedsAndBirthdates();
    },
    siresWithDifferentBreed() {
      // Checks if there are sires with different breeds.If true, change allowBreedEdit to false, and disables the automatic breed toggle button.
      if (this.multipleSiresWithDifferentBreed) {
        this.disabled = 1;
        this.allowBreedEdit = false;
      } else {
        this.disabled = 0;
      }
    },
  },
};
</script>
