<template>
  <div class="modal fade" id="batchUpdate">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="page === 'form'">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="batch-title">Batch Backdate</h4>
          </div>
          <div class="modal-body">
            <div id="batch-update-form">
              <div class="alert alert-danger" role="alert" v-show="error">{{ error }}</div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon input-label">Feed Name</span>
                <input type="text" class="form-control feedName" data-id="item-name" v-model="batchForm.name" disabled />
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon input-label">Start Date</span>
                <input type="date" class="form-control" :max="this.userStore.user.work_date" v-model="batchForm.start_date" />
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon input-label">End Date</span>
                <input type="date" class="form-control" :max="this.userStore.user.work_date" v-model="batchForm.end_date" />
              </div>
              <div class="input-group input-group-lg">
                <span class="input-group-addon input-label">Cost per {{ inventoryStore.unitMapper(batchForm.unit) }}</span>
                <input type="text" class="form-control batch_input" v-model="batchForm.properties.cents_per_unit" />
              </div>
              <br />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-confirm" @click="getSummary()">Confirm</button>
          </div>
        </div>
        <div v-else-if="page == 'summary'">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="batch-title">Batch Backdate</h4>
          </div>
          <div class="modal-body">
            <h4>Summary</h4>
            <h5>Start Date: {{ batchForm.start_date }}</h5>
            <h5>End Date: {{ batchForm.end_date }}</h5>
            <br />
            <h5>Updating:</h5>
            <ul>
              <li>Individual Animal Costs</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-cancel" @click="back()">Back</button>
            <button type="button" class="btn btn-confirm" :disabled="saving === true" @click="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import qs from "qs";
import { useToast } from "vue-toastification";

import { useUserStore } from "@stores/user";
import { useInventoryStore } from "@/stores/inventory";

export default {
  data() {
    return {
      batchForm: {
        properties: {},
      },
      page: "form",
      error: null,
      saving: false,
    };
  },
  props: {
    inventory: { type: Object, required: true },
  },
  setup() {
    const toast = useToast();
    const userStore = useUserStore();
    const inventoryStore = useInventoryStore();

    return {
      toast,
      userStore,
      inventoryStore,
    };
  },
  watch: {
    inventory: function (newVal, oldVal) {
      this.batchForm = _.clone({
        id: newVal.id,
        name: newVal.name,
        start_date: this.userStore.user.work_date,
        end_date: this.userStore.user.work_date,
        properties: { cents_per_unit: newVal.cents_per_unit },
        unit: newVal.unit,
      });
    },
  },
  methods: {
    getSummary() {
      if (this.validate()) {
        this.page = "summary";
      }
    },
    validate() {
      if (!this.batchForm.start_date) {
        this.error = "Invalid start date";
        return false;
      }
      if (!this.batchForm.end_date) {
        this.error = "Invalid end date";
        return false;
      }
      if (typeof this.batchForm.properties.cents_per_unit != "undefined" && this.batchForm.properties.cents_per_unit < 0) {
        this.error = "Cost per unit must be zero or greater";
        return false;
      }

      this.error = "";
      return true;
    },
    back() {
      this.page = "form";
    },
    save() {
      window.$(".loader").fadeIn();
      this.saving = true;
      this.batchForm.cost_animal = true;
      axios
        .post(`/php/inventory/batch_backdate/`, qs.stringify(this.batchForm))
        .then((res) => {
          window.$(".loader").fadeOut();
          this.saving = false;

          let data = res.data;

          if (typeof data.error !== "undefined") {
            this.error = data.error;
            this.back();
            return;
          }

          window.$("#batchUpdate").modal("hide");
          this.toast.success("Backdate successful.");
          this.back();
        })
        .catch(() => {
          window.$(".loader").fadeOut();
          this.saving = false;

          this.toast.error("An error occurred while running batch backdate.");
        });
    },
  },
};
</script>
